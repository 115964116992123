.interactive-map {
  display: flex;
  position: relative;

  video {
    margin: 0;
    aspect-ratio: 16 / 9;
    position: relative;
    z-index: 1;
  }

  &__background {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;

    &__poster {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: auto;
    }
  }

  .zoomVideo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: -1;
    opacity: 0;

    &.active {
      z-index: 1;
      opacity: 1;
    }
  }

  .loading-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .markers {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity $transition-speed ease;
    z-index: 2;

    &-enter,
    &-appear {
      opacity: 0;
    }
    &-enter-active,
    &-appear-active {
      opacity: 1;
      transition: opacity ease 300ms;
    }
    &-exit {
      opacity: 1;
      pointer-events: none;
    }
    &-exit-active,
    &-exit-done {
      opacity: 0;
      pointer-events: none;
      transition: opacity ease 300ms;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .marker {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba($white, 0.9);
      box-shadow: $box-shadow;
      text-decoration: none;
      color: $tertiary;
      cursor: pointer;
      padding: 5px 10px 5px 22px;
      transition: all $transition-speed ease;
      line-height: 1;
      transform: translateX(15px);

      &:hover {
        background-image: linear-gradient(
          to right,
          $secondary-highlight,
          $secondary
        );
        color: $white;

        .icon.hover {
          opacity: 1;
        }

        .label {
          color: $white;
        }
      }

      .icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);

        &::before {
          display: block;
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
          height: 90%;
          border-radius: 50%;
          background-color: $white;
        }

        &.hover {
          opacity: 0;
          transition: opacity $transition-speed ease;
        }
      }

      .label {
        margin: 0;
        font-size: 12px;
        text-align: left;
        text-decoration: none;
        color: $tertiary;
        line-height: 1.5em;
        transition: color $transition-speed ease;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 1rem;
    right: 5px;
    z-index: 2;
  }
}
