.three-sixty-view-wrapper {
  position: relative;

  .three-sixty-view {
    cursor: grab;
  }

  .three-sixty-view-loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--loading {
    .three-sixty-view {
      opacity: 0.5;
    }
  }

  &--hidden {
    display: none;
  }
}

.three-sixty-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $dark-blue;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  padding: 1rem;
  transition:
    color 150ms ease-in-out,
    opacity 150ms ease-in-out;

  &:hover {
    color: $dark-blue-highlight;
  }

  &.loading {
    @keyframes shimmerLoading {
      0% {
        opacity: 0.4;
      }

      50% {
        opacity: 0.6;
      }

      100% {
        opacity: 0.4;
      }
    }

    animation: shimmerLoading 2s infinite ease-in-out;
  }

  &.active {
    color: $blue;

    &:hover {
      color: $blue-highlight;
    }
  }

  span {
    font-size: 12px;
    font-weight: 700;
  }
}
