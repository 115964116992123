@mixin typography($weight, $size, $lh) {
  font-size: $size + px;
  font-weight: $weight;
  line-height: $lh;
}
* {
  font-family: $font-family;
}
h1,
.h1 {
  @include typography(300, 56, 1.1);

  @include media-breakpoint-down(xs) {
    @include typography(300, 48, 1.1);
  }
}

h2,
.h2 {
  @include typography(300, 48, 1.1);

  @include media-breakpoint-down(xs) {
    @include typography(300, 40, 1.1);
  }
}

h3,
.h3 {
  @include typography(300, 40, 1.1);

  @include media-breakpoint-down(xs) {
    @include typography(300, 30, 1.1);
  }
}

h4,
.h4 {
  @include typography(300, 30, 1.1);

  @include media-breakpoint-down(xs) {
    @include typography(400, 22, 1.1);
  }
}

h5,
.h5 {
  @include typography(400, 22, 1.1);

  @include media-breakpoint-down(xs) {
    @include typography(400, 16, 1.1);
  }
}

h6,
.h6 {
  @include typography(400, 16, 24px);
}

.p,
p {
  margin-bottom: 10px;
  @include typography(400, 16, 24px);
  &.no-margin {
    margin: 0;
  }

  @include media-breakpoint-down(xs) {
    @include typography(400, 14, 21px);
  }
}

.lead p {
    @include typography(400, 20, 24px);
}

.small {
  @include typography(400, 14, 21px);
}

.medium {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

small {
  color: $text-gray;
}

// Specific
.locale-head {
  @include typography(300, 22, 28px);
}

.card-title {
  @include typography(400, 22, 1.1);
}

.glbl-hover {
  &:hover {
    color: $primary !important;
    text-decoration: underline;
  }
}

.superscript,
.subscript {
  font-size: 0.75rem;
}

.superscript {
  vertical-align: super;
}

.subscript {
  vertical-align: sub;
}
