.quotes {
  position: relative;
  margin-bottom: 30px;
  .quotes-navigation {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    .slider__prevButton,
    .slider__nextButton {
      background-color: transparent;
      border: none;
    }
  }
  .swiper-button-lock {
    display: none;
  }
  .quote {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    > div {
      @include media-breakpoint-down(lg) {
        width: fit-content;
      }
    }
    &:not(.not-round) .image-wrapper {
      border-radius: 50%;
    }
    .image-wrapper {
      width: 200px !important;
      height: 200px;
      margin-right: 30px;
      overflow: hidden;

      @include media-breakpoint-down(lg) {
        width: 120px !important;
        height: 120px !important;
        margin: auto;
        margin-bottom: 15px;
      }
    }

    figure {
      margin: 0;
    }

    .blockquote {
      margin-bottom: 20px;
      color: $gray-100;
      font-size: 22px;
      *:not(.btn, .label) {
        font-size: 22px;
        line-height: 1.75rem;
      }

      @include media-breakpoint-down(lg) {
        text-align: left;
      }
    }

    .blockquote-footer {
      position: relative;
      padding-left: 24px;
      width: fit-content;

      @include media-breakpoint-down(lg) {
        text-align: left;
      }

      &:before {
        content: "";
        -moz-transform: rotate(($base-angle) + deg);
        -ms-transform: rotate(($base-angle) + deg);
        -o-transform: rotate(($base-angle) + deg);
        -webkit-backface-visibility: hidden;
        -webkit-background-clip: content-box;
        -webkit-transform: rotate(($base-angle) + deg);
        backface-visibility: hidden;
        background-clip: content-box;
        background-color: $primary;
        height: 100%;
        left: 0;
        padding: 1px;
        position: absolute;
        top: 0;
        transform: rotate(($base-angle) + deg);
        width: 4px;
        will-change: transform;
        margin-left: 4px;
      }

      .author {
        color: $gray-100;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5rem;
      }

      .profession {
        color: $gray-100;
        font-size: 14px;
        line-height: 1.5rem;
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-down(lg) {
      display: block;

      figure {
        text-align: center !important;
      }
    }
  }
}

.component__quotes .round .slider__small-navigation {
  margin-top: 0 !important;
}
