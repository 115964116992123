.event-page {
  margin-top: 50px !important;

  .event-disruptor {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    background: $salmon-light;
    padding: 15px;
    margin-bottom: 30px;

    .info-circle {
      margin-right: 15px;
    }

    .disruptor-icon-text-wrapper {
      display: flex;
      margin-right: 25px;

      .disruptor-text {
        font-weight: 700;
        margin-bottom: 0;
      }
    }

    a.btn {
        white-space: nowrap;
    }

    @include media-breakpoint-down(sm) {
        flex-direction:column;
        align-items: flex-end;

        .info-circle {
            svg {
                width: 28px!important;
                height: 28px!important;
            }
        }

        .disruptor-icon-text-wrapper {
            margin-bottom: 10px;
            margin-right: 0;
          }

      }
  }

  .event-page-header {
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0px;
    }

    h1,
    h2,
    .event-image {
      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }
    }
  }

  .event-exerpt {
    padding: 0;
  }

  .event-page-sidebar {
    margin-top: 50px;

    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }

    .event-detail-image {
      margin-bottom: 20px;

      .image-wrapper div {
        width: auto !important;
      }
    }

    .event-info-headline hr {
      margin-bottom: 20px;
    }

    .event-detail-information {
      margin-bottom: 20px;

      span.svg {
        margin-right: 10px;
        svg {
          fill: $primary;
          margin: 4px 0;
        }
      }

      a.btn {
        margin-bottom: 15px;
        svg {
          fill: $white;
        }
      }

      .event-info-data {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .event-info {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 20px;
      }
    }
  }

  .successor-banner {
    display: flex;
    position: relative;
    height: 280px;
    max-height: 280px;
    width: 100%;

    .successor-image {
        position: absolute;
        display: inline;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-right: 33%;

        .image-wrapper div {
            width: 100%;
        }

        img {
            object-fit: cover;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .successor-content-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items:flex-end;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $secondary;
        color: $white;
        width: 33%;
        height: 100%;
        z-index: 1;


        .content {
            padding: 30px 40px 30px 80px;

            a {
                &:hover {
                    color: $secondary;
                }

            }

            a.btn svg {
                color: $secondary;
                fill: $secondary;
            }


        }

        @include media-breakpoint-down(sm) {
            width: 100%!important;
            clip-path: none!important;
            justify-content: center;
            align-items:center;

            .content {
                padding: 15px;
            }
        }
    }
  }

  .section__booth .image-wrapper .w-full {
    width: auto !important;
  }

  .container__section:not(.bg-gray) {
    padding-bottom: 20px;
  }

  [class^="event-image"] {
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
  }

  [class^="event-item-wrapper"] {
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
  }

  /* HIGHLIGHTS SECTION */
  .keyhighlight-wrapper {
    margin-top: 10px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-bottom: 30px;
    }
  }

  .event-item-wrapper__keyhighlight {
    margin-bottom: 80px;
  }
  .event-item-wrapper__highlights {
    margin-bottom: 30px;
  }

  .highlight-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-bottom: 30px;
    }
  }

  /* BOOTH SECTION */
  .section__booth {
    .image-wrapper div {
      max-height: 500px;
    }
  }
}
