.customer-portal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  z-index: 100000;

  &__wrapper {
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-down(lg) {
      max-width: none;
    }

    @include media-breakpoint-down(md) {
      height: 100%;
      padding: 0;
    }
  }

  &__window {
    width: 18rem;
    max-width: 100%;
    background-color: $white;
    margin-top: var(--meta-height, 37px);
    box-shadow: $box-shadow;
    position: relative;

    opacity: 0;
    transform: translateY(0.5rem);

    transition-property: opacity transform;
    transition-duration: 0.5 * $transition-speed;
    transition-timing-function: ease;

    @include media-breakpoint-down(md) {
      width: 100%;
      height: 100%;
      margin-top: 0;
      transform: translateY(1rem);
    }

    &.entering,
    &.entered {
      opacity: 1;
      transform: translateY(0);
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      right: 3rem;
      bottom: 100%;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid $white;
      z-index: -1;
    }

    &__heading {
      display: none;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        padding: 0.75rem 1rem;
        min-height: 4rem;
        background-color: $primary;
        box-shadow: $box-shadow;
        margin: 0;
        color: $white;

        .heading {
          display: flex !important;
          justify-content: space-between;
          align-items: center;
          margin: 0;

          h4 {
            margin: 0;
            font-size: 22px;
            font-weight: 400;
            line-height: 26px;
          }

          .close-big:hover svg {
            fill: $white;
          }
        }
      }
    }

    &__header {
      position: relative;

      &__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $gray-40;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding: 1.5rem;

        &__loading {
          &-1 {
            width: 12rem;
            height: 2.25rem;
            border-radius: 2.25rem;
            background-color: $gray-40;
            margin-bottom: 1rem;
          }

          &-2 {
            width: 14.25rem;
            height: 1.5rem;
            border-radius: 1.5rem;
            background-color: $gray-40;
          }
        }

        &__sign-in-button {
          margin-right: 0;
          margin-bottom: 1rem;
        }

        &__register-text {
          text-align: center;
          font-weight: 700;
          margin: 0;
        }

        &__register-link {
          text-decoration: underline;
          color: $primary;
          display: inline;

          .label {
            display: inline !important;
          }
        }

        &__heading {
          text-align: center;
          margin-bottom: 0.5rem;

          &__username {
            display: block;
            font-size: 1.375rem;
          }
        }

        &__sign-out-link {
          text-align: center;
          font-weight: 700;
          text-decoration: underline;
          color: $primary;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 0;
      margin: 0;
      list-style: none;

      &.not-signed-in {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
      }

      &__item-wrapper {
        display: block;
      }

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.75rem 1rem;
        min-height: 3.5rem;
        border-bottom: 1px solid $white-highlight;
        cursor: pointer;

        &:hover {
          color: $primary;
          text-decoration: none;

          svg {
            fill: $gray-60;
          }
        }

        &--subnavigation-header {
          position: relative;
          font-weight: 700;
          justify-content: center;
          padding: 0.75rem 3rem;
          text-align: center;

          &:hover {
            color: inherit;
          }

          .customer-portal-overlay__window__list__item {
            &__icon {
              position: absolute;
              left: 1rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}

.root .page-content > .mykrohne-content {
  .breadcrumb .breadcrumb-item a {
    color: $tertiary;
    font-weight: 400;

    &:hover {
      color: $primary;
    }
  }

  .mykrohne-overview {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    gap: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    &__card {
      display: flex;
      align-items: center;
      background-image: linear-gradient(to left, $gray-300, $gray-10);
      box-shadow: $box-shadow;
      padding: 1rem;

      &__image {
        width: 6rem !important;
        height: 6rem;
        flex-shrink: 0;
        margin-right: 1.5rem;
      }

      &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__title {
          font-weight: 700;
          margin-bottom: 1.5rem;
        }

        &__button {
          margin-top: 1.5rem;
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .mykrohne-iframe {
    width: 1px;
    min-width: 100%;
    height: 100%;
    min-height: 80vh;
    border: none;
  }
}
