.contentset-finder {
  background-color: $gray-10;
  margin-bottom: 90px;
  .quick-filter {
    .quick-filter-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 10px;
      position: relative;
      @include media-breakpoint-down(sm) {
        text-align: center;
        padding-top: 0;

        .h5,
        h5 {
          margin-bottom: 20px;
        }

        .quick-filter-headline {
          display: none;
          padding-bottom: 10px;
          width: 100%;
          font-weight: 700;
          &.checked,
          &.active {
            display: block;
            & + .react-select__container {
              display: block;
            }
          }
          &.checked {
            border-bottom: 2px solid $green;
          }
          & + .react-select__container {
            display: none;
          }
        }

        .react-select__container {
          margin-top: 10px;
          margin-bottom: 14px;

          .react-select__control {
            min-height: 50px;
            box-shadow: 0px 3px 5px $box-shadow-color;
            border: 1px solid $white-highlight;
          }

          .react-select__value-container--has-value
            .react-select__single-value {
            color: $green;
            font-weight: 700;
          }

          &:last-of-type {
            margin-bottom: 8px;
          }
        }

        .quick-filter-options {
          .quick-filter-option {
            display: block;
            width: 100%;
          }
        }

        &.additional-requirements {
          label {
            color: $tertiary;
          }

          p {
            font-size: 14px;
            font-weight: 700;
            color: $gray-100;
          }

          .form-check:not(:last-child) {
            margin-right: 35px;
          }
        }

        &.results {
          position: absolute;
          bottom: -110px;
          left: 50%;
          transform: translate(-50%, 50%);

          .nav-item {
            .nav-link {
              padding-right: 0;

              .svg svg {
                fill: $tertiary;
              }

              &:hover {
                text-decoration: underline;
                color: $primary;
              }
            }
          }
        }

        .dropdown {
          background: $white;
          box-shadow: 0px 3px 5px $box-shadow-color;
          border: 1px solid $white-highlight;
          padding: 14.5px 20px;
        }

        .form-check .form-check-custom {
          min-height: unset;
        }

        small {
          position: absolute;
          bottom: -42px;
          left: 50%;
          transform: translate(-50%, 50%);
        }

        .form-check .form-check-input {
          display: inherit;
        }
      }

      .tab-primitive__content {
        width: 100%;
        overflow: visible !important;

        .tab-primitive__content__item {
          padding-top: 30px;
          padding-bottom: 50px;

          @include media-breakpoint-down(sm) {
            padding-top: 10px;
          }
        }
      }
      .tab-primitive__nav {
        display: flex;
        align-items: center;
        margin-top: 5px;
        border-bottom: 1px solid $gray-40;

        @include media-breakpoint-down(xs) {
          align-items: flex-start;
          overflow: auto;
          width: 100vw;
          margin: 0 -30px;
        }

        .quick-filter-number {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          min-width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid $tertiary;
          margin-right: 10px;
          font-size: 16px;
          font-weight: 700;
        }
        .quick-filter-headline {
          margin-right: 30px;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          padding-bottom: 15px;
          border-bottom: 2px solid transparent;
          color: $tertiary;
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            cursor: pointer;
          }
          &.checked {
            color: $green;
            .quick-filter-number {
              border: 1px solid $green;
              color: $green;
            }
          }
          &.active {
            color: $primary;
            font-weight: 700;
            border-bottom: 2px solid $primary;
            .quick-filter-number {
              background: $primary;
              border: 1px solid $primary;
              color: $white;
            }
          }
        }
        .quick-filter-arrow {
          margin-bottom: 17px;
          .svg {
            display: grid;
            place-items: center;
            width: 18px;
            height: 18px;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      .quick-filter-options {
        display: flex;
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 15px;
        .quick-filter-option {
          margin-left: 8px;
          margin-right: 8px;
          padding: 0;
          &.checked {
            border: 1px solid $green;
            color: $green;
          }
          input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
          }
          .form-check-custom {
            visibility: hidden;
            min-width: 0;
            border: 0;
            &.checked {
              + label {
                border: 1px solid $green;
                .form-check-option-label {
                  color: $green;
                  font-weight: 700;
                }
              }
            }
          }
          label {
            background: $white;
            box-shadow: $box-shadow;
            border: 1px solid $white-highlight;
            padding: 13.5px 20px;
            transition: box-shadow 0.2s ease;
            &:hover {
              cursor: pointer;
              box-shadow: 0px 6px 10px $box-shadow-color;
            }
            .form-check-option-label {
              margin-right: 0;
            }
            .form-check-label-support {
              background-color: $white-highlight;
              color: $primary;
              font-weight: bold;
              line-height: 1 !important;
              margin-left: 8px;
              margin-top: 0px;
              padding-left: 4px;
              padding-right: 4px;
            }
          }
        }
      }
      .quick-filter-nav {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 18px;
        transform: translate(0%, 100%);
        align-items: flex-start;

        .hidden {
          opacity: 0%;
        }

        .quick-filter-nav-btn {
          @include media-breakpoint-up(md) {
            margin-left: 10px;
          }

          @include media-breakpoint-down(sm) {
            border: none;
            background: none;
            width: 36px;
            height: 36px;
            outline: 0;

            &:last-child {
              margin-right: 0px;
              margin-left: 10px;
            }

            &:focus {
              box-shadow: unset;
            }
          }
        }

        .quick-filter-nav-links {
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          a {
            margin-right: 0px !important;
          }

          a:not(:first-child) {
            margin-top: 10px;
          }

          @include media-breakpoint-up(md) {
            justify-content: center;
            max-width: fit-content;

            a:not(:first-child) {
              width: fit-content;
            }
          }

          @include media-breakpoint-down(sm) {
            width: 100%;
          }
        }
      }
      .quick-filter-search {
        justify-content: center;
        display: flex;

        .react-select__container {
          width: 50%;

          @include media-breakpoint-down(sm) {
            width: 100%;
            margin-bottom: 14px;
          }
        }

        .quick-filter-nav {
          max-width: fit-content;
        }
      }
    }
  }
}
