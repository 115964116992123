.svg {
  display: flex;
  position: relative;
  svg {
    position: relative;
  }
  &.configure svg {
    top: -2px;
    width: 18px;
  }
  &.forward svg {
    top: -2px;
    width: 18px;
  }

  &.close-big {
    &:hover svg {
      fill: $primary;
    }
  }
}
.event-slider .svg.close-big:hover svg {
  fill: currentColor;
}

#nprogress {
  pointer-events: none;
  .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 50%;
    right: 50%;
  }
  .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: $primary;
    border-left-color: $primary;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }

  .bar {
    background: $primary-highlight;

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
  }

  .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow:
      0 0 10px $white,
      0 0 5px $white;
    opacity: 1;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
