.newsletter__container {
  @include media-breakpoint-down(md) {
    margin-bottom: 0 !important;

    & > .row {
      margin-right: 0;
      margin-left: 0;

      & > .col {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &.container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    max-width: none;
  }

  &.hidden {
    display: none;
  }
}

@media all and (display-mode: fullscreen) {
  .fullscreen-only-interactive-map .newsletter__container {
    display: none;
  }
}

.newsletter-disruptor {
  display: flex;
  overflow: hidden;
  height: 278px;
  position: relative;


  .newsletter-caption {
    position: absolute;
    display: flex;
    flex-direction: column;
    -moz-box-pack: end;
    justify-content: flex-end;
    top: 0;
    left: 0;
    width: 42%;
    height: 100%;
    color: white;
    padding: 30px;
    background: linear-gradient(
        99.79deg,
        $primary-highlight 7.46%,
        $primary 92.74%
        );

    @include media-breakpoint-down(md) {
        clip-path: polygon(0 8%, 100% 0, 100% 100%, 0 100%)!important;
    }

    h1,
    .h1 {
      margin-bottom: 10px;

      @include media-breakpoint-down(md) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    p {
      margin-bottom: 15px;
    }

    form {
      input[type="email"] {
        height: 36px;
        border: 0;
        border-radius: 0;
        margin-right: 10px;
        padding-left: 5px;

        &::placeholder {
          color: $gray-80;
          font-size: 14px;
        }

        &:focus {
          outline: 0;
        }
      }
      .form-group {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(md) {
        .form-group {
          margin-bottom: 6px;
          width: 67%;

          input {
            width: 98%;
          }
        }

        button {
          width: 33%;
        }
      }

      @include media-breakpoint-down(sm) {
        display: block;

        .form-group {
          width: 100%;

          input {
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
          }
        }

        button {
          width: auto;
        }
      }
    }

    small {
      font-size: 14px;
      color: white;
    }

    @include media-breakpoint-down(xl) {
      width: 50%;
      padding-right: 50px;
    }

    @include media-breakpoint-down(lg) {
      width: 55%;
    }

    @include media-breakpoint-down(md) {
      position: relative;
      width: 100%;
      padding-top: 50px;
      clip-path: polygon(0 8%, 100% 0%, 100% 100%, 0% 100%);
    }

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .newsletter-image {
    background-color: $gray-40;
    margin-left: 38%;
    width: 63% !important;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
      display: none;
    }

    position: relative;
    z-index: -1;
  }

  @include media-breakpoint-down(xl) {
    height: 300px;
  }

  @include media-breakpoint-down(md) {
    height: 100%;
  }
}
