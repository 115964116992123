.tab-primitive__nav {
  display: flex;
  position: relative;

  &__item {
    &--active,
    &:hover {
      color: $primary;
    }

    &--active {
      font-weight: 700;
    }
  }

  &__indicator {
    position: absolute;
    bottom: 0;
    background-color: $primary;
    height: 2px;
    transition-property: left, width;
    transition-duration: 200ms;
    transition-timing-function: ease;
  }
}

.tab-primitive__content {
  overflow-y: clip;
  position: relative;
  transition: height 200ms ease;

  &__item {
    padding-top: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;

    &--active {
      position: static;
      opacity: 1;
      pointer-events: auto;
      // transition-delay: 200ms;
    }
  }
}

.nav-tabs,
.tab-primitive__nav {
  border-bottom: 1px solid $gray-20;
  &.tab-primitive__nav--sticky {
    position: sticky;
    top: var(--navigation-height, 0px);
    z-index: 25;
    transition:
      background 200ms ease 50ms,
      top 150ms ease,
      transform 50ms ease,
      opacity 50ms ease;

    &::before {
      display: block;
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: calc(var(--navigation-height, 0px) + var(--tab-height, 50px));
      background-color: $white;
      box-shadow: $box-shadow;
      pointer-events: none;
      z-index: -1;
      transform: translateY(-100%);
      opacity: 0;
      transition:
        height 150ms ease,
        transform 50ms ease,
        opacity 50ms ease;
    }

    &.tab-primitive__nav--sticking {
      border-bottom: 0 !important;
      &::before {
        pointer-events: initial;
        transform: none;
        opacity: 1;
      }
    }

    &.tab-primitive__nav--scrolled-over {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  &.tab-primitive__nav--left {
    .nav-link {
      flex: 0 1 auto;
      white-space: nowrap;
    }
  }
  .nav-link {
    align-items: center;
    border-left: none;
    border-radius: 0;
    border-right: none;
    display: flex;
    flex: 1;
    font-size: 16px;
    justify-content: center;
    margin-bottom: -2px !important;
    min-height: 50px;
    padding: 0 20px;
    text-align: center;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      margin: auto;
    }
  }

  .nav-link.active {
    font-weight: 700;
  }
}

// fix position and height of sticky-nav tabs
.component__tabs {
  .tab-primitive {
    .tab-primitive__nav.tab-primitive__nav--sticky {
      @include media-breakpoint-down(md) {
        &:after {
          top: 0;
          height: var(--tab-height, 50);
        }
      }
    }
  }
}
