.facts {
  row-gap: 2rem;
  & > div {
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
    width: 25%;
    &:not(:nth-child(4n))::after {
      content: "";
      -moz-transform: rotate(($base-angle) + deg);
      -ms-transform: rotate(($base-angle) + deg);
      -o-transform: rotate(($base-angle) + deg);
      -webkit-backface-visibility: hidden;
      -webkit-background-clip: content-box;
      -webkit-transform: rotate(($base-angle) + deg);
      backface-visibility: hidden;
      background-clip: content-box;
      background-color: $gray-40;
      bottom: 0;
      height: 100%;
      padding: 1px;
      position: absolute;
      right: 0;
      transform: rotate(($base-angle) + deg);
      width: 4px;
      will-change: transform;
    }

    &:last-child::after {
      content: none;
    }
  }

  @include media-breakpoint-down(sm) {
    & > div {
      width: 100% !important;
      &:not(:last-child)::after {
        content: none;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    & > div {
      width: 50%;
      &:not(:nth-child(odd))::after {
        content: none;
      }
    }
  }
}
