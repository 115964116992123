.component__imagemap {
  &__wrapper {
    position: relative;

    &__labels {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      @include media-breakpoint-down(md) {
        position: static;
      }

      &__label {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @include media-breakpoint-down(md) {
          position: static;
          justify-content: flex-start;
          padding: 12px 0;
        }

        .svg {
          background: $white;
          border-radius: 50%;
          padding: 9px;
          &.background {
            &.blue {
              background: linear-gradient(
                90deg,
                $primary-highlight 0%,
                $primary 100%
              );
            }
            &.orange {
              background: linear-gradient(
                90deg,
                $secondary-highlight 0%,
                $secondary 100%
              );
            }
          }
          &.outline {
            border-width: 2px;
            border-style: solid;
            padding: 7px;
            &.blue {
              border-color: $primary;
            }
            &.orange {
              border-color: $secondary;
            }
          }
        }

        span:not(.svg) {
          padding: 4px 0;
          border-radius: 4px;
          margin-bottom: 0;
          background-color: $white;
          font-size: 14px;
          line-height: 22px;
          white-space: nowrap;
          height: 30px;
          color: $tertiary;
          box-shadow:
            $box-shadow-color 0px 20px 25px -5px,
            rgba($black, 0.04) 0px 10px 10px -5px;

          &.left {
            margin-right: -18px;
            padding-right: 23px;
            padding-left: 8px;
            order: -1;
            @include media-breakpoint-down(md) {
              order: 99;
            }
          }
          &.right {
            margin-left: -18px;
            padding-left: 23px;
            padding-right: 8px;
            order: 99;
          }

          @include media-breakpoint-down(md) {
            margin: 0;
            background-color: transparent;
            padding: 4px 12px;
            box-shadow: none;
            flex-grow: 1;
          }
        }
      }
    }

    .image-wrapper {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}
