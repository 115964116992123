.chat-modal {
  position: absolute;
  left: -307px;
  top: -10px;
  width: 300px;
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba($black, 0.25);
  padding: 20px;
  transition: opacity $transition-speed ease;
  opacity: 0;

  &.state-entering,
  &.state-entered {
    opacity: 1;
  }

  .chat-modal-header {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .svg {
      &:hover {
        cursor: pointer;
      }
    }
    .chat-modal-title {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }
    &:after {
      content: "";
      position: absolute;
      right: -25px;
      top: 10px;
      width: 12px;
      height: 12px;
      background: $white;
      transform: rotate(45deg);
    }
  }
  .chat-modal-text {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .btn {
    width: 100%;
    text-align: center;
    .label {
      width: 100%;
    }
  }
}
