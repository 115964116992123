.tab-primitive__nav--icon {
  .nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.info-box,
.tab-primitive__content--info-box {
  box-shadow: $box-shadow;
  background: $white;
  color: $gray-100;

  & > .tab-primitive__content__item {
    padding: 30px;

    @include media-breakpoint-down(sm) {
      padding: 30px 15px;
    }

    .container {
      padding-left: 0px;
      padding-right: 0px;
    }
    .card-navigation-wrapper {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .info-header {
      margin-bottom: 30px;

      @include media-breakpoint-down(lg) {
        margin-bottom: 8px;
      }
    }
    .title {
      padding-bottom: 15px;
    }

    @include media-breakpoint-down(xs) {
      .info-body {
        p,
        li:not(.list-inline-item),
        nav.tabs > a {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}

.pss-page .info-box,
.pss-page .tab-primitive__content--info-box {
  .info-body {
    .table {
      tr {
        td:first-child {
          width: 30%;
        }
        td:last-child {
          width: 70%;
        }
      }
    }
  }
}

.tab-primitive {
  position: relative;
  padding-top: 10px;

  .tab-primitive__nav {
    padding-bottom: 2px;
    overflow: scroll;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.component__tabs {
  .tab-content {
    position: relative;
  }
  .tab-primitive__nav {
    padding-bottom: 2px;
    overflow: scroll;
    flex-wrap: nowrap;
    border-bottom: 1px solid $gray-20;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &--big {
      .nav-link {
        padding-bottom: 10px;
      }
    }
  }
}

.update-box {
  position: relative;
}

.product-update-box {
  margin-top: 2em;
}

.solution-update-box {
  margin-top: 2em;
}

.section-title {
  margin-bottom: 30px;
  h4 {
    padding-bottom: 15px;
  }
}

.vector-list {
  ul {
    @include unstyled-list;
    padding-left: 10px;
    margin-bottom: 20px;

    ul {
      padding-left: 30px !important;
    }

    li {
      position: relative;
      padding: 5px 0 5px 30px;
    }
    li:before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0px;
      top: 11px;
      height: 20px;
      width: 20px;
      background-image: url("#{$asset-path}/shape/vector.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.link {
  .label {
    margin-left: 10px;
  }
}

.link-separate {
  margin: 0 10px;
  color: $gray-20;
}

.overview {
  .overview-description {
    margin-bottom: 20px;
  }
  .swiper-slide {
    &:hover {
      .fullscreen-slider-icon {
        opacity: 1;
      }
    }
  }
  .fullscreen-slider-icon {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    right: 10px;
    bottom: 10px;
    box-shadow: $box-shadow;
    padding: 5px;
    background: $white;
    &:hover {
      cursor: pointer;
    }
  }
  .tabs > .nav-item {
    flex: none;
  }
}

// TODO: move secton to separate file
.section {
  // padding: 30px 0;
  &-dark {
    background: $gray-10;
  }
}

.section-transition {
  // just reduce height on smaller devices
  height: 90px;
  &-dark {
    background-image: linear-gradient(to top left, $white 50%, $gray-10 50%);
  }
  &-light {
    background-image: linear-gradient(to top left, $gray-10 50%, $white 50%);
  }
}

.product-card {
  ul {
    @extend .bullet-point-list;

    li {
      margin-bottom: 10px;
    }
  }
  .dropdown-toggle,
  .dropdown-menu {
    min-width: 355px;

    @include media-breakpoint-down(xl) {
      min-width: 314px;
    }
  }

  .product-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    .excerpt {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .product-img {
    div[class*="background-gradient"] {
      width: auto;
      @include media-breakpoint-down(lg) {
        left: 0 !important;
        margin-left: 0 !important;
      }
    }

    @include media-breakpoint-down(lg) {
      order: 1;
      margin-top: 0 !important;
    }
  }
}

.product-info-mobile {
  display: none;
  margin-bottom: 20px;

  .image-caption {
    display: none;
  }
  .product-info {
    padding-top: 20px;

    h3,
    .h3 {
      font-size: 22px;
      font-weight: 300;
      line-height: 28px;
      margin-bottom: 10px;
    }

    h5,
    .h5 {
      font-size: 16px;
      line-height: 24px;
    }

    .inline-actions {
      display: flex;
      justify-content: flex-start;

      flex-wrap: wrap;

      .card-dropdown {
        width: 100%;

        button.dropdown-toggle {
          width: 100%;
        }
      }

      button.btn.btn-primary.btn-lg {
        width: 20%;

        @include media-breakpoint-down(sm) {
          width: auto;
        }
      }
    }

    ul {
      @extend .bullet-point-list;
    }
    .dropdown-toggle,
    .dropdown-menu {
      min-width: 355px;

      @include media-breakpoint-down(xl) {
        min-width: 314px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    display: block;
  }
}

.inline-actions {
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .btn {
    @include media-breakpoint-down(sm) {
      margin-right: 0 !important;
    }
  }

  margin-bottom: 5px;

  > *:not(:last-child) {
    margin-right: 10px;
  }

  > * {
    margin-bottom: 15px;
  }

  &.secondary-actions {
    &:last-child {
      margin-bottom: -15px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: row;
      justify-content: center !important;
    }

    .btn-small {
      padding-right: 5px;
      padding-left: 35px;
    }

    > *:not(:last-child) {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .btn {
        margin-right: 10px;
      }

      &::after {
        display: block;
        content: "/";
        color: $gray-40;
      }
    }
  }
}

.featured-logos {
  display: flex;

  .nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }

  .image-wrapper {
    margin-right: 15px;
    flex-shrink: inherit !important;
    height: 70px;
    width: 70px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.platform {
  position: relative;

  .eyecatcher-wrapper {
    z-index: 10;
    pointer-events: none;

    .eyecatcher {
      width: auto;

      @include media-breakpoint-down(lg) {
        left: 0 !important;
        margin-left: 10% !important;
      }

      @include media-breakpoint-down(xs) {
        left: 0 !important;
        margin-left: 0 !important;
      }
    }
  }

  .kp-background,
  .eyecatcher-wrapper {
    height: 100%;
    position: absolute;
    width: 55.5% !important;
    right: 0;

    @include media-breakpoint-up(4xl) {
      width: 51.5% !important;
    }

    @include media-breakpoint-down(lg) {
      width: 100% !important;
      clip-path: none !important;
    }
  }

  .krohne-pick-background {
    height: 100%;
    width: 100%;

    img {
      height: 100% !important;
      width: 100% !important;
      position: static !important;
      object-fit: cover;
      -webkit-object-position: center center;
      object-position: center center;
    }

    > span:first-child {
      height: 100% !important;
      width: 100% !important;
    }

    @include media-breakpoint-down(lg) {
      position: absolute;
    }
  }
  .slider__product-slider .swiper-wrapper {
    align-items: center;
  }
}

.pss-page {
  @include media-breakpoint-down(lg) {
    .section {
      position: relative;
      // clip-path: polygon(0 40px, 100% 0%, 100% 100%, 0% 100%);
      padding-top: 40px;
    }

    .section-dark {
      padding-bottom: 15px;
    }

    .section-transition {
      height: 20px;
    }

    .outer-carousel.default:not(.single-slide) {
      overflow: hidden;
    }

    .carousel.slide {
      .carousel-inner {
        &:before {
          background-image: none !important;
        }
      }

      .variant-products {
        max-width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        display: flex !important;
        flex-wrap: wrap !important;

        .carousel-caption {
          position: relative;
          margin-left: 0 !important;
          padding: 0 !important;
          clip-path: none;
          order: 2 !important;

          .h5,
          h5 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            margin-bottom: 5px;
          }

          .h6,
          h6 {
            font-size: 14px;
            line-height: 21px;
            font-weight: 700;
            margin-bottom: 5px;
          }
        }
      }
    }

    .carousel-control-prev .svg,
    .carousel-control-next .svg {
      &:after {
        content: "";
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: -10px;
        right: -10px;
      }
    }

    .outer-carousel.default:not(.single-slide)
      .carousel
      .carousel-control-next {
      right: 0;
    }

    .outer-carousel.default:not(.single-slide)
      .carousel
      .carousel-control-prev
      .svg {
      margin-left: 0;
    }

    .outer-carousel:not(.platform) .carousel-item.image-left.image-small {
      width: 100% !important;
      position: relative;
    }
  }
  .table-striped td p {
    margin-bottom: 0;
    font-size: inherit;
    font-weight: 400;
    line-height: inherit;
  }

  .product-img {
    position: relative;

    .slider--product.slider--hidden {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    .swiper-container-thumbs-wrapper.is-inactive {
      .swiper-slide-thumb-active {
        opacity: 0.3 !important;
      }
    }

    .three-sixty-button {
      position: absolute;
      top: 1rem;
      right: 0;
    }

    .product-360 {
      position: absolute;
      top: 0;
      left: 100px;
      right: 0;
      z-index: 9;
      padding: 0 15px;

      @include media-breakpoint-down(lg) {
        left: 0;
      }
    }

    .static-image, .card-image, .image-wrapper, .image-wrapper div.d-flex, .image-wrapper img {
        height:100%
    }
  }
}

.technology-page {
  .info-box,
  .tab-primitive__content--info-box {
    & > .container__section > .container {
      @include media-breakpoint-up(md) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}
