.component__accordion {
  .accordion {
    &__block {
      margin-bottom: 10px;
      box-shadow: 0px 3px 10px $box-shadow-color;
      border-left: 4px solid $white;
      transition: all 0.3s ease-in-out;

      &:last-child {
        padding-bottom: 0;
      }

      &__headline {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        border-bottom: 0px solid $white;
        padding-left: 26px;
        transition: color 0.35s ease;
        &:hover {
          cursor: pointer;
          h4 {
            color: $primary;
          }
        }
        h4 {
          padding: 20px 0;
          margin: 0;
          font-weight: 300;
          color: $tertiary;
        }
        &__closing-button {
          width: 84px;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $white;
          transition: background-color 0.35s ease;
          clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%);

          // unset default button styles
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;

          &:before {
            content: "";
            background-color: $white-highlight;
            width: 8px;
          }

          .svg {
            transition: transform 0.35s ease;
            transform: rotate(0deg);
          }
        }
      }

      &__content {
        transition: all 0.35s ease !important;
        padding: 0px 15px 0px 11px;
      }

      &.active {
        border-color: $primary;

        .accordion__block__headline {
          border-bottom: 1px solid $white-highlight;
          h4 {
            color: $primary;
            font-weight: 400;
          }
          &__closing-button {
            background-color: $white-highlight;
            .svg {
              transform: rotate(45deg);
            }
          }
        }
        .accordion__block__content {
          padding: 30px 15px 30px 11px;
        }
      }
    }
  }
}
