.component__images {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
  .swiper-slide {
    height: auto;

    .hover__zoom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      height: 100%;
    }
  }
  .flex-images {
    align-items: center;
    > * {
      flex: 1 1 auto;
    }
    > div {
      margin-left: 32px;
      &:first-child {
        margin-left: 0;
      }
      position: relative;
    }
  }
}
