.slider {
  position: relative;
  &.slider--default__navigation {
    display: flex;
    align-items: center;
    column-gap: 15px;
    .swiper {
      // Width of the arrows: 36px; Gap: 15px
      width: calc(100% - 36px * 2 - 15px * 2);
    }

    .slider__prevButton {
      order: 0;
    }
    .slider__nextButton {
      order: 2;
    }
  }
  .swiper {
    width: 100%;
    order: 1;
  }

  // Swiper overrides

  .swiper-wrapper {
    @include media-breakpoint-down(md) {
      height: unset;
    }
  }

  .swiper-slide {
    opacity: 1 !important;
    transform: none !important;
    height: auto !important;
    @include media-breakpoint-down(md) {
      height: auto;
    }
  }

  .slider--fixHeightMode {
    height: var(--fixHeight, auto);

    .swiper-wrapper {
      height: 100%;
    }

    .swiper-slide {
      width: auto;
      height: 100% !important;

      & > div {
        height: 100%;

        .cld-responsive {
          height: 100%;
          width: auto;
        }
      }
    }
  }

  // Navigation

  &__navigation {
    &--inset {
      pointer-events: none;
      position: absolute;
      bottom: calc(4.75rem);
      right: calc(50% + 130px);
      z-index: 3;

      @include media-breakpoint-down(xl) {
        right: calc(50% + 110px);
        bottom: calc(1.5rem);
      }

      @include media-breakpoint-down(lg) {
        right: calc(50% + 95px);
      }

      @include media-breakpoint-down(md) {
        left: 0;
        top: 28.125vw; // = ((9 / 16) / 2) * 100vw
        bottom: auto;
        display: flex !important;
        justify-content: space-between !important;
        width: 100%;
        margin-top: 0 !important;

        .svg svg {
          filter: drop-shadow(0 0 4px rgba($black, 0.7));
        }

        &.white {
          .svg svg {
            filter: drop-shadow(0 0 4px rgba($white, 0.7));
          }
        }
      }

      &.white {
        .svg svg {
          fill: $tertiary;
        }
      }
    }
    order: 2;
  }

  .slider__prevButton,
  .slider__nextButton {
    pointer-events: all;
    border: none;
    background: transparent;
  }

  > .slider__prevButton,
  > .slider__nextButton {
    width: 50px;
    height: 50px;
    z-index: 10;
    margin: 0;
    padding: 0;
    outline: 0;
    display: block;
    flex-shrink: 0;

    &--hidden {
      display: none;
    }
  }

  &--navigationOnHover {
    .slider__prevButton,
    .slider__nextButton {
      transition: 0.2s opacity;
      opacity: 0;
    }

    &:hover {
      .slider__prevButton,
      .slider__nextButton {
        opacity: 1;
      }
    }
  }

  // Indicators

  &__indicators {
    &__list {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 21;
      display: flex;
      justify-content: center;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        pointer-events: none;
      }

      &:hover {
        border-top: rgba($white, 0.5) solid 4px;

        li {
          color: $tertiary;
          height: 56px;

          &:hover {
            color: white;
            background-color: $primary-highlight;
            background-image: none;
          }
        }
      }

      li {
        display: inline-flex;
        justify-content: center;
        position: relative;
        box-sizing: content-box;
        flex: 1;
        height: 0.5rem;
        cursor: pointer;
        color: $gray-20;
        background-color: $gray-20;
        background-size: 200% 100%;
        background-position: right bottom;
        text-align: center;
        line-height: 54px;
        transition:
          height $transition-speed ease,
          color $transition-speed ease,
          background-color $transition-speed ease;

        &:not(:last-child) {
          border-right: 2px solid white;
        }

        // Enhancing The Clickable Area Size
        &:after {
          content: "";
          position: absolute;
          top: -10px;
          bottom: 0;
          left: 0;
          right: 0;
        }

        &.active:not(:hover) {
          background-position: left bottom;
          color: white;
        }
      }

      .swiper-pagination-bullet {
        @include media-breakpoint-down(md) {
          display: none;
          height: 0.5rem;
        }

        &.swiper-pagination-bullet-active {
          background: $gray-60;

          @include media-breakpoint-down(md) {
            display: inline-flex;
          }
        }
      }
    }
  }

  // Autoplay

  &--autoplay {
    .slider__indicators__list {
      .preActive {
        background-color: $gray-60;
        transition:
          height 0.3s ease,
          color 0.3s ease,
          background-color 0s ease;
      }

      .swiper-pagination-bullet-active {
        position: relative;
        background-color: $gray-20;
        z-index: 1;

        &:hover::before {
          width: 0% !important;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          background-color: $gray-60;
          z-index: -1;
          animation: fillAnimation var(--autoplay-delay) linear;
        }

        @keyframes fillAnimation {
          0% {
            width: 0%;
          }
          100% {
            width: 100%;
          }
        }
      }
    }

    &.slider--paused {
      .slider__indicators__list {
        .swiper-pagination-bullet-active::before {
          animation: none !important;
        }
      }
    }
  }

  // Preview

  &__preview {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20%;
    background: linear-gradient(
      90deg,
      rgba($white, 0) 0%,
      rgba($white, 1) 100%
    );
    z-index: 50;
    pointer-events: none;
  }

  // Product Slider

  .eye-catcher-container {
    overflow: hidden;
  }

  .eye-catcher {
    z-index: 99;
    right: 0px;
    top: 15%;
    padding: 10px 25px 10px 10px;
    color: $white;
    background-image: linear-gradient(
      to left,
      $secondary,
      $secondary-highlight
    );
  }
  &--product {
    margin-left: 100px;

    @include media-breakpoint-down(lg) {
      margin-left: 0 !important;
    }

    .card-image,
    .image-wrapper > span:first-child {
      width: 100% !important;
    }

    > .slider__prevButton {
      left: -55px !important;

      @include media-breakpoint-down(md) {
        left: 10px !important;
      }

      .eye-catcher {
        left: 0;
      }
    }

    > .slider__nextButton {
      left: 100% !important;

      @include media-breakpoint-down(md) {
        left: calc(100% - 60px) !important;
      }
    }

    .swiper-slide .image-wrapper {
      display: inline-flex;
      align-items: center;
      width: 100%;
    }
  }

  // Related Products Slider
  &.slider--relatedProducts {
    .swiper {
      width: 100%;
      padding: 0 15px;
      margin: 0 -15px;
    }

    .eyecatcher {
      overflow-x: visible;
      pointer-events: none;
      position: absolute;
      top: 10px;
      left: -10px;
      font-size: small;
      font-weight: 400;
    }

    .swiper-slide:not(.swiper-slide-visible) {
      visibility: hidden;
    }

    .swiper-slide.swiper-slide-visible {
      visibility: visible;
    }

    // desktop navigation
    &.slider--default__navigation {
      .slider {
        &__prevButton,
        &__nextButton {
          position: absolute;
        }

        &__prevButton {
          left: -5%;
        }
        &__nextButton {
          right: -5%;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      &.slider--navigationOnHover {
        .slider__prevButton,
        .slider__nextButton {
          opacity: 1;
        }
      }

      > .slider__prevButton,
      > .slider__nextButton {
        width: 25px;
        height: 25px;
        // top: 242.5px;

        svg {
          width: 20px !important;
          height: 20px !important;
        }
      }

      > .slider__prevButton {
        left: calc(-25px * 1.5);
      }

      > .slider__nextButton {
        left: calc(100% + (25px / 2));
      }
    }
  }

  // Hero Slider
  &--hero {
    min-height: 600px;

    @include media-breakpoint-down(md) {
      min-height: 500px;
      display: flex;
      align-items: stretch;
    }

    .swiper-wrapper {
      min-height: 100%;
      max-width: 100vw;
    }

    .slide {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: stretch;
      }

      &__imageArea {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 1;

        @include media-breakpoint-down(md) {
          position: relative;
          justify-content: flex-start;
        }

        .eye-catcher-container {
          overflow: hidden;
          pointer-events: none;
        }

        .slide__imageArea__image {
          position: relative;
          min-width: calc(50%);
          height: 100%;

          img {
            object-fit: cover;
          }

          @include media-breakpoint-down(md) {
            width: 100%;
            height: auto;
          }
        }

        &__background {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          flex-grow: 1;
          flex-shrink: 0;
          min-width: 50%;
          height: 100%;
          background: transparent;
          z-index: 1;

          @include media-breakpoint-down(md) {
            flex-grow: 0;
            flex-shrink: 1;
            right: 0;
            height: auto;
            clip-path: none;
            background: $white;
            background: linear-gradient(
              90deg,
              rgba($white, 0.5) 0%,
              rgba($white, 0) 15%,
              rgba($white, 0) 85%,
              rgba($white, 0.5) 100%
            );
          }
        }

        &__image {
          position: relative;
          min-width: calc(55% - 4rem);
          height: 100%;

          @include media-breakpoint-down(md) {
            width: 100%;
            height: auto;
          }

          .image-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 100%;
            min-height: 100%;
            max-width: none !important;

            @include media-breakpoint-down(md) {
              position: static;
              transform: none;
              max-width: 100% !important;
            }

            img {
              object-fit: cover;
            }
          }

          .lightbox-video {
            height: 100%;

            span.svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              cursor: pointer;
            }
          }
        }
      }

      &__content-wrapper {
        position: relative;
        min-width: 100%;
        height: 600px;
        max-height: 600px;
        z-index: 2;
        pointer-events: none;

        .container,
        .row {
          height: 100%;
        }

        @include media-breakpoint-down(md) {
          height: auto;
          flex-grow: 1;
          padding-top: 3.4vw;
          max-height: none;
        }

        .slide__contentArea__content {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: stretch;
          height: 100%;
          padding-bottom: 4rem;
          pointer-events: auto;

          @include media-breakpoint-down(md) {
            justify-content: flex-start;
            padding-top: 0.75rem;
            padding-bottom: 2.5rem;
          }

          &__headline {
            margin-bottom: 20px;

            @include media-breakpoint-down(md) {
              font-size: 22px;
              line-height: 28px;
              font-weight: 400;
              margin-bottom: 0.5rem;
            }
          }

          &__text {
            p:last-child {
              margin-bottom: 0;
            }

            @include media-breakpoint-down(md) {
              p {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
              }

              ul p {
                margin-bottom: 8px;
              }
            }
          }

          &__buttons {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            margin-top: 30px;

            @include media-breakpoint-down(md) {
              flex-wrap: wrap;
              margin-top: 10px;
              margin-bottom: -10px;

              & > * {
                margin-bottom: 10px;
              }
            }
          }
        }
      }

      &__content-wrapper::before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        min-width: calc(50% + 84px);
        transform: skewX(-8deg);
        transform-origin: top right;
        transform-box: view-box;
        content: "";
        background: $white;
        overflow: hidden;

        @include media-breakpoint-down(md) {
          width: 100%;
          transform: skew(0);
        }
      }

      &--primary {
        .slide {
          &__contentArea__content {
            color: $white;
          }

          &__content-wrapper::before {
            $list: $primary, $primary-highlight;
            @include gradient(to left, $list);
            border-radius: 1px;
          }

          &__imageArea__background {
            @include media-breakpoint-down(md) {
              background: $black;
              background: linear-gradient(
                90deg,
                rgba($black, 0.5) 0%,
                rgba($black, 0) 15%,
                rgba($black, 0) 85%,
                rgba($black, 0.5) 100%
              );
            }
          }

          @include media-breakpoint-down(md) {
            &__contentArea {
              $list: $primary, $primary-highlight;
              @include gradient(to left, $list);
            }
          }
        }
      }
      &--secondary {
        .slide {
          &__contentArea__content {
            color: $white;
          }

          &__content-wrapper::before {
            $list: $secondary, $secondary-highlight;
            @include gradient(to left, $list);
            border-radius: 1px;
          }

          @include media-breakpoint-down(md) {
            &__contentArea {
              $list: $secondary, $secondary-highlight;
              @include gradient(to left, $list);
            }
          }

          &__imageArea__background {
            @include media-breakpoint-down(md) {
              background: $black;
              background: linear-gradient(
                90deg,
                rgba($black, 0.5) 0%,
                rgba($black, 0) 15%,
                rgba($black, 0) 85%,
                rgba($black, 0.5) 100%
              );
            }
          }
        }
      }
      &--tertiary {
        .slide {
          &__contentArea__content {
            color: $white;
          }

          &__content-wrapper::before {
            $list: $tertiary, $tertiary-highlight;
            @include gradient(to left, $list);
            border-radius: 1px;
          }

          &__imageArea__background {
            @include media-breakpoint-down(md) {
              background: $black;
              background: linear-gradient(
                90deg,
                rgba($black, 0.5) 0%,
                rgba($black, 0) 15%,
                rgba($black, 0) 85%,
                rgba($black, 0.5) 100%
              );
            }
          }

          @include media-breakpoint-down(md) {
            &__contentArea {
              $list: $tertiary, $tertiary-highlight;
              @include gradient(to left, $list);
            }
          }
        }
      }

      &--green {
        .slide {
          &__contentArea__content {
            color: $white;
          }

          &__content-wrapper::before {
            $list: $green-light, $green-light-highlight;
            @include gradient(to left, $list);
            border-radius: 1px;
          }

          @include media-breakpoint-down(md) {
            &__contentArea {
              $list: $green-light, $green-light-highlight;
              @include gradient(to left, $list);
            }
          }

          &__imageArea__background {
            @include media-breakpoint-down(md) {
              background: $black;
              background: linear-gradient(
                90deg,
                rgba($black, 0.5) 0%,
                rgba($black, 0) 15%,
                rgba($black, 0) 85%,
                rgba($black, 0.5) 100%
              );
            }
          }
        }
      }

      &--white {
        .slide {
          &__contentArea__content {
            color: initial;
          }

          &__content-wrapper::before {
            background: $white;
            border-radius: 1px;
          }

          &__imageArea__background {
            @include media-breakpoint-down(md) {
              background: $white;
              background: linear-gradient(
                90deg,
                rgba($white, 0.5) 0%,
                rgba($white, 0) 15%,
                rgba($white, 0) 85%,
                rgba($white, 0.5) 100%
              );
            }
          }

          @include media-breakpoint-down(md) {
            &__contentArea {
              background: $white;
            }
          }
        }
      }
    }
  }
}

// Mobile fallback in contentset
.sliderMobileFallback {
  @include media-breakpoint-down(sm) {
    hr {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    &__item {
      margin-top: 13px !important;
      margin-bottom: 0 !important;
    }

    .btn:not(:disabled):not(.disabled):active,
    .btn:not(:disabled):not(.disabled).active {
      box-shadow: none;
    }

    .btn {
      padding-left: 23px;

      .svg {
        left: 0;
      }
    }
  }
}

// Product slider thumbnails

.swiper-container-thumbs-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 100px !important;

  @include media-breakpoint-down(lg) {
    margin-left: 0 !important;
  }

  .swiper {
    max-width: 356px;
    margin: 0;
  }

  .slider__navigation {
    order: unset;
  }

  .swiper-container-thumbs,
  .swiper-thumbs {
    .swiper-slide {
      transform: none !important;
      opacity: 0.3 !important;
      cursor: pointer;
    }

    .swiper-slide-thumb-active {
      opacity: 1 !important;
    }
  }
}

.product-img {
  .slider {
    &__prevButton,
    &__nextButton {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease;
    }
  }

  &:hover {
    .slider {
      &__prevButton,
      &__nextButton {
        opacity: 1;
        pointer-events: all;

        &.swiper-button-disabled {
          opacity: 0.5;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .slider {
      &__prevButton,
      &__nextButton {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

// Fallback static image

.static-image {
  position: relative;
}

.relatedProducts {
  .related-products-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .related-products-navigation {
      display: flex;
      align-items: center;

      .slider__prevButton,
      .slider__nextButton {
        background-color: transparent;
        border: none;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .col-md-3 {
      padding-bottom: 24px;

      .image-wrapper {
        max-width: 50% !important;
      }
    }
  }
}

.swiper-button-disabled {
  opacity: 0.5;
}
