.news-page-detail {
  .nav .nav-item {
    max-width: 100%;
  }

  .section-transition-dark-top {
    background-image: linear-gradient(
      to bottom right,
      $white 50%,
      $gray-10 50%
    );
  }

  .news-page-contentbar {
    margin-bottom: 165px;

    .news-page-meta {
      font-size: 16px;
      line-height: 24px;
      color: $breadcrumb-active-color;
      margin-bottom: 16px;

      > time,
      > span {
        &:after {
          content: "/";
          color: $gray-40;
          margin-left: 10px;
          margin-right: 10px;
        }

        &:last-child {
          &:after {
            content: none;
          }
        }
      }
    }

    .news-page-content {
      margin-bottom: 47px;
    }

    h1,
    .h1 {
      margin-bottom: 27px;
    }

    .news-title {
      @include media-breakpoint-down(sm) {
        @include typography(300, 30, 1.1);
      }
    }

    h5,
    .h5 {
      margin-bottom: 33px;
    }

    .image-wrapper {
      margin-bottom: 1.25rem;
      position: relative;

      img {
        width: 100% !important;
        -webkit-object-fit: cover;
        object-fit: cover;
        -webkit-object-position: center center;
        object-position: center center;
      }
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 75px;
    }
  }

  .news-page-sidebar {
    margin-top: 53px;

    h5,
    .h5 {
      &:after {
        content: "";
        display: block;
        border-bottom: 1px solid $gray-40;
        padding-top: 15px;
      }
    }

    .nav {
      margin-top: -15px;

      .nav-item {
        border-bottom: 1px solid $gray-20;

        a {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          color: $tertiary;
          padding-bottom: 18px;
          padding-top: 18px;
          padding-left: 58px;
          text-align: left;

          .svg {
            left: 16px;

            svg {
              fill: $tertiary;
            }
          }

          &:active,
          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    .sidebar-downloads {
      margin-bottom: 55px;
    }

    .sidebar-relations {
      .relation-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        padding: 4px 0;
        border-bottom: 1px solid $gray-20;

        .image-preview {
          width: 50px;
          height: 50px;
        }

        .label {
          margin-left: 8px;
          color: $tertiary;
          font-size: 1rem;
        }
      }
    }

    @include media-breakpoint-down(md) {
    }
  }
}

.related-news {
  background-color: $gray-10;
  padding-top: 48px;
  padding-bottom: 48px;

  .card {
    background-color: transparent;
  }
}

.news-page-contact {
  h5,
  .h5 {
    margin-top: 54px;
    margin-bottom: 1.25rem;

    &:after {
      content: "";
      display: block;
      border-bottom: 1px solid $gray-40;
      padding-top: 15px;
    }
  }
}

.section-transition-dark {
  margin-bottom: 50px;
}

.company {
  margin-bottom: 85px;
}

.svg.download > svg {
  width: 13px;
  height: 13px;
}

.event-detail-image {
  height: 100px;
}
