.dp-content {
  ul {
    @extend .bullet-point-list;
  }
}

.dp-nav a {
  cursor: pointer;
}

.dp-slider {
  width: 100vw;
}

.dp-recommendation-slide {
  .dp-recommendation-slide-visual {
    min-width: 116px;
    width: 116px;
  }

  .dp-recommendation-slide-product {
    flex-direction: column;
    padding-left: 20px;
    width: 100%;
  }
}

.dp-recommendations {
  height: 100%;
  border-bottom: 1px solid $gray-20;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  @include media-breakpoint-down(md) {
    margin-top: 70px;
  }
}

.dp-recommendations-container {
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  @include media-breakpoint-down(md) {
    min-height: 70px !important;
  }

  .hidden {
    display: none;
  }
}

.dp-recommendations-details {
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 0px;

    .dp-recommendations-cell:first-child {
      width: 40%;
    }
    .dp-recommendations-cell:last-child {
      width: 60%;
    }
  }
}

.dp-recommendations-nav {
  align-items: center;
  background-color: transparent;
  bottom: 0;
  display: flex;
  left: 30px;
  min-height: 70px;
  pointer-events: none;
  position: absolute;
  right: 30px;
  top: 0;
  z-index: 10;

  @include media-breakpoint-down(md) {
    background-color: white;
    bottom: unset;
    left: 0;
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;
    right: 0;
  }

  &__products {
    background-color: unset !important;
  }

  .react-select__container {
    position: absolute;
    left: 50px;
    right: 50px;
    pointer-events: all;
  }
  &__products {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    bottom: 34px;
    right: 0px;

    @include media-breakpoint-up(lg) {
      bottom: 24px;
      right: 104px;
    }
  }

  .slider__prevButton,
  .slider__nextButton {
    background-color: transparent;
    border: none;
    pointer-events: all;
  }
}

.dp-recommendations-cell {
  position: relative;

  @include media-breakpoint-up(lg) {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  @include media-breakpoint-down(md) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  p {
    margin: 0px !important;

    &.dp-recommendations-headline {
      margin-top: 0px !important;
      margin-bottom: 4px !important;
      font-size: 14px !important;
    }
  }
}

.dp-recommendations-process {
  background-color: $gray-10;
  padding-top: 20px;
  padding-bottom: 20px;

  @include media-breakpoint-up(lg) {
    padding-left: 120px !important;
    padding-right: 30px !important;
  }

  p {
    margin: 0px !important;
    padding-left: 4px;

    &.dp-recommendations-headline {
      margin-top: 0px !important;
      margin-bottom: 4px !important;
      font-size: 14px !important;
    }
  }

  p {
    font-size: 16px !important;

    @include media-breakpoint-up(3xl) {
      font-size: 22px !important;
    }
  }
}

.dp-recommendations-requirements {
  ul {
    margin-bottom: 0px !important;

    li {
      margin-bottom: 10px !important;
    }
  }
}

.dp-process-dot {
  position: relative;
  box-sizing: content-box;
  width: 8px;
  height: 8px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: $orange-highlight;

  &:after {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    animation: circle-pulse 2s infinite;
    border-radius: 50%;
    box-shadow: 0 0 0 0 $orange-highlight;
  }
}

@keyframes circle-pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $orange-highlight;
  }
  50% {
    transform: scale(1);
    box-shadow: 0 0 0 6px transparent;
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 transparent;
  }
}

.dp-section {
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.dp-content > .dp-section,
.dp-content > .dp-overview {
  display: none !important;
}

.dp-content > .dp-overview.dp-active,
.dp-content > .dp-section.dp-active {
  display: block !important;
}

@media (min-width: 1200px) {
  .dp-content > .dp-section.dp-active {
    display: flex !important;
  }
}

@media (min-width: 992px) {
  .dp-nav {
    border-right: 1px solid $gray-400;
  }

  .dp-section {
    flex-wrap: unset;
    margin-right: unset;
    margin-left: unset;
  }
}

.dp-content .btn {
  margin-top: 45px;
}

.detailprocess__header {
  .container-fluid {
    padding-left: 0;
    padding-right: 0;

    .row {
      margin-left: 0;
      margin-right: 0;

      .col-md-12 {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.detailed-process-header {
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 56px;
  position: relative;
  z-index: 1;

  &__content {
    position: relative;
    width: 100%;
  }

  &__buttons {
    z-index: 11;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}

.dp-hero {
  @media all and (display-mode: fullscreen) {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    height: 100vh;

    .detailed-process-header {
      display: flex !important;
      justify-content: stretch;
      align-items: center;
      flex-grow: 1;
    }

    .slider {
      flex-shrink: 0;
    }
  }
}

@keyframes glow {
  0% {
    background: transparent;
    box-shadow: none;
  }
  100% {
    background: rgba($secondary, 0.2);
    box-shadow: rgba($secondary, 0.4) 0 0 20px 0;
  }
}

.detailed-process-header-link {
  display: block;
  position: absolute;
  cursor: pointer;
  color: transparent;
  font-size: 0;
  border-radius: 10px;
  z-index: 5;
  will-change: box-shadow, background;
  transition: all 0.25s ease-in;

  &:hover {
    background: rgba($secondary, 0.1);
    box-shadow: rgba($secondary, 0.2) 0 0 20px 0;
  }

  &.m--no-hover:hover {
    background: unset;
    box-shadow: unset;
  }

  &.m--glow {
    animation: glow 1.5s infinite alternate ease-in-out;
  }
}

.detailed-process-links-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.detailed-process-links {
  position: absolute;
  height: 100%;
  margin: 0 auto;
  aspect-ratio: 24 / 10;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  img {
    object-fit: contain;
  }
}

.detailed-process-links::before {
  float: left;
  padding-top: 41.6666667%;
  content: "";
}

.detailed-process-links::after {
  display: block;
  content: "";
  clear: both;
}
.detailed-process-header-overlay .image-wrapper > span {
  max-height: calc(70vh - (var(--header-height)));
  width: auto;
}

.detailed-process-header-overlay {
  position: relative;
  z-index: 2;

  .dp-overlay {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
  }

  .detailed-process-links,
  .dp-overlay-base,
  .dp-overlay {
    width: 100%;
    margin: 0 auto;
    height: auto !important;
    z-index: 5 !important;
    max-height: calc(
      70vh - calc(var(--header-height) - var(--navigation-height))
    );
  }

  .dp-overlay-base {
    z-index: 4 !important;
  }

  .dp-overlay-active {
    display: flex;
  }

  img {
    object-fit: contain;
  }
}

.dp-recommended {
  height: 100%;

  @include media-breakpoint-up(lg) {
    padding-right: 120px !important;
    border-left: 1px solid $gray-20;
  }

  @include media-breakpoint-down(md) {
    border-top: 1px solid $gray-20;
    border-bottom: 1px solid $gray-20;
  }

  .swiper-slide {
    display: flex;
  }

  .swiper-slide-next {
    margin-left: 1px;
  }

  .swiper-slide > .row {
    width: 100%;
  }

  .slider__pagination {
    right: 0;
    top: 0;
  }
}

// for iOS safari
@supports not (aspect-ratio: 24 / 10) {
  .detailed-process-links,
  .detailed-process-header-overlay .image-wrapper > span,
  .detailed-process-header-overlay .detailed-process-links,
  .detailed-process-header-overlay .dp-overlay-base,
  .detailed-process-header-overlay .dp-overlay {
    max-height: unset;
  }

  .detailed-process-links {
    position: relative;
    top: unset;
    bottom: unset;
    left: unset;
    transform: unset;
  }
}

.popover {
  margin-left: 9px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  padding: 4px 8px;

  .arrow:before {
    border: none;
  }
}
