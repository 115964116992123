.anchor-links {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-columns: 1fr;
  gap: 10px;
  margin: 0;
  padding: 0;

  &[data-columns="1"] {
    grid-template-columns: 1fr;
  }

  &[data-columns="2"] {
    grid-template-columns: repeat(2, 1fr);
  }

  &[data-columns="3"] {
    grid-template-columns: repeat(3, 1fr);

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &[data-columns="4"] {
    grid-template-columns: repeat(4, 1fr);

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &[data-columns="5"] {
    grid-template-columns: repeat(5, 1fr);

    @include media-breakpoint-down(xl) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &[data-columns="6"] {
    grid-template-columns: repeat(6, 1fr);

    @include media-breakpoint-down(xl) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include media-breakpoint-down(xs) {
    grid-template-columns: 1fr !important;
  }

  &__item {
    display: block;
    list-style: none;

    &__link {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: stretch;
      width: 100%;
      height: 100%;
      text-decoration: none !important;

      &:hover {
        .anchor-links__item__link__box:before {
          opacity: 1;
        }

        .anchor-links__item__link__box__icon > .svg {
          transform: translateY(100%);
        }
      }

      &__box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        background-image: linear-gradient(
          ((90 + $base-angle) + deg),
          $primary 0%,
          $primary calc(100% - 60px),
          $primary-highlight calc(100% - 60px),
          $primary-highlight 100%
        );
        min-height: 60px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: linear-gradient(
            to right,
            $primary,
            $primary-highlight
          );
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }

        &__content {
          display: flex;
          align-items: center;
          gap: 8px;
          padding-left: 1rem;
          color: $white;
          position: relative;

          &__title {
            font-weight: 500;
            font-size: 1rem;
            margin: 0;
          }
        }

        &__icon {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          width: 18px;
          height: 18px;
          overflow: hidden;
          margin: 20px;
          position: relative;

          & > .svg {
            transition: transform 0.3s ease-in-out;
          }
        }
      }
    }
  }
}
