.btn {
  @include transition(color, 50ms);
  display: inline-flex;
  padding: 8px 15px 8px 15px;
  border-radius: 0;
  border-width: 0px;
  color: $tertiary;
  border-color: transparent;
  position: relative;
  min-height: 36px;
  height: max-content;
  margin-right: 10px;

  .shape-container {
    position: absolute;
    left: -1px;
    svg path {
      @include transition(fill);
    }
  }

  .svg {
    position: absolute;
    width: $icon-small !important;
    height: $icon-small;
    top: 50%;
    transform: translateY(-50%);

    svg {
      vertical-align: top;
    }
  }

  .svg:first-child {
    @include transition(left);
    left: 7px;
  }

  &.icon-nav-link {
    padding-right: 22px !important;

    .svg:first-child {
      @include transition(left);
      left: 0px !important;
    }

    .svg:last-child {
      @include transition(right);
      right: 0px;
    }
  }

  &:not(.btn-outline):not(.btn-dropdown):not(.btn-sm) {
    padding: 8px 15px 8px 40px;
    &:hover {
      .shape-container {
        display: none;
      }

      .svg {
        left: 12px;
      }
    }
  }
  &.btn-sm {
    padding-left: 30px;
    padding-top: 7px;

    .p.label {
        font-weight: 400;
      }
  }
  &.btn-outline {
    border: 1px solid $light;
    .svg {
      width: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .p.label {
    line-height: 1.3;
    margin: 0;
    width: 100%;
    font-weight: 400;
  }

  &.btn-white {
    .p.label {
      line-height: 1.3;
    }
  }

  &.toggle-button {
    box-shadow: none !important;
    padding-left: 22px;
    outline: 0;
    &:hover {
      color: $primary;

      .svg svg {
        fill: $primary;
      }
    }

    .svg {
      left: 0px;
    }
  }

  &.recommend-button {
    &:hover,
    &:active,
    &:focus {
      color: $primary;
      outline: 0;
      box-shadow: none !important;

      .svg svg {
        fill: $primary;
      }
    }
  }

  @include button("primary", $primary, $primary-highlight, to left);
  @include button("secondary", $secondary, $secondary-highlight, to left);
  @include button("tertiary", $tertiary, $tertiary-highlight, to left);
  @include button("white", $white, $white-highlight, to right);
  @include button("salmon", $secondary, $secondary-highlight, to left);
  @include button("green", $green-light, $green-light-highlight, to left);

  &.btn-salmon-outline {
    border-color: $secondary-highlight;
    color: $secondary;
    @include angle($salmon, white, $base-angle);

    &:hover {
      color: $white;
      svg {
        fill: $white;
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
  }

  .btn-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 0;
  }

  &.btn--loading {
    pointer-events: none;

    .p.label {
      opacity: 0;
    }

    .svg {
      opacity: 0;
    }

    .btn-loader {
      opacity: 1;
    }

    &.btn-primary {
      background: linear-gradient(to left, $primary, $primary-highlight);
    }

    &.btn-secondary {
      background: linear-gradient(to left, $secondary, $secondary-highlight);
    }

    &.btn-tertiary {
      background: linear-gradient(to left, $tertiary, $tertiary-highlight);
    }

    &.btn-white {
      background: linear-gradient(to right, $white, $white-highlight);
    }

    &.btn-green {
        background: linear-gradient(to right, $green-light, $green-light-highlight);
      }
  }

  &.btn--icon {
    min-width: auto !important;
    width: 2.25rem;
    padding: 0 0.5rem !important;

    .svg {
      left: 9px !important;
    }

    &.btn-primary {
      background: $primary-highlight;

      &:hover {
        background: $primary;
      }
    }

    &.btn-secondary {
      background: $secondary-highlight;

      &:hover {
        background: $secondary;
      }
    }

    &.btn-tertiary {
      background: $tertiary-highlight;

      &:hover {
        background: $tertiary;
      }
    }

    &.btn-white {
      background: $white-highlight;

      &:hover {
        background: $white;
      }
    }

    &.btn-green {
        background: $green-light-highlight;

        &:hover {
          background: $green-light;
        }
      }
  }
}
.dropdown-item:not(:last-child) {
  border-bottom: 1px solid $dropdown-border-color;
}
.dropdown-item:hover,
.dropdown-item:focus {
  font-weight: 700;
}

.dropdown-menu {
  min-width: 250px;
}
.btn.btn-dropdown {
  width: 250px;
  border: 1px solid $gray-40;
  background: $white;
  color: $tertiary;
  padding: 7px 30px 8px 10px;
  margin: 0;
  &:before,
  &:after {
    content: none;
  }
  .svg {
    transition: none;
    position: absolute;
    left: unset;
    width: 25px;
    right: 0;
    background: white;
    top: 50%;
    transform: translateY(-50%);
  }
}

// RECOMMEND BUTTON POPOVER

#recommendPopover {
  background-color: $white;
  border: none;
  padding: 5px 10px 10px;

  .arrow::before {
    border-right-color: rgba($black, 0.1);

    @include media-breakpoint-down(md) {
      border-right-color: transparent;
      border-top-color: rgba($black, 0.1);
    }
  }

  .recommendPopover {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $white;
      border: none;
      padding: 0;
      font-weight: 700;
      margin: 0 0 15px 0;
    }

    &__closeBtn {
      display: flex;
      margin-left: 24px;
      cursor: pointer;
    }

    &__content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0;
      margin-right: -20px;

      &__icon {
        margin: 0 20px 0 0;
        cursor: pointer;

        a {
          display: flex;
        }
      }
    }
  }
}
