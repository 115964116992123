.react-select__container {
  color: $primary;
  font-size: 14px;
  min-width: 100px;
  max-width: 100%;
  height: 50px;

  &.action-dropdown {
    height: auto;

    .react-select__value-container {
      cursor: pointer;
      padding-left: 12px;
    }

    .react-select__single-value {
      margin-left: 0;
      padding-left: 0;
    }
  }

  .image-wrapper {
    width: 30px !important;
    height: 30px;
  }
  &:hover {
    .react-select__control {
      border: 1px solid $primary !important;
      cursor: pointer;
      .react-select__placeholder {
        color: $primary;
      }
    }
  }
  .react-select__indicator:hover .svg svg {
    fill: $primary;
  }
  &.react-select--is-disabled {
    .react-select__indicators {
      visibility: hidden;
    }
  }
  &.has_border {
    .react-select__menu {
      border-top: 0;
    }
  }
  &.no_border {
    .react-select__control {
      border: 0;
      background-color: transparent;
    }
    .react-select__single-value,
    .react-select__placeholder {
      right: 0;
      padding-left: 2px;
    }
  }
  &.has_value {
    .react-select__control {
      border-bottom: 2px solid $primary !important;
    }
    .react-select__single-value {
      font-weight: 700;
    }
    &:not(.show-indicator) .react-select__dropdown-indicator {
      display: none;
    }
  }
  .react-select__control {
    height: 100%;
    box-shadow: none;
    transition: all 0.3s;
    border: 1px solid $gray-40;
    .svg.search {
      margin: 10px;
    }
    .react-select__placeholder {
      line-height: 1;
    }
  }
  .react-select__single-value {
    color: $primary;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 16px);
    display: flex;
    align-items: center;
    pointer-events: none;
    & > * {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    > p,
    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .react-select__value-container {
    cursor: text;
    padding-left: 16px;
    height: 100%;
    pointer-events: auto;
    > div {
      width: calc(100% - 16px);
      height: 100%;
      display: flex;
      align-items: center;
      > div {
        height: 100%;
        input {
            height: 100%;
        }
      }
    }
  }

  &.no_typing {
    .react-select__value-container {
      cursor: pointer;
    }
  }

  .svg.search + .react-select__value-container {
    padding-left: 0;
  }

  .react-select__input {
    color: $primary;
    caret-color: $primary;
    width: 100%;
    input {
    position: relative;
      width: 100% !important;
      z-index: 1;
    }
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__indicators-container {
    cursor: pointer;
  }
  .react-select__dropdown-indicator {
    padding-right: 16px;

    .svg svg {
      transition: all 0.3s;
    }
  }
  .react-select__clear-indicator {
    padding-right: 16px;

    .svg {
      opacity: 1;

      svg {
        transition: all 0.3s;
      }

      &:hover {
        opacity: 1;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

// ### Specific styling for the two different select types ###

// Both select types
.react-select__menu {
  margin: 0;
  padding: 0;
  border: 1px solid $white-highlight;
  overflow: hidden;
  > div:not(.react-select__menu-list) {
    pointer-events: none;
  }

  .react-select__menu-list {
    padding: 0;
    * {
      font-size: 14px;
    }
    .react-select__group {
      padding-bottom: 0;
      .react-select__group-heading {
        font-size: 12px;
      }
    }
    .react-select__no-options-message {
      border-bottom: 1px solid $white-highlight;
    }
    .custom-option.react-select__option {
      display: flex;
      align-items: center;
      cursor: pointer;

      &.action-dropdown-option {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      &:hover {
        color: $primary;
        background: $white;
      }
      &--is-focused {
        background: none;
      }
      &--is-selected {
        color: $primary;
        background: $white-highlight !important;
      }
      .image-wrapper {
        width: 30px !important;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
}

// Portaled select
.react-select__menu-portal {
  z-index: 100001 !important;
  .react-select__menu {
    margin-top: 0px;
    margin-bottom: 0px;
    box-shadow: $box-shadow !important;
  }
}
// General select on page
.react-select__container {
  .react-select__menu {
    margin-top: -1px;
    box-shadow: none !important;
    z-index: 3;
  }
}
