.component__grid .col-lg-4 {
    .contentbanner-disruptor {
            display: flex;
            flex-direction: column;
            height: fit-content!important;

            .contentbanner-image {
            z-index: 1;
            display: block;
            order: -1;
            padding-right: 0!important;
            padding-left: 0!important;

            }

            .contentbanner-content {
                clip-path: none!important;
                padding: 20px!important;
                width: 100%!important;
                padding: 20px;
                order: 1;
                align-items: center;
            }
        }
}

.contentbanner__slider {
    .swiper {
      padding-left: 10px;
      margin-left: -10px;
    }
    .slider__navigation {
      margin-top: 0 !important;
    }
    .swiper-slide {
      &:not(.swiper-slide-visible) {
        .contentbanner-eyecatcher {
          display: none;
        }
      }
    }
    &.slider.grid-slider {
        margin-right: -15px;
    }
  }

  .contentbanner__container {
    margin-bottom: 15px !important;

    @include media-breakpoint-down(sm) {
      max-width: none;
    }

    &.hidden {
      display: none;
    }
  }

  .contentbanner-disruptor {
    display: grid;
    overflow-y: clip;
    overflow-x: visible;
    grid-template-columns: 100%;
    box-shadow: $box-shadow;

    @include media-breakpoint-down(md) {
      display: flex;
      position: relative;
    }

    .contentbanner-child {
      grid-area: 1 / 1 / 2 / 2;
    }

    .contentbanner-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      color: $white;
      padding: 30px;
      width: 45%;
      position: relative;
      max-height: 1500px;

      .card {
        overflow-y: auto;
      }

      @include media-breakpoint-down(md) {
        clip-path: unset!important;
        }

        &.no-background {
          clip-path: unset !important;
        }

      &.content-right {
        @include media-breakpoint-down(md) {
          clip-path: unset;
        }
        align-items: flex-end;
        @include media-breakpoint-up(lg) {
          padding-left: calc(8% + 20px);
          padding-right: 30px;
        }

      }

      @include media-breakpoint-up(lg) {
        padding-right: 10%;
      }

      @include media-breakpoint-down(md) {
        width: 100% !important;
        clip-path: unset;
      }

      &.bg_blue {
          background: linear-gradient(98deg, $primary-highlight 8%, $primary 92%);
        li, li:before {
          color: $white !important;
        }
      }
      &.bg_white {

          background: linear-gradient(98deg, $white-highlight 8%, $white 92%);

        li, li:before {
          color: $tertiary !important;
        }
      }
      &.bg_green {
        background: linear-gradient(
            98deg,
            $green-light-highlight 8%,
            $green-light 92%
          );

          ul li, li:before {
          color: $white !important;
        }
      }
      &.bg_orange {

          background: linear-gradient(
            98deg,
            $secondary-highlight 8%,
            $secondary 92%
          );

          ul li, li:before {
          color: $white !important;
        }
      }
      &.blue {
        .card-title,
        .content, ul li, li:before {
          color: $tertiary !important;
        }
      }
      &.white {
        .card-title,
        .content, ul li, li:before {
          color: $white !important;
        }
      }

      .slider {
        width: 100%;
        .swiper {
          overflow: visible;
        }
        @include media-breakpoint-up(lg) {
          max-width: 500px;
        }
      }

      .card {
        background: none;
        .image-wrapper {
          max-height: 250px;
        }
        .card-title {
            &.no-title {
                margin: 0;
            }
        }
        .content {
            /* overflow-y: hidden; */
          > :last-child {
            margin-bottom: 0;
          }
        }
      }

      h4,
      .h4,
      h5,
      .h5 {
        margin-bottom: 10px;

        @include media-breakpoint-down(md) {
          font-size: 22px;
          line-height: 28px;
        }
      }

      p {
        margin-bottom: 15px;
      }

      small {
        font-size: 14px;
        color: white;
      }
    }

    .contentbanner-eyecatcher {
      width: auto;
      position: absolute;
      top: 10px;
      margin-left: -10px;
      z-index: 1;
    }

    .contentbanner-image {
      z-index: -1;
      width: 100%;

      .image-wrapper {
        min-height: 100%;

        img {
          object-fit: cover;
        }
      }
    }

    &.grid-disruptor {
        display: flex;
        flex-direction: column;
        height: fit-content!important;

        .grid-image {
        z-index: 1;
        display: block;
        order: -1;
        padding-right: 0!important;
        padding-left: 0!important;

        }

        .grid-content {
            clip-path: none!important;
            padding: 20px!important;
            width: 100%!important;
            padding: 20px;
            order: 1;
            align-items: center;
        }
    }

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        height: auto!important;

        .contentbanner-image {
            width: 100%!important;
            display: block;
            order: -1;
            padding-right: 0!important;
            padding-left: 0!important;
        }

        .contentbanner-content {
            width: 100%!important;
            padding: 20px!important;
            order: 1;
            align-items: center;
        }
    }
  }
