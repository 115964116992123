$alignList: left, center, right;
.text-component {
  @for $column from 1 through 3 {
    @each $align in $alignList {
      &-#{$column}-#{$align} {
        column-count: $column;
        text-align: $align;
      }
    }
  }
}

.component__text {
  .image-wrapper {
    margin: 20px auto;
  }
}

.info-body {
  .component__text,
  .component__image-text {
    .info-header {
      margin-bottom: 0px !important;
    }
  }
}

.text-blockquote {
  @extend .h5;
  border-left: 4px solid $primary;
  padding-left: 15px;
  margin-left: 10px;
  font-style: italic;
}
