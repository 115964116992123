.pss-page {
    .las-overview {
      position: relative;
      overflow: hidden;
      display: flex;
      width: 100%;
      min-height: 550px;

      &__container {
        margin: 0;
        width: 100%;
      }

      &__content-wrapper {
          /* .image-title-wrapper {
              .ff-fix {
                  width: 40px!important;
              }
          } */
      }

      &__content {
        display: flex;
        flex: 1;
        min-width: 50vw;
        padding: 45px 45px 0;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(to left, #f5f6f7, $gray-10);

        @include media-breakpoint-down(md) {
          clip-path: unset!important;
          align-items: flex-start;
          min-width: 100%;
          padding-left: 15px !important;
          padding-right: 15px !important;
          padding-bottom: 30px;
      }

        .content-wrapper {
          max-width: 80%;
          padding-right: 80px;
        }
      }

      ul {
        @extend .bullet-point-list;
      }

      &__keyvisual {
        aspect-ratio: unset;
        display: flex;
        justify-content: center;
        flex: 1;
        max-height: calc(100vh - var(--header-height) - 55px);
        width: 100%;
        margin-left: -8%;

        .image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          div {
            aspect-ratio: 4 / 3;
            width: auto;
          }

          img {
            height: 100%;
            width: auto;
          }
        }

        .media-keyvisual {
          top: 5px;
          object-fit: cover;
        }
      }

      @include media-breakpoint-up(lg) {
        &__content {
          padding-top: 0;
        }
      }

      @include media-breakpoint-down(md) {
        max-height: unset;
        display: block;

        &__keyvisual {
          aspect-ratio: unset;
          width: 100%;
          position: static;
          margin-left: 0;

          .image-wrapper {
            max-height: 40vh;
            // TODO: delete next line when KRN-989 is live
            aspect-ratio: unset!important;

            div {
            aspect-ratio: unset!important;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        &__container {
          flex-direction: column-reverse;
          width: 100%;
        }

        .content-wrapper {
          max-width: 100%;
          padding-right: 0;
        }
      }
    }
  }

  .pss-page:not(.detail) {
    .las-overview {
      height: calc(100vh - var(--header-height) - 55px);

      &__keyvisual {
          aspect-ratio: 3/4;
      }

      &__container {
        margin: auto;
        flex: 1;
      }

      &__content {
        .subtitle {
          margin-bottom: 50px;
        }

        .choice-description {
          font-weight: bold;
        }
      }

      .card-wrapper {
        width: 95%;

        a {
          text-decoration: none;
        }
      }

      .card.horizontal-thin {
        text-decoration: none;
        margin-bottom: 24px;
        box-shadow: $box-shadow;
        max-height: unset;
        min-height: unset;
        align-items: center;
        padding: 15px 0;

        &:hover {
          .card-title {
            text-decoration: none;
          }
          .card-image img {
            transform: scale(1) !important;
          }
        }

        .card-image {
          min-width: 60px;
          max-width: 60px;

          img {
            transform: scale(0.95);
          }
        }

        .card-title {
          color: $tertiary;
        }

        .card-subtitle {
          color: $gray-100;
        }

        .card-footer {
          margin-top: unset;
          display: flex;
          justify-content: flex-end;
        }
      }

      @include media-breakpoint-down(xl) {
        .card.horizontal-thin {
          .card-image {
            min-width: 50px;
            max-width: 50px;
          }
        }
      }

      @include media-breakpoint-down(md) {
        height: 100%;

        &__content {
          min-height: calc((100vh - var(--header-height) - 55px) / 100 * 60);
        }

        &__keyvisual {
          aspect-ratio: unset;
      }

        .card-wrapper {
          width: 100%;
        }

        .card.horizontal-thin {
          margin-bottom: 12px;
        }
      }
    }
  }

  // Detail Page for Pr / pr Entities
  .pss-page.detail {
    .las-overview {
      video {
        margin-top: 0;
        object-fit:cover
      }

      &__content {
        z-index: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &-wrapper {
          max-width: 80%;
          @include media-breakpoint-down(md) {
            max-width: 100%;
            margin-bottom: 20px;
          }
        }
      }

      &__keyvisual {
        .media-keyvisual {
          height: 100%;
          position: relative;
          cursor: pointer;

          video {
            height: 101%;
          }
        }

        .media-play {
          position: absolute;
          bottom: 3vw;
          right: 5vw;
          padding: 9px;
          background-color: $white;
          box-shadow: $box-shadow;

          @include media-breakpoint-down(lg) {
            bottom: 3vw;
            right: 3vw;
          }

          @include media-breakpoint-down(md) {
            bottom: 9vw;
            right: 3vw;
          }
        }
      }

      .accessoires-wrapper {
        padding: 30px 0 15px;
        background-color: $gray-10;
      }
    }
  }
