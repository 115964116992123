.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.full-width {
  width: 100vw;
  margin-left: -50vw;
  left: 50%;
}

.absolute-center-vertical {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  word-wrap: normal !important;
}

$directions: left, right, top, bottom;
$spacings: (
  "small": 20px,
  "medium": 30px,
  "large": 50px,
);

@each $direction in $directions {
  .border-#{$direction} {
    border-#{$direction}: 1px solid $gray-40 !important;
  }
}

@each $direction in $directions {
  .border-#{$direction}-subtle {
    border-#{$direction}: 1px solid $light !important;

    &__blue {
      border-color: $blue !important;
    }
  }
}

@each $direction in $directions {
  .border-#{$direction}-skyblue {
    border-#{$direction}: 1px solid $white-highlight;
  }
}

@each $direction in $directions {
  @each $spacing, $size in $spacings {
    .padding-#{$direction}-#{$spacing} {
      padding-#{$direction}: $size;
    }
  }
}

@each $direction in $directions {
  @each $spacing, $size in $spacings {
    .margin-#{$direction}-#{$spacing} {
      margin-#{$direction}: $size;
    }
  }
}

.image-caption {
  font-size: 14px;
  color: $tertiary;
  line-height: 21px;
  padding: 0.5rem 0 0.75rem;
  place-self: center;
  width: fit-content;
  max-width: 100%;
  text-align: center;

  &:has(> ol) {
    place-self: start;
  }

  &.left {
    place-self: start;
  }

  &.right {
    place-self: end;
  }

  @include media-breakpoint-down(lg) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.product-img {
    .static-image {
        .image-caption {
            transform: translate(0%, -100%);

            @include media-breakpoint-down(lg) {
                transform: translate(0%, -25%);
              }
          }
    }
}

div[class*="background-gradient"] {
  padding: 5px 13px 5px 10px;
  color: $white;
  clip-path: polygon(0 0, 100% 0%, 95% 100%, 0 100%);
  width: min-content;
  display: table;
}

.background-gradient-primary {
  $list: $primary, $primary-highlight;
  @include gradient(to left, $list);
}
.background-gradient-secondary {
  $list: $secondary, $secondary-highlight;
  @include gradient(to left, $list);
}
.background-gradient-tertiary {
  $list: $tertiary, $tertiary-highlight;
  @include gradient(to left, $list);
}

.hover__zoom:hover {
  cursor: zoom-in;
}

.italic {
  font-style: italic;
}

.bg-gray {
  background: $gray-10;
}
.bg-green {
    background-image: linear-gradient(
        to left,
        $green-light,
        $green-light-highlight
    );
}
.bg-blue {
    background-image: linear-gradient(
        to left,
        $primary,
        $primary-highlight
    );
}
.text-col-2 {
  @include media-breakpoint-up(lg) {
    column-count: 2;
    column-gap: 32px;
  }
}

.flex-col {
  flex-direction: column;
}
.gap-x-1 {
  column-gap: 0.25rem;
}
.gap-x-2 {
  column-gap: 0.5rem;
}
.gap-x-4 {
  column-gap: 1rem;
}
.gap-y-1 {
  row-gap: 0.25rem;
}
.gap-y-3 {
  row-gap: 0.75rem;
}
.grow {
  flex-grow: 1;
}
.h-0 {
  height: 0px;
}
.h-fit {
  height: fit-content;
}
.h-full {
  height: 100%;
}
.relative {
  position: relative;
}
.w-0 {
  width: 0px;
}
.w-full {
  width: 100%;
}
.text-white {
  color: white;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.h-200 {
    height: 200px;
}
