.glance-container {
  display: flex;

  .image-wrapper {
    flex-shrink: 1 !important;
  }
}

.service-container {
  display: flex;
  justify-content: space-between;

  .service-sidelist {
    @include media-breakpoint-down(md) {
      max-width: 100%;
      padding: 15px !important;
    }

    .service-sidelist-panel {
      padding: 15px;

      @include media-breakpoint-up(md) {
        padding: 30px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .image-wrapper {
    flex-shrink: 1 !important;

    @include media-breakpoint-up(sm) {
      float: right;
      margin-bottom: 10px;
      margin-left: 10px;
      width: 50% !important;
    }
  }
}

.service-options {
  .accordion__block__content {
    @include media-breakpoint-down(md) {
      padding: 15px 0px !important;
    }
  }
}

.container__section.section--service {
  padding-top: 8rem;
  padding-bottom: 8rem;
  margin-bottom: 8rem;

  .btn--container-bottom {
    position: absolute;
    bottom: -7.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
