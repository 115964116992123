@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes formio-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes formio-dialog-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes formio-dialog-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes formio-dialog-flyin {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes formio-dialog-flyout {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow:
    1px 0 0 #e6e6e6,
    -1px 0 0 #e6e6e6,
    0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6,
    0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow:
    1px 0 0 #e6e6e6,
    -1px 0 0 #e6e6e6,
    0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6,
    0 3px 13px rgba(0, 0, 0, 0.08);
  .hasWeeks {
    .dayContainer {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }
  }
  .hasTime {
    .dayContainer {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &:before {
    position: absolute;
    display: block;
    pointer-events: none;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    left: 22px;
    border-width: 5px;
    margin: 0 -5px;
  }
  &:after {
    position: absolute;
    display: block;
    pointer-events: none;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    left: 22px;
    border-width: 4px;
    margin: 0 -4px;
  }
  &:focus {
    outline: 0;
  }
}
.flatpickr-calendar.open {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
  display: inline-block;
  z-index: 999990;
}
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth {
  .flatpickr-days {
    .dayContainer {
      &:nth-child(n + 1) {
        .flatpickr-day.inRange {
          &:nth-child(7n + 7) {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
          }
        }
      }
      &:nth-child(n + 2) {
        .flatpickr-day.inRange {
          &:nth-child(7n + 1) {
            -webkit-box-shadow:
              -2px 0 0 #e6e6e6,
              5px 0 0 #e6e6e6;
            box-shadow:
              -2px 0 0 #e6e6e6,
              5px 0 0 #e6e6e6;
          }
        }
      }
    }
  }
}
.flatpickr-calendar.hasTime {
  .flatpickr-time {
    height: 40px;
    border-top: 1px solid #e6e6e6;
  }
}
.flatpickr-calendar.noCalendar.hasTime {
  .flatpickr-time {
    height: auto;
  }
}
.flatpickr-calendar.rightMost {
  &:before {
    left: auto;
    right: 22px;
  }
  &:after {
    left: auto;
    right: 22px;
  }
}
.flatpickr-calendar.arrowRight {
  &:before {
    left: auto;
    right: 22px;
  }
  &:after {
    left: auto;
    right: 22px;
  }
}
.flatpickr-calendar.arrowCenter {
  &:before {
    left: 50%;
    right: 50%;
  }
  &:after {
    left: 50%;
    right: 50%;
  }
}
.flatpickr-calendar.arrowTop {
  &:before {
    bottom: 100%;
    border-bottom-color: #e6e6e6;
  }
  &:after {
    bottom: 100%;
    border-bottom-color: #fff;
  }
}
.flatpickr-calendar.arrowBottom {
  &:before {
    top: 100%;
    border-top-color: #e6e6e6;
  }
  &:after {
    top: 100%;
    border-top-color: #fff;
  }
}
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}
.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  .flatpickr-month {
    background: transparent;
    color: rgba(0, 0, 0, 0.9);
    fill: rgba(0, 0, 0, 0.9);
    height: 34px;
    line-height: 1;
    text-align: center;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .flatpickr-prev-month {
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 34px;
    padding: 10px;
    z-index: 3;
    color: rgba(0, 0, 0, 0.9);
    fill: rgba(0, 0, 0, 0.9);
    i {
      position: relative;
    }
    &:hover {
      color: #959ea9;
      svg {
        fill: #f64747;
      }
    }
    svg {
      width: 14px;
      height: 14px;
      path {
        -webkit-transition: fill 0.1s;
        transition: fill 0.1s;
        fill: inherit;
      }
    }
  }
  .flatpickr-next-month {
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 34px;
    padding: 10px;
    z-index: 3;
    color: rgba(0, 0, 0, 0.9);
    fill: rgba(0, 0, 0, 0.9);
    i {
      position: relative;
    }
    &:hover {
      color: #959ea9;
      svg {
        fill: #f64747;
      }
    }
    svg {
      width: 14px;
      height: 14px;
      path {
        -webkit-transition: fill 0.1s;
        transition: fill 0.1s;
        fill: inherit;
      }
    }
  }
  .flatpickr-prev-month.flatpickr-disabled {
    display: none;
  }
  .flatpickr-next-month.flatpickr-disabled {
    display: none;
  }
  .flatpickr-prev-month.flatpickr-prev-month {
    left: 0;
  }
  .flatpickr-next-month.flatpickr-prev-month {
    left: 0;
  }
  .flatpickr-prev-month.flatpickr-next-month {
    right: 0;
  }
  .flatpickr-next-month.flatpickr-next-month {
    right: 0;
  }
}
.numInputWrapper {
  position: relative;
  height: auto;
  input {
    display: inline-block;
    width: 100%;
    &::-ms-clear {
      display: none;
    }
    &::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }
  span {
    display: inline-block;
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    border: 1px solid rgba(57, 57, 57, 0.15);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    &:hover {
      background: $box-shadow-color;
    }
    &:active {
      background: rgba(0, 0, 0, 0.2);
    }
    &:after {
      display: block;
      content: "";
      position: absolute;
    }
    svg {
      width: inherit;
      height: auto;
      path {
        fill: rgba(0, 0, 0, 0.5);
      }
    }
  }
  span.arrowUp {
    top: 0;
    border-bottom: 0;
    &:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid rgba(57, 57, 57, 0.6);
      top: 26%;
    }
  }
  span.arrowDown {
    top: 50%;
    &:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid rgba(57, 57, 57, 0.6);
      top: 40%;
    }
  }
  &:hover {
    background: rgba(0, 0, 0, 0.05);
    span {
      opacity: 1;
    }
  }
}
.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  span.cur-month {
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    display: inline-block;
    margin-left: 0.5ch;
    padding: 0;
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
  .numInputWrapper {
    width: 6ch;
    width: 7ch\0;
    display: inline-block;
    span.arrowUp {
      &:after {
        border-bottom-color: rgba(0, 0, 0, 0.9);
      }
    }
    span.arrowDown {
      &:after {
        border-top-color: rgba(0, 0, 0, 0.9);
      }
    }
  }
  input.cur-year {
    background: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    cursor: text;
    padding: 0 0 0 0.5ch;
    margin: 0;
    display: inline-block;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    line-height: inherit;
    height: auto;
    border: 0;
    border-radius: 0;
    vertical-align: initial;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    &:focus {
      outline: 0;
    }
  }
  input.cur-year[disabled] {
    font-size: 100%;
    color: rgba(0, 0, 0, 0.5);
    background: transparent;
    pointer-events: none;
    &:hover {
      font-size: 100%;
      color: rgba(0, 0, 0, 0.5);
      background: transparent;
      pointer-events: none;
    }
  }
  .flatpickr-monthDropdown-months {
    appearance: menulist;
    background: transparent;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    height: auto;
    line-height: inherit;
    margin: -1px 0 0 0;
    outline: none;
    padding: 0 0 0 0.5ch;
    position: relative;
    vertical-align: initial;
    -webkit-box-sizing: border-box;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    width: auto;
    &:focus {
      outline: none;
    }
    &:active {
      outline: none;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
    .flatpickr-monthDropdown-month {
      background-color: transparent;
      outline: none;
      padding: 0;
    }
  }
}
.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
  .flatpickr-weekdaycontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}
.dayContainer {
  padding: 1px 0 0 0;
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  + {
    .dayContainer {
      -webkit-box-shadow: -1px 0 0 #e6e6e6;
      box-shadow: -1px 0 0 #e6e6e6;
    }
  }
}
.flatpickr-weeks {
  padding: 1px 0 0 0;
}
.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
  &:focus {
    outline: 0;
  }
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  &:hover {
    cursor: pointer;
    outline: 0;
    background: #e6e6e6;
    border-color: #e6e6e6;
  }
  &:focus {
    cursor: pointer;
    outline: 0;
    background: #e6e6e6;
    border-color: #e6e6e6;
  }
}
.flatpickr-day.inRange {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
  border-radius: 0;
  -webkit-box-shadow:
    -5px 0 0 #e6e6e6,
    5px 0 0 #e6e6e6;
  box-shadow:
    -5px 0 0 #e6e6e6,
    5px 0 0 #e6e6e6;
}
.flatpickr-day.prevMonthDay.inRange {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.flatpickr-day.nextMonthDay.inRange {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.flatpickr-day.today.inRange {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.flatpickr-day.prevMonthDay.today.inRange {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.flatpickr-day.nextMonthDay.today.inRange {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.flatpickr-day.prevMonthDay {
  &:hover {
    cursor: pointer;
    outline: 0;
    background: #e6e6e6;
    border-color: #e6e6e6;
  }
  &:focus {
    cursor: pointer;
    outline: 0;
    background: #e6e6e6;
    border-color: #e6e6e6;
  }
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.nextMonthDay {
  &:hover {
    cursor: pointer;
    outline: 0;
    background: #e6e6e6;
    border-color: #e6e6e6;
  }
  &:focus {
    cursor: pointer;
    outline: 0;
    background: #e6e6e6;
    border-color: #e6e6e6;
  }
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.today {
  border-color: #959ea9;
  &:hover {
    border-color: #959ea9;
    background: #959ea9;
    color: #fff;
  }
  &:focus {
    border-color: #959ea9;
    background: #959ea9;
    color: #fff;
  }
}
.flatpickr-day.selected {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
  &:focus {
    background: #569ff7;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
  }
  &:hover {
    background: #569ff7;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
  }
}
.flatpickr-day.startRange {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
  &:focus {
    background: #569ff7;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
  }
  &:hover {
    background: #569ff7;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
  }
}
.flatpickr-day.endRange {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
  &:focus {
    background: #569ff7;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
  }
  &:hover {
    background: #569ff7;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
  }
}
.flatpickr-day.selected.inRange {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.startRange.inRange {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.endRange.inRange {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.selected.prevMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.startRange.prevMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.endRange.prevMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.selected.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.startRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.selected.startRange {
  border-radius: 50px 0 0 50px;
  + {
    .endRange {
      &:not(:nth-child(7n + 1)) {
        -webkit-box-shadow: -10px 0 0 #569ff7;
        box-shadow: -10px 0 0 #569ff7;
      }
    }
  }
}
.flatpickr-day.startRange.startRange {
  border-radius: 50px 0 0 50px;
  + {
    .endRange {
      &:not(:nth-child(7n + 1)) {
        -webkit-box-shadow: -10px 0 0 #569ff7;
        box-shadow: -10px 0 0 #569ff7;
      }
    }
  }
}
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
  + {
    .endRange {
      &:not(:nth-child(7n + 1)) {
        -webkit-box-shadow: -10px 0 0 #569ff7;
        box-shadow: -10px 0 0 #569ff7;
      }
    }
  }
}
.flatpickr-day.selected.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.startRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.selected.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.startRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.flatpickr-disabled {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
  &:hover {
    color: rgba(57, 57, 57, 0.3);
    background: transparent;
    border-color: transparent;
    cursor: default;
    cursor: not-allowed;
    color: rgba(57, 57, 57, 0.1);
  }
}
.flatpickr-day.notAllowed {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.notAllowed.prevMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow:
    -5px 0 0 #569ff7,
    5px 0 0 #569ff7;
  box-shadow:
    -5px 0 0 #569ff7,
    5px 0 0 #569ff7;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode {
  .flatpickr-day {
    margin-top: 1px;
  }
}
.flatpickr-weekwrapper {
  float: left;
  .flatpickr-weeks {
    padding: 0 12px;
    -webkit-box-shadow: 1px 0 0 #e6e6e6;
    box-shadow: 1px 0 0 #e6e6e6;
  }
  .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px;
  }
  span.flatpickr-day {
    display: block;
    width: 100%;
    max-width: none;
    color: rgba(57, 57, 57, 0.3);
    background: transparent;
    cursor: default;
    border: none;
    &:hover {
      display: block;
      width: 100%;
      max-width: none;
      color: rgba(57, 57, 57, 0.3);
      background: transparent;
      cursor: default;
      border: none;
    }
  }
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .numInputWrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 40%;
    height: 40px;
    float: left;
    span.arrowUp {
      &:after {
        border-bottom-color: #393939;
      }
    }
    span.arrowDown {
      &:after {
        border-top-color: #393939;
      }
    }
  }
  input {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    color: #393939;
    font-size: 14px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    &:focus {
      outline: 0;
      border: 0;
      background: #eee;
    }
    &:hover {
      background: #eee;
    }
  }
  input.flatpickr-hour {
    font-weight: bold;
  }
  input.flatpickr-minute {
    font-weight: 400;
  }
  input.flatpickr-second {
    font-weight: 400;
  }
  .flatpickr-time-separator {
    height: inherit;
    float: left;
    line-height: inherit;
    color: #393939;
    font-weight: bold;
    width: 2%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .flatpickr-am-pm {
    height: inherit;
    float: left;
    line-height: inherit;
    color: #393939;
    font-weight: bold;
    width: 2%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    &:hover {
      background: #eee;
    }
    &:focus {
      background: #eee;
    }
  }
}
.flatpickr-time.hasSeconds {
  .numInputWrapper {
    width: 26%;
  }
}
.flatpickr-time.time24hr {
  .numInputWrapper {
    width: 49%;
  }
}
.flatpickr-input[readonly] {
  cursor: pointer;
}
.choices {
  position: relative;
  margin-bottom: 24px;
  font-size: 16px;
  &:focus {
    outline: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.choices.is-disabled {
  .choices__inner {
    background-color: #eaeaea;
    cursor: not-allowed;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .choices__input {
    background-color: #eaeaea;
    cursor: not-allowed;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .choices__item {
    cursor: not-allowed;
  }
}
.choices[data-type*="select-one"] {
  cursor: pointer;
  .choices__inner {
    padding-bottom: 7.5px;
  }
  .choices__input {
    display: block;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid $gray-30;
    background-color: #fff;
    margin: 0;
  }
  .choices__button {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
    padding: 0;
    background-size: 8px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
    margin-right: 25px;
    height: 20px;
    width: 20px;
    border-radius: 10em;
    opacity: 0.5;
    &:focus {
      opacity: 1;
      box-shadow: 0 0 0 2px #00bcd4;
    }
    &:hover {
      opacity: 1;
    }
  }
  &:after {
    content: "";
    height: 0;
    width: 0;
    border-style: solid;
    border-color: #333 transparent transparent;
    border-width: 5px;
    position: absolute;
    right: 11.5px;
    top: 50%;
    margin-top: -2.5px;
    pointer-events: none;
  }
  .choices__input.is-hidden {
    display: none;
  }
}
.choices[data-type*="select-one"].is-open {
  &:after {
    border-color: transparent transparent #333;
    margin-top: -7.5px;
  }
}
.choices[data-type*="select-one"][dir="rtl"] {
  &:after {
    left: 11.5px;
    right: auto;
  }
  .choices__button {
    right: auto;
    left: 0;
    margin-left: 25px;
    margin-right: 0;
  }
}
.choices[data-type*="select-multiple"] {
  .choices__inner {
    cursor: text;
  }
  .choices__button {
    position: relative;
    display: inline-block;
    margin: 0 -4px 0 8px;
    padding-left: 16px;
    border-left: 1px solid #008fa1;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
    background-size: 8px;
    width: 8px;
    line-height: 1;
    opacity: 0.75;
    border-radius: 0;
    &:focus {
      opacity: 1;
    }
    &:hover {
      opacity: 1;
    }
  }
  .choices__input.is-hidden {
    display: none;
  }
}
.choices[data-type*="text"] {
  .choices__inner {
    cursor: text;
  }
  .choices__button {
    position: relative;
    display: inline-block;
    margin: 0 -4px 0 8px;
    padding-left: 16px;
    border-left: 1px solid #008fa1;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
    background-size: 8px;
    width: 8px;
    line-height: 1;
    opacity: 0.75;
    border-radius: 0;
    &:focus {
      opacity: 1;
    }
    &:hover {
      opacity: 1;
    }
  }
}
.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid $gray-30;
  border-radius: 2.5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden;
}
.is-focused {
  .choices__inner {
    border-color: #b7b7b7;
  }
}
.is-open {
  .choices__inner {
    border-color: #b7b7b7;
    border-radius: 2.5px 2.5px 0 0;
  }
  .choices__list--dropdown {
    border-color: #b7b7b7;
  }
}
.is-flipped.is-open {
  .choices__inner {
    border-radius: 0 0 2.5px 2.5px;
  }
}
.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
  padding: 0;
  .choices__item {
    width: 100%;
  }
}
[dir="rtl"] {
  .choices__list--single {
    padding-right: 4px;
    padding-left: 16px;
  }
  .choices__list--multiple {
    .choices__item {
      margin-right: 0;
      margin-left: 3.75px;
    }
  }
  .choices__list--dropdown {
    .choices__item {
      text-align: right;
    }
  }
  .choices__input {
    padding-right: 2px;
    padding-left: 0;
  }
}
.choices__list--multiple {
  display: inline;
  .choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 20px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 700;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #00bcd4;
    border: 1px solid #00a5bb;
    color: #fff;
    word-break: break-all;
    border-radius: 0;
    padding: 2px 8px;
    line-height: 1em;
    margin-bottom: 6px;
  }
  .choices__item[data-deletable] {
    padding-right: 5px;
  }
  .choices__item.is-highlighted {
    background-color: #00a5bb;
    border: 1px solid #008fa1;
  }
}
.is-disabled {
  .choices__list--multiple {
    .choices__item {
      background-color: #aaa;
      border: 1px solid #919191;
      padding: 5px 10px;
      .choices__button {
        display: none;
      }
    }
  }
}
.choices__list--dropdown {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid $gray-30;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: auto;
  word-break: break-all;
  z-index: 100;
  .choices__list {
    position: relative;
    max-height: 300px;
    margin-bottom: 41px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
  }
  .choices__item {
    position: relative;
    padding: 10px;
    font-size: 14px;
  }
  .choices__item--selectable.is-highlighted {
    background-color: #f2f2f2;
    &:after {
      opacity: 0.5;
    }
  }
  .choices__item--selectable {
    padding-right: 0px;
  }
}
.choices__list--dropdown.is-active {
  display: block;
}
.is-flipped {
  .choices__list--dropdown {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
    border-radius: 0.25rem 0.25rem 0 0;
  }
}
.choices__item {
  cursor: default;
}
.choices__item--selectable {
  cursor: pointer;
}
.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.5;
}
.choices__heading {
  font-weight: 700;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray;
}
.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}
.choices__input {
  &:focus {
    outline: 0;
  }
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
  padding: 2px;
}
.choices__placeholder {
  opacity: 0.5;
}
.choices__input.is-hidden {
  display: none;
}
dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: block;
  &:not([open]) {
    display: none;
  }
  + {
    .backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $box-shadow-color;
    }
  }
}
._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable {
  &:active {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}
.formio-form {
  position: relative;
  min-height: 80px;
}
.formio-disabled-input {
  .form-control.flatpickr-input {
    background-color: #eee;
  }
  .formio-tagpad-canvas {
    cursor: not-allowed;
  }
  .formio-tagpad-image-container {
    background-color: #eee;
  }
}
.formio-component.has-error {
  .invalid-feedback {
    display: block;
  }
}
.formio-wysiwyg-editor {
  min-height: 200px;
  background-color: #fff;
}
.has-feedback {
  .form-control {
    padding-right: 10px;
  }
  .form-control[type="hidden"] {
    padding-right: 0px;
  }
}
.has-error.bg-danger {
  padding: 4px;
}
.ql-source {
  &:after {
    content: "[source]";
    white-space: nowrap;
  }
}
.quill-source-code {
  width: 100%;
  margin: 0px;
  background: #1d1d1d;
  box-sizing: border-box;
  color: #cccccc;
  font-size: 15px;
  outline: none;
  padding: 20px;
  line-height: 24px;
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  position: absolute;
  top: 0;
  bottom: 0;
  border: none;
  display: none;
}
.formio-disabled-input.formio-component-textarea {
  > .well {
    white-space: pre-wrap;
    * {
      white-space: initial;
    }
  }
  .well {
    overflow-wrap: break-word;
  }
}
.formio-component-tags {
  tags {
    background-color: #fff;
  }
}
.field-required {
  &:after {
    content: " *";
    color: red;
  }
}
.glyphicon-spin {
  -webkit-animation: formio-spin 1s infinite linear;
  -moz-animation: formio-spin 1s infinite linear;
  -o-animation: formio-spin 1s infinite linear;
  animation: formio-spin 1s infinite linear;
}
.button-icon-right {
  margin-left: 5px;
}
.formio-component-submit {
  .submit-success {
    &::after {
      font-family: "Glyphicons Halflings";
      content: "\e013";
      position: relative;
      right: -4px;
      top: 1;
      line-height: 1;
    }
  }
  .submit-fail {
    &::after {
      font-family: "Glyphicons Halflings";
      content: "\e014";
      position: relative;
      right: -4px;
      top: 1;
      line-height: 1;
    }
  }
  .submit-fail[disabled] {
    opacity: 1;
  }
  button.uploads-in-progress {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .has-error {
    display: none;
  }
  button[disabled] {
    + {
      .has-error {
        display: block;
      }
    }
  }
}
.form-control.flatpickr-input {
  background-color: #fff;
}
td {
  > .form-group {
    margin-bottom: 0;
  }
  .formio-component-modaledit {
    .formio-modaledit-view-container {
      padding: 0;
      border-style: none;
    }
  }
}
.signature-pad {
  position: relative;
}
.signature-pad-body {
  overflow: hidden;
  img {
    width: unset !important;
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.signature-pad-canvas {
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
  border: 1px solid #f4f4f4;
}
.btn.signature-pad-refresh {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  padding: 3px;
  line-height: 0;
}
[dir="rtl"] {
  .btn.signature-pad-refresh {
    left: unset;
    right: 0;
  }
  .formio-component-file {
    .fileSelector {
      .fa {
        margin-right: unset;
        margin-left: 5px;
      }
      .glyphicon {
        margin-right: unset;
        margin-left: 5px;
      }
    }
  }
  .formio-collapse-icon {
    margin-right: unset;
    margin-left: 4px;
  }
}
.signature-pad-refresh {
  img {
    height: 1.2em;
  }
}
.signature-pad-footer {
  text-align: center;
  color: #c3c3c3;
}
.loader-wrapper {
  z-index: 1000;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: $box-shadow-color;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px;
  z-index: 10000;
  display: inline-block;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
.choices[dir="rtl"] {
  > * {
    text-align: right;
  }
  .choices__list--multiple {
    .choices__item[data-deletable] {
      padding-left: 5px;
      float: right;
      .choices__button {
        float: left;
        margin: 0 8px 0 -4px;
        padding-left: unset;
        padding-right: 16px;
        border-left: unset;
        border-right: 1px solid #008fa1;
      }
    }
  }
}
.formio-component-file {
  .fileSelector {
    padding: 15px;
    border: 2px dashed $gray-30;
    text-align: center;
    .loader-wrapper {
      display: none;
    }
    .glyphicon {
      font-size: 20px;
      margin-right: 5px;
    }
    .fa {
      font-size: 20px;
      margin-right: 5px;
    }
    .browse {
      cursor: pointer;
    }
  }
  .fileSelector.fileDragOver {
    border-color: #127abe;
  }
}
.formio-dialog {
  box-sizing: border-box;
  font-size: 0.8em;
  color: #666;
  position: fixed;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  animation: formio-dialog-fadein 0.5s;
  * {
    box-sizing: inherit;
    &:before {
      box-sizing: inherit;
    }
    &:after {
      box-sizing: inherit;
    }
  }
  .formio-dialog-close {
    cursor: pointer;
  }
}
.formio-dialog.formio-modaledit-dialog {
  font-size: inherit;
}
.formio-dialog.formio-dialog-disabled-animation {
  -webkit-animation: none !important;
  animation: none !important;
  .formio-dialog-overlay {
    -webkit-animation: none !important;
    animation: none !important;
  }
  .formio-dialog-content {
    -webkit-animation: none !important;
    animation: none !important;
  }
}
.formio-dialog-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  -webkit-animation: formio-dialog-fadein 0.5s;
  animation: formio-dialog-fadein 0.5s;
  margin-right: 15px;
  background: transparent;
}
.formio-dialog-no-overlay {
  pointer-events: none;
}
.formio-dialog.formio-dialog-closing {
  .formio-dialog-overlay {
    -webkit-backface-visibility: hidden;
    -webkit-animation: formio-dialog-fadeout 0.5s;
    animation: formio-dialog-fadeout 0.5s;
  }
  .formio-dialog-content {
    -webkit-backface-visibility: hidden;
    -webkit-animation: formio-dialog-fadeout 0.5s;
    animation: formio-dialog-fadeout 0.5s;
  }
}
.formio-dialog-content {
  background: white;
  -webkit-backface-visibility: hidden;
  -webkit-animation: formio-dialog-fadein 0.5s;
  animation: formio-dialog-fadein 0.5s;
  pointer-events: all;
  overflow: auto;
  .panel {
    margin: 0;
  }
}
.formio-dialog-close {
  &:before {
    font-family: "Helvetica", Arial, sans-serif;
    content: "\D7";
    cursor: pointer;
  }
}
html.formio-dialog-open {
  overflow: hidden;
}
body.formio-dialog-open {
  overflow: hidden;
}
.formio-dialog.formio-dialog-theme-default {
  padding-bottom: 160px;
  padding-top: 160px;
  .formio-dialog-content {
    -webkit-animation: formio-dialog-flyin 0.5s;
    animation: formio-dialog-flyin 0.5s;
    background: #f0f0f0;
    border-radius: 5px;
    font-family: "Helvetica", sans-serif;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 0 auto;
    max-width: 100%;
    padding: 1em;
    position: relative;
    width: 65%;
  }
  .formio-dialog-close {
    border: none;
    background: transparent;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    &:before {
      display: block;
      padding: 3px;
      background: transparent;
      color: #bbb;
      content: "\D7";
      font-size: 26px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
    }
    &:hover {
      &:before {
        color: #777;
      }
    }
    &:active {
      &:before {
        color: #777;
      }
    }
  }
  .formio-dialog-message {
    margin-bottom: 0.5em;
  }
  .formio-dialog-input {
    margin-bottom: 1em;
    textarea {
      background: #fff;
      border: 0;
      border-radius: 3px;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      margin: 0 0 0.25em;
      min-height: 2.5em;
      padding: 0.25em 0.67em;
      width: 100%;
      &:focus {
        box-shadow: inset 0 0 0 2px #8dbdf1;
        outline: none;
      }
    }
    input[type="text"] {
      background: #fff;
      border: 0;
      border-radius: 3px;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      margin: 0 0 0.25em;
      min-height: 2.5em;
      padding: 0.25em 0.67em;
      width: 100%;
      &:focus {
        box-shadow: inset 0 0 0 2px #8dbdf1;
        outline: none;
      }
    }
    input[type="password"] {
      background: #fff;
      border: 0;
      border-radius: 3px;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      margin: 0 0 0.25em;
      min-height: 2.5em;
      padding: 0.25em 0.67em;
      width: 100%;
      &:focus {
        box-shadow: inset 0 0 0 2px #8dbdf1;
        outline: none;
      }
    }
    input[type="email"] {
      background: #fff;
      border: 0;
      border-radius: 3px;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      margin: 0 0 0.25em;
      min-height: 2.5em;
      padding: 0.25em 0.67em;
      width: 100%;
      &:focus {
        box-shadow: inset 0 0 0 2px #8dbdf1;
        outline: none;
      }
    }
    input[type="url"] {
      background: #fff;
      border: 0;
      border-radius: 3px;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      margin: 0 0 0.25em;
      min-height: 2.5em;
      padding: 0.25em 0.67em;
      width: 100%;
      &:focus {
        box-shadow: inset 0 0 0 2px #8dbdf1;
        outline: none;
      }
    }
  }
  .formio-dialog-buttons {
    *zoom: 1;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  .formio-dialog-button {
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    float: right;
    font-family: inherit;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    line-height: 1em;
    margin: 0 0 0 0.5em;
    padding: 0.75em 2em;
    text-transform: uppercase;
    &:focus {
      -webkit-animation: formio-dialog-pulse 1.1s infinite;
      animation: formio-dialog-pulse 1.1s infinite;
      outline: none;
    }
  }
  .formio-dialog-button.formio-dialog-button-primary {
    background: #3288e6;
    color: #fff;
  }
  .formio-dialog-button.formio-dialog-button-secondary {
    background: #e0e0e0;
    color: #777;
  }
}
.formio-dialog.formio-dialog-theme-default.formio-dialog-closing {
  .formio-dialog-content {
    -webkit-animation: formio-dialog-flyout 0.5s;
    animation: formio-dialog-flyout 0.5s;
  }
}
.formio-clickable {
  cursor: pointer;
}
.component-settings {
  .nav {
    > li {
      > a {
        padding: 8px 10px;
      }
    }
  }
}
.formio-placeholder {
  position: absolute;
  color: #999;
}
.formio-iframe {
  border: none;
  width: 100%;
  height: 1000px;
}
.inline-form-button {
  margin-right: 10px;
}
.tooltip {
  opacity: 1;
}
.tooltip[x-placement="right"] {
  .tooltip-arrow {
    border-right: 5px solid black;
  }
  .tooltip-inner {
    margin-left: 8px;
  }
}
.control-label--bottom {
  margin-bottom: 0;
  margin-top: 5px;
}
.formio-component-label-hidden {
  position: relative;
}
.control-label--hidden {
  position: absolute;
  top: 6px;
  right: 5px;
  font-size: 1.5em;
}
.formio-component-datetime {
  .control-label--hidden.field-required {
    right: 45px;
    z-index: 3;
  }
  .form-control[type="datetime-local"] {
    ~ {
      .input-group-addon {
        width: auto;
      }
    }
  }
}
.formio-component-survey {
  .control-label--hidden.field-required {
    top: 0;
  }
}
.formio-component-selectboxes {
  .control-label--hidden.field-required {
    top: 0;
  }
}
.formio-component-resource {
  .control-label--hidden.field-required {
    right: 40px;
    z-index: 2;
  }
}
.formio-component-select {
  .control-label--hidden.field-required {
    right: 40px;
    z-index: 2;
  }
  select[disabled="disabled"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
  div[disabled="disabled"] {
    button {
      display: none;
    }
  }
  .choices.is-disabled[data-type*="select-one"] {
    &:after {
      display: none;
    }
  }
}
.checkbox-inline {
  label {
    font-weight: 400;
    cursor: pointer;
  }
}
.radio-inline {
  label {
    font-weight: 400;
    cursor: pointer;
  }
}
.editgrid-listgroup {
  margin-bottom: 10px;
}
.tree-listgroup {
  flex-direction: row;
}
.formio-choices.form-group {
  margin-bottom: 0;
}
.formio-choices[data-type="select-multiple"] {
  .form-control {
    height: auto;
  }
}
.form-control.formio-multiple-mask-select {
  width: 15%;
  z-index: 4;
}
.form-control.formio-multiple-mask-input {
  width: 85%;
}
.input-group.formio-multiple-mask-container {
  width: 100%;
}
.formio-component {
  .table {
    margin-bottom: 0;
  }
  &:not(:hover) {
    .component-btn-group {
      display: none;
    }
  }
}
.formio-hide-label-panel-tooltip {
  margin-top: -10px;
  margin-left: -10px;
}
.has-error.alert-danger {
  .form-group {
    color: #333;
  }
}
.formio-collapse-icon {
  cursor: pointer;
  color: #666;
  font-size: 0.6em;
  border: 1px solid #ccc;
  border-radius: 0.3em;
  padding: 0.2em 0.4em;
  vertical-align: top;
  margin-right: 4px;
  margin-top: 1px;
}
.formio-component-dateTime {
  .form-control[type="datetime-local"] {
    ~ {
      .input-group-addon {
        width: auto;
      }
    }
  }
}
.formio-component-datagrid {
  .formio-datagrid-remove {
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition:
      opacity 200ms linear,
      visibility 0ms 200ms;
  }
  .datagrid-table {
    > tbody {
      > tr {
        > td {
          &:last-child {
            position: relative;
          }
        }
        &:hover {
          > td {
            &:last-child {
              .formio-datagrid-remove {
                visibility: visible;
                opacity: 1;
                transition:
                  visibility 0ms,
                  opacity 200ms linear;
              }
            }
          }
        }
      }
    }
  }
}
.formio-component-modaledit {
  .formio-modaledit-view-container {
    position: relative;
    border: 1px solid $gray-30;
    min-height: 34px;
    padding: 6px 12px;
    cursor: text;
    &:hover {
      .formio-modaledit-edit {
        visibility: visible;
        opacity: 1;
        transition:
          visibility 0ms,
          opacity 200ms linear;
      }
    }
  }
  .formio-modaledit-edit {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition:
      opacity 200ms linear,
      visibility 0ms 200ms;
  }
}
.formio-modaledit-dialog {
  .formio-modaledit-close {
    position: absolute;
    top: 100%;
    right: 0;
    border-radius: 0;
  }
}
.reset-margins {
  html {
    margin: 0;
  }
  body {
    margin: 0;
  }
  div {
    margin: 0;
  }
  span {
    margin: 0;
  }
  applet {
    margin: 0;
  }
  object {
    margin: 0;
  }
  iframe {
    margin: 0;
  }
  h1 {
    margin: 0;
  }
  h2 {
    margin: 0;
  }
  h3 {
    margin: 0;
  }
  h4 {
    margin: 0;
  }
  h5 {
    margin: 0;
  }
  h6 {
    margin: 0;
  }
  p {
    margin: 0;
  }
  blockquote {
    margin: 0;
  }
  pre {
    margin: 0;
  }
  a {
    margin: 0;
  }
  abbr {
    margin: 0;
  }
  acronym {
    margin: 0;
  }
  address {
    margin: 0;
  }
  big {
    margin: 0;
  }
  cite {
    margin: 0;
  }
  code {
    margin: 0;
  }
  del {
    margin: 0;
  }
  dfn {
    margin: 0;
  }
  em {
    margin: 0;
  }
  img {
    margin: 0;
  }
  ins {
    margin: 0;
  }
  kbd {
    margin: 0;
  }
  q {
    margin: 0;
  }
  s {
    margin: 0;
  }
  samp {
    margin: 0;
  }
  small {
    margin: 0;
  }
  strike {
    margin: 0;
  }
  strong {
    margin: 0;
  }
  sub {
    margin: 0;
  }
  sup {
    margin: 0;
  }
  tt {
    margin: 0;
  }
  var {
    margin: 0;
  }
  b {
    margin: 0;
  }
  u {
    margin: 0;
  }
  i {
    margin: 0;
  }
  center {
    margin: 0;
  }
  dl {
    margin: 0;
  }
  dt {
    margin: 0;
  }
  dd {
    margin: 0;
  }
  ol {
    margin: 0;
  }
  ul {
    margin: 0;
  }
  li {
    margin: 0;
  }
  fieldset {
    margin: 0;
  }
  form {
    margin: 0;
  }
  label {
    margin: 0;
  }
  legend {
    margin: 0;
  }
  table {
    margin: 0;
  }
  caption {
    margin: 0;
  }
  tbody {
    margin: 0;
  }
  tfoot {
    margin: 0;
  }
  thead {
    margin: 0;
  }
  tr {
    margin: 0;
  }
  th {
    margin: 0;
  }
  td {
    margin: 0;
  }
  article {
    margin: 0;
  }
  aside {
    margin: 0;
  }
  canvas {
    margin: 0;
  }
  details {
    margin: 0;
  }
  embed {
    margin: 0;
  }
  figure {
    margin: 0;
  }
  figcaption {
    margin: 0;
  }
  footer {
    margin: 0;
  }
  header {
    margin: 0;
  }
  hgroup {
    margin: 0;
  }
  menu {
    margin: 0;
  }
  nav {
    margin: 0;
  }
  output {
    margin: 0;
  }
  ruby {
    margin: 0;
  }
  section {
    margin: 0;
  }
  summary {
    margin: 0;
  }
  time {
    margin: 0;
  }
  mark {
    margin: 0;
  }
  audio {
    margin: 0;
  }
  video {
    margin: 0;
  }
}
.ck-body {
  .ck.ck-balloon-panel {
    z-index: 101000;
  }
}
.datagrid-group-label.collapsed {
  > td {
    display: none;
  }
}
.datagrid-group-header.clickable {
  cursor: pointer;
  .datagrid-group-label {
    &:before {
      display: inline-block;
      vertical-align: middle;
      content: "▾";
      margin: 0 5px;
    }
  }
}
.datagrid-group-header.clickable.collapsed {
  .datagrid-group-label {
    &:before {
      content: "▸";
    }
  }
}
.formio-drag-column {
  width: 50px;
  white-space: nowrap;
  text-align: center;
}
.formio-remove-column {
  width: 50px;
  white-space: nowrap;
  text-align: center;
}
.formio-sketchpad-toolbar-input {
  height: 24px;
  margin-top: -5px;
  margin-bottom: -5px;
  width: 40px;
  margin-left: 5px;
}
.formio-sketchpad-toolbar-button {
  overflow: visible;
  &:hover {
    background: #e6e6e6;
  }
}
.formio-sketchpad-toolbar-group {
  background: #f2f2f2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-right: 3px;
}
.formio-sketchpad-toolbar {
  color: #000000;
  display: inline-block;
  margin-left: 0;
  border: 1px solid #999;
  border-radius: 3px;
  margin-bottom: -1px;
  position: relative;
  z-index: 3;
}
.formio-sketchpad-edit-dialog.formio-dialog.formio-dialog-theme-default {
  .formio-dialog-content {
    text-align: center;
    position: fixed;
    top: 3%;
    left: 3%;
    right: 3%;
    bottom: 3%;
    width: auto;
    overflow: auto;
    .formio-dialog-close {
      z-index: 4;
    }
  }
}
.formio-sketchpad-svg {
  border: 1px dashed #999;
}
.formio-sketchpad-edit-dialog-body {
  display: inline-block;
  text-align: left;
  max-width: 100%;
}
.formio-sketchpad-meta-info {
  text-align: right;
}
.formio-sketchpad-save-button {
  margin-bottom: 10px;
}
.formio-view-sketchpad-container {
  position: relative;
  overflow: auto;
}
.formio-edit-sketchpad-container {
  position: relative;
  overflow: auto;
}
.formio-view-sketchpad-background {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background: #ffffff;
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
.formio-edit-sketchpad-background {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  text-align: center;
  svg {
    background: #ffffff;
    border: 1px dashed #999;
  }
}
.formio-view-sketchpad-canvas {
  position: relative;
  z-index: 2;
  text-align: center;
  svg {
    display: block;
  }
}
.formio-edit-sketchpad-canvas {
  position: relative;
  z-index: 2;
  text-align: center;
}
.formio-tagpad-image-container {
  float: left;
  width: 50%;
  z-index: 1;
  position: relative;
  border: 1px dashed #999;
  border-radius: 3px;
}
.formio-tagpad-form-container {
  width: 50%;
  float: left;
}
.formio-tagpad-canvas {
  cursor: crosshair;
  position: absolute;
  z-index: 2;
}
.formio-tagpad-background {
  svg {
    width: 100%;
  }
}
.formio-tagpad-form {
  padding-left: 5px;
  padding-right: 5px;
}
.formio-tagpad-dot {
  cursor: pointer;
}
.formio-tagpad-dot-index {
  cursor: pointer;
}
.formio-tagpad-save-button {
  margin-right: 5px;
  margin-bottom: 5px;
}
.formio-tagpad-remove-button {
  margin-right: 5px;
  margin-bottom: 5px;
}
.formio-tagpad-form-title {
  font-size: 1.7em;
}
.formbuilder {
  position: relative;
  .drag-container {
    padding: 10px;
    min-height: 26px;
    border: dotted 2px #e8e8e8;
    &:hover {
      cursor: move;
      border: dotted 2px #ccc;
    }
  }
  .formio-component-htmlelement {
    padding: 10px;
    min-height: 26px;
    border: dotted 2px #e8e8e8;
  }
  .formio-component-custom {
    padding: 10px;
    min-height: 26px;
    border: dotted 2px #e8e8e8;
  }
  .form-builder-group-header {
    padding: 0;
  }
  .formio-dialog {
    .tab-content {
      padding-top: 12px;
    }
  }
  .formio-component-hidden {
    border: 2px dashed $gray-30;
    height: 3em;
    text-align: center;
    color: #aaa;
    padding-top: 0.5em;
  }
  .formio-component-form {
    border: 2px dashed $gray-30;
  }
  .row.formio-component-columns {
    margin-left: 0;
    margin-right: 0;
  }
  .formcomponents {
    .formcomponent {
      text-align: left;
      padding: 5px 5px 5px 8px;
      margin-top: 0.2rem;
      font-size: 0.8em;
      line-height: 1.2;
      border-radius: 0.3em;
    }
  }
  .form-builder-panel {
    .panel-body {
      padding: 5px;
    }
  }
  .formio-pdf-builder {
    position: relative;
  }
  .formio-drop-zone {
    display: none;
    position: absolute;
    z-index: 10;
    background-color: #0d87e9;
    opacity: 0.1;
  }
  .formio-drop-zone.enabled {
    display: inherit;
    + {
      .formio-pdf-builder {
        pointer-events: none;
      }
    }
  }
  .component-settings-button-paste {
    display: none;
  }
  .builder-paste-mode {
    .component-settings-button-paste {
      display: inherit;
    }
  }
  .wizard-page-label {
    cursor: pointer;
  }
  .panel-body {
    .drag-and-drop-alert {
      margin-bottom: 0;
    }
  }
}
.component-btn-group {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
  margin-top: -2px;
  .component-settings-button {
    padding: 2px 2px;
    font-size: 10px;
    line-height: 1.2em;
    border-radius: 0;
    width: 18px;
    height: 18px;
    float: right;
    z-index: 1001;
    margin: 4px 4px 0 0;
    z-index: 1001;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(48, 113, 169, 0.6);
    -moz-box-shadow: 0px 0px 10px 1px rgba(48, 113, 169, 0.6);
    box-shadow: 0px 0px 10px 1px rgba(48, 113, 169, 0.6);
  }
}
.btn-xxs {
  padding: 2px 2px;
  font-size: 10px;
  line-height: 1.2em;
  border-radius: 0;
  width: 18px;
  height: 18px;
}
.btn-group-xxs {
  > .btn {
    padding: 2px 2px;
    font-size: 10px;
    line-height: 1.2em;
    border-radius: 0;
    width: 18px;
    height: 18px;
  }
}
.builder-group-button {
  background-color: transparent;
  white-space: normal;
  text-align: left;
}
@media (min-width: 640px) {
  .choices__list--dropdown {
    .choices__item--selectable {
      padding-right: 100px;
      &:after {
        content: attr(data-select-text);
        font-size: 12px;
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  [dir="rtl"] {
    .choices__list--dropdown {
      .choices__item--selectable {
        text-align: right;
        padding-left: 100px;
        padding-right: 10px;
        &:after {
          right: auto;
          left: 10px;
        }
      }
    }
  }
}
@media (max-width: sm) {
  .formio-dialog.formio-dialog-theme-default {
    .formio-dialog-button {
      &:focus {
        -webkit-animation: none;
        animation: none;
      }
    }
  }
}
.theusername {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
