.image-title-wrapper {
    margin-bottom: 10px;

  .image-wrapper {
    margin-right: 5px;
  }

  .title-wrapper {
    margin: 0;
  }

  /* &.like-h1 {
    height: 56px;

    @include media-breakpoint-down(md) {
      height: 48px;
    }
  }
  &.like-h2 {
    height: 48px;

    @include media-breakpoint-down(md) {
      height: 40px;
    }
  }
  &.like-h3 {
    height: 40px;

    @include media-breakpoint-down(md) {
      height: 30px;
    }
  }
  &.like-h4 {
    height: 30px;

    @include media-breakpoint-down(md) {
      height: 22px;
    }
  }
  &.like-h5 {
    height: 22px;

    @include media-breakpoint-down(md) {
      height: 16px;
    }
  }
  &.like-h6 {
    height: 16px;

    @include media-breakpoint-down(md) {
      height: 14px;
    }
  } */

  // fix width of cloudinary image in firefox
  .ff-fix {
    &.w-h1 {
      width: 56px;

      @include media-breakpoint-down(md) {
        width: 48px;
      }
    }
    &.w-h2 {
      width: 48px;

      @include media-breakpoint-down(md) {
        width: 40px;
      }
    }
    &.w-h3 {
      width: 40px;

      @include media-breakpoint-down(md) {
        width: 30px;
      }
    }
    &.w-h4 {
      width: 30px;

      @include media-breakpoint-down(md) {
        width: 22px;
      }
    }
    &.w-h5 {
      width: 22px;

      @include media-breakpoint-down(md) {
        width: 16px;
      }
    }
    &.w-h6 {
      width: 16px;

      @include media-breakpoint-down(md) {
        width: 14px;
      }
    }
  }
}
