.button-group {
  .btn {
    margin-right: 2px;
    clip-path: polygon(10px 0%, 100% 0%, calc(100% - 10px) 100%, 0 100%);
    &:first-child {
      clip-path: polygon(0 0, 100% 0%, calc(100% - 10px) 100%, 0 100%);
    }
    &:last-child {
      clip-path: polygon(10px 0%, 100% 0, 100% 100%, 0% 100%);
    }
  }
  .part-of-group {
    padding: 8px 15px !important;
    margin-left: -10px;

    $button-colors: (
      primary: (
        $primary,
        $primary-highlight,
      ),
      secondary: (
        $secondary,
        $secondary-highlight,
      ),
      tertiary: (
        $tertiary,
        $tertiary-highlight,
      ),
      white: (
        $white,
        $white-highlight,
      ),
      green: (
        $green-light,
        $green-light-highlight,
      ),
    );

    @each $btn-type, $colors in $button-colors {
      &.btn-#{"" + $btn-type} {
        background: nth($colors, 1);

        $list: nth($colors, 1), nth($colors, 2);
        &:before {
          @include gradient(to right, $list);
        }
      }
    }
  }
}
