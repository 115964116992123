.language-switcher {
  display: flex;
  align-items: center;
  width: 100%;

  @include media-breakpoint-up(sm) {
    margin-bottom: 30px;
  }

  > :nth-child(1) {
    width: 10%;
    padding-right: 1rem;
  }
  > :nth-child(2) {
    max-width: 35%;
    width: 35%;
    @include media-breakpoint-down(sm) {
      max-width: unset !important;
      width: unset !important;
    }
  }
  > :nth-child(3) {
    @include media-breakpoint-up(sm) {
      margin-left: auto;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    & ~ .filter-wrapper {
      display: none;
    }
  }
  .image-wrapper {
    min-width: unset !important;
    height: 100%;
    max-width: 4rem !important;
    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
      height: 36px !important;
      width: 36px !important;
      padding-right: 0px;
      margin-right: 8px;
    }
  }
  .language-switcher__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 20px;
    @include media-breakpoint-down(sm) {
      flex-basis: 75%;
      margin-right: 0;
      margin-bottom: 10px;
    }
    .language-switcher__info-title {
      font-size: 22px;
      color: $tertiary;

      @include media-breakpoint-down(xs) {
        font-size: 16px;
        line-height: 1;
      }
    }
  }
  .btn {
    margin-right: 0;
  }
  .language-switcher__select {
    height: auto;
    width: 260px;
    margin-right: 1rem;
    margin-bottom: 5px;
    @include media-breakpoint-down(sm) {
      flex-basis: calc(67% - 5px);
      margin-right: 10px;
    }
  }

  > a {
    margin-bottom: 5px;
    @include media-breakpoint-down(sm) {
      flex-basis: calc(33% - 5px);
      margin-right: 0;
    }
  }
}
.locale-switcher {
  .contentset-wrapper {
    .entity-grid {
      .card {
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr 3fr;
        grid-template-areas: "image body" "image footer";

        .card-image {
          grid-area: image;
          width: auto;
          max-width: 100px;
          margin-right: 20px;
        }

        .card-body {
          grid-area: body;
          padding-bottom: 0;
          width: auto;
          max-width: none;
          margin-left: 8px;

          .card-title {
            @include media-breakpoint-down(sm) {
              font-size: 16px;
            }
          }
        }

        .card-footer {
          grid-area: footer;
          width: auto;
          text-align: left;
          padding-left: 0;
          margin-top: 0;
          padding-top: 0;
        }
      }
    }
  }
}
.entity-language-select {
  margin-bottom: 30px;
}
.lightbox-heading .container {
  padding: 0;
}
.country-language-switch {
  .container {
    padding: 0;
  }
  @include media-breakpoint-down(sm) {
    .filter-divider {
      margin-bottom: 0px !important;
    }
    .view__list_thin {
      margin-top: 0px !important;
    }
    .contentset-wrapper .filter-wrapper {
      display: none;
    }

    h3.h4 {
      font-size: 16px;
    }

    .language-switcher__info {
      .language-switcher {
        &__info-title {
          font-size: 20px;
        }
        &__info-subtitle {
          font-size: 14px;
        }
      }

      .load-more-divider {
        border-width: 0px;
      }
    }
  }
}

.language-switcher-header {
  top: -30px;
  left: -15px;
  margin: -30px -15px;
  margin-bottom: 0px;
  padding: 20px 15px;
  position: sticky;
  background: white;
  z-index: 999;
  box-shadow: 0px 3px 5px #0000001a;
}

.language-container {
  &__divider {
    margin-bottom: 20px;
  }

  .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .tab-primitive__content {
    box-shadow: $box-shadow;
    background: $white;
    color: $gray-100;

    &__item {
      padding: 30px;
    }
  }
  .language-card {
    max-height: fit-content !important;
    min-height: fit-content !important;
    padding-bottom: 15px !important;
    padding-top: 15px !important;

    .h6 {
      line-height: 18px !important;
    }
    .language-card__languages {
      font-size: 14px;
    }
  }
}

.language-portal {
  position: absolute;
  width: "fit-content";
  background-color: white;
  box-shadow: 0px 3px 5px $box-shadow-color;
  padding-top: 0.25rem;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid $white-highlight;
  z-index: 999;
  font-size: 0.875rem;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    z-index: 5;
  }

  &.bottom {
    &:before {
      border-bottom: 8px solid white;
      top: -8px;
    }
  }

  &.end {
    &:before {
      right: 12px;
    }
  }

  &.start {
    &:before {
      left: 12px;
    }
  }

  &.top {
    &:before {
      border-top: 8px solid white;
      bottom: -8px;
      right: 12px;
    }
  }

  .language-portal-title {
    padding: 0.625rem;
    font-weight: 700;
  }

  .language-portal-item {
    padding: 0.625rem;

    &:hover {
      text-decoration: none !important;
      background: $white-highlight;
    }

    &.active {
      font-weight: 700;
    }

    img {
      margin-right: 0.5rem;
    }
  }
}

.country-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  grid-auto-rows: minmax(88px, 1fr);
  gap: 0 30px;

  @include media-breakpoint-down(sm) {
    grid-auto-rows: minmax(70px, 1fr);
  }
}
