$asset-path: "/assets" !default;
$font-family: var(--din-web-pro-font), "Helvetica Neue", Arial, sans-serif;
$font-family-sans-serif: $font-family;

$blue: #005da8;
$blue-highlight: #2885d0;
$orange: #e94d19;
$orange-highlight: #fd612d;
$salmon: #f6b8a3;
$salmon-light: #fbdbd1;
$dark-blue: #455c6e;
$dark-blue-highlight: #586c7d;
$green: #28a745;
$green-light: #8cc645;
$green-light-highlight: #9ECF62;
$gray-100: #222;
$gray-90: #808b94;
$gray-80: #99a2a9;
$gray-60: #b3b9bf;
$gray-40: #ccd1d4;
$gray-30: #dddddd;
$gray-20: #e6e8ea;
$gray-10: #f7f8f9;
$black: #000;
$white: #fff;
$white-highlight: #e5eff6;
$spacer: 30px;

$primary: $blue;
$primary-highlight: $blue-highlight;
$secondary: $orange;
$secondary-highlight: $orange-highlight;
$tertiary: $dark-blue;
$tertiary-highlight: $dark-blue-highlight;
//$success: #0f0;
$success: #d4edda;
$info: $gray-80;
//$warning: #ff0;
$warning: #fff3cd;
//$danger: #f00;
$danger: #f5c6cb;
$light: $gray-20;
$dark: $gray-100;

$text-dark: $dark-blue;
$text-gray: $gray-80;

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "tertiary": $tertiary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

// Navbar
$navbar-light-color: $white;
$navbar-light-hover-color: $gray-100;
$navbar-light-gradient:
  $primary 90%,
  $primary-highlight 90%;

$navbar-padding-y: 1px;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: 7.5px;

$link-color: $text-dark;
$link-hover-color: $primary;
$link-active-color: $primary;

$base-angle: 8;
$transition-speed: 0.3s;
$icon-small: 18px;

$box-shadow-color: rgba($black, 0.1);
$box-shadow: 0px 3px 5px $box-shadow-color;

// Grid
$grid-gutter-width: $spacer;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1400px,
  3xl: 1650px,
  4xl: 2000px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1380px,
) !default;

$dropdown-border-radius: 0;
$dropdown-padding-y: 0;
$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 0.75rem;
$dropdown-link-hover-bg: $white-highlight;
$dropdown-link-hover-color: $primary;
$dropdown-border-color: $dropdown-link-hover-bg;
$dropdown-box-shadow: 0px 3px 5px $box-shadow-color;
$enable-shadows: true;
$dropdown-bg: $white;
$table-accent-bg: $gray-10;
$border-color: $gray-20;

$breadcrumb-bg: transparent;
$breadcrumb-padding-y: 1rem;
$breadcrumb-padding-x: 0;
$breadcrumb-divider-color: rgba($black, 0.2);
$breadcrumb-margin-bottom: 0;

$nav-tabs-border-color: transparent;
$nav-tabs-border-width: 2px;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: transparent transparent $primary transparent;

$embed-responsive-aspect-ratios: (
  (4 3),
  (24 10),
  (3 4),
  (16 9),
  (16 5),
  (1 1)
) !default;
