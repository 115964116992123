#basic-nav-dropdown {
  color: $black !important;
}

// temporary
.cld-responsive {
  width: 100%;
  height: auto;
  &.cld-reponsive-height {
    object-fit: cover;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  &--contain {
    object-fit: contain;
  }

  &--cover {
    object-fit: cover;
  }
}

.root {
  .page-content {
    & > div:not(.detailed-process-list) a:not(.btn, .nav-item, .arrow-list a) {
      font-weight: 700;
      color: $primary;
    }
  }
}

.nprogress-busy:not([data-no-loading="true"]) .root .page-content,
.nprogress-busy:not([data-no-loading="true"]) .root .breadcrumb,
.nprogress-busy:not([data-no-loading="true"]) .root .newsletter__container {
  opacity: 0.1;
}

.nprogress-busy[data-no-loading="true"] #nprogress .spinner {
  display: none;
}

hr {
  border-color: $gray-40;
}

.image-wrapper {
  display: flex;
  max-width: 100% !important;
}

.image-fill {
  min-height: 100%;
  min-width: 100%;
}

video {
  width: 100%;
  margin-top: 35px;
}

.related-action-list {
  max-width: 250px;
}

.special-spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: $primary;
    animation: dash 1.5s ease-in-out infinite;
  }
}

.shape-divider {
  display: none;
}

@include media-breakpoint-down(lg) {
  .shape-divider {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    background: white;
  }

  .shape-divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 40px;
    transform: rotateY(180deg);
  }

  .shape-divider .shape-fill {
    fill: $gray-10;
  }
}

.hyphenate {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

// dp-recommended images and product-detail / media tab screen images are not updating on safari otherwise.
.tab-content .slider__related-products-wrapper .static-image .image-wrapper,
.dp-recommended .image-wrapper {
  height: 100%;

  > span:first-child {
    position: static !important;
    width: 100% !important;
  }
}

figure {
  margin: 0;
}
