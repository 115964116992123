.card-navigation-wrapper {
    padding: 25px 0;
    overflow: hidden;

    .card-navigation-mobile {
      display: none;

      &__landing-page {
        bottom: 10%;
        left: 0;
        right: 0;
        z-index: 20;
        display: block;

        &.last-button {
            bottom: 0;
        }

        p {
          color: white;
          font-size: 2rem !important;
          font-weight: 500;
          margin-bottom: 1rem;
        }
      }
    }

    .card-navigation-content-wrapper {
      display: flex;
      justify-content: space-between;

      h5 {
        color: #455c6e;
        font-size: 16px;
        font-weight: 400;
      }

      p {
        color: #222;
        font-size: 14px;
      }
    }

    .card-navigation {
      position: relative;
      padding-top: 75%;
      background-size: cover !important;
      background-position: center;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        clip-path: unset !important;
      }

      .card-navigation-outer {
        position: absolute;
        padding-right: 20px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        top: 0px;
        z-index: 10;

        .card-navigation-gradient {
          position: absolute;
          bottom: 0px;
          right: 0px;
          left: 0px;
          background: linear-gradient(0deg, rgba($black, 0.75), transparent);
          height: 50%;
          opacity: 1;

          @include media-breakpoint-down(md) {
            height: 75%;
          }
        }

        .card-navigation-body {
          position: absolute;
          bottom: 18px;
          left: 22px;
          transition: all 0.6s ease;
          padding-right: 20px;

          .card-navigation-content {
            opacity: 0;
            height: 0;
          }

          h4,
          .h4,
          h5,
          .h5 {
            margin-bottom: 0;
            font-weight: 400;
          }
          h3 {
            text-shadow: 0 -1px 4px rgba($black, 0.45);
          }

          h4,
          .h4,
          h5,
          .h5,
          p,
          a {
            z-index: 5px;
            color: $white;
          }
        }
      }

      .card-surface {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $primary;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: 10;
        transition: all 0.6s ease;
      }

      /* hover state */
      &:hover {
        .card-navigation-outer {
          z-index: 20;

          .card-navigation-gradient {
            opacity: 0;
          }

          .card-navigation-body {
            bottom: 20px;

            h3 {
              text-shadow: none;
            }

            &:not(:has(> a:last-child)) {
              h4,
              .h4,
              h5,
              .h5 {
                margin-bottom: 10px;
              }

              p {
                margin-bottom: 20px;
              }
            }

            .card-navigation-content {
              opacity: 1;
              height: auto;
            }
          }
        }

        .card-surface {
          opacity: 0.8;
        }
      }
    }

    &.card-navigation-parallelogram {
      .wrap-it {
        .card-navigation {
          transition: all 0.6s ease;
          padding-top: 67%;

          .card-navigation-outer {
            .card-navigation-body {
              left: 50px;
              bottom: 23px;

              h4,
              .h4,
              h5,
              .h5 {
                font-size: 30px;
                line-height: 39px;
                width: fit-content;
              }

              p {
                width: 65%;
              }
            }
          }

          /* hover state */
          &:hover {
            z-index: 20;

            .card-navigation-outer {
              .card-navigation-body {
                bottom: 30px;
                left: 76px;

                &:not(:has(> a:last-child)) {
                  h4,
                  .h4,
                  h5,
                  .h5 {
                    font-size: 20px;
                    line-height: 1.2;
                  }
                }
              }
            }
          }

          @include media-breakpoint-down(md) {
            &:hover {
              .card-surface {
                opacity: 0;
              }
              .card-navigation-gradient {
                opacity: 1;
              }
            }
          }

          &.row-start {
            .card-navigation-outer .card-navigation-body {
              left: 20px;
            }

            &:hover {
              margin-right: calc(-3.2vw - 20px);
              z-index: 20;
            }
          }

          &.row-center {
            margin-left: calc(-1.8vw - 20px);

            &:hover {
              margin-left: calc(-5vw - 20px);
              margin-right: calc(-3.2vw - 20px);
              z-index: 20;
            }
          }

          &.row-end {
            margin-left: calc(-1.8vw - 20px);

            &:hover {
              z-index: 20;
              margin-left: calc(-5vw - 20px);
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        .card-navigation-body {
          display: none;
        }

        .card-navigation-mobile {
          display: block;
          padding: 20px;
          text-align: center;
          margin-bottom: 10px;

          .h5,
          h5 {
            margin-bottom: 5px;
          }

          p {
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
    &:not(.card-navigation-parallelogram) {
      @include media-breakpoint-down(sm) {
        display: block !important;
        .card-navigation {
          display: none !important;
        }
        .card-navigation-mobile {
          border-bottom: solid 1px $white-highlight;
          display: block;
          padding: 0px;

          a {
            display: grid;
            grid-template-columns: 30% auto;
            grid-column-gap: 0px;
            .card-navigation-content {
              padding: 10px;
              padding-left: 0;
              h5 {
                margin: 0;
              }
            }
            img {
              padding: 10px 0 10px 0;
              padding-right: 0;
              width: auto;
              max-width: 100%;
              object-position: left center;
            }
            .svg {
              margin-left: auto;
              align-self: center;
            }
          }
          &.has-subtitle {
            a {
              align-content: flex-start;
              align-items: center;
            }
          }
        }
      }
    }

    &.grid-with-headline-3col {
      padding: 0;
      overflow: hidden;
      height: calc(100vh - var(--header-height));

      @include media-breakpoint-down(sm) {
        padding: 0 !important;
        max-width: 100% !important;
        height: 100%;

        .heading h2 {
          margin-top: 15px;
          @include typography(300, 30, 1.1);
        }
      }

      &.card-navigation-parallelogram {
        .wrap-it {
          width: 100%;
          z-index: 12;
          position: relative;

          &:first-child {
            z-index: 15;
          }

          &.row {
            margin-top: -11%;

            @include media-breakpoint-down(sm) {
              margin: -11% 0 0;
            }
          }

          @include media-breakpoint-down(sm) {
            clip-path: polygon(0 0, 100% 0, 100% 89%, 0 100%) !important;

            &:first-child {
              clip-path: polygon(0 0, 100% 0%, 100% 89%, 0% 100%) !important;
            }

            &:last-child {
              clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 100%) !important;
            }
          }

          .card-navigation {
            height: 100%;

            .card-navigation-gradient {
              height: 50%;

              @include media-breakpoint-down(md) {
                height: 100%;
              }
            }

            @include media-breakpoint-down(md) {
              .card-navigation-body {
                display: flex;
              }
            }

            @include media-breakpoint-down(sm) {
              .card-navigation-body {
                display: none;
              }
            }

            .container__section {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            }

            &.headline-grid {
              padding-top: 0;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              transition: none;

              .heading {
                text-align: left;
                width: 60%;
                margin: 20px 10%;
              }

              &.row-start {
                z-index: 30;
                min-width: 500px;

                &:hover {
                  margin-right: 0;
                  z-index: 30;
                  .card-navigation-outer {
                    .card-navigation-body {
                      bottom: 4rem;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    }
                  }
                }
                @include media-breakpoint-down(sm) {
                  min-width: 100%;

                  .container__section {
                    width: 100%;
                  }

                  .heading {
                    width: 100%;
                    margin: 0 0 15px;

                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                      font-size: 24px;
                      font-weight: 500;
                      margin-top: 20px;
                    }
                  }
                }
              }
            }

            &.row-center {
              margin-left: -20vw;
              margin-right: -20vw;

              @include media-breakpoint-up(4xl) {
                margin-left: -10vw;
                margin-right: -10vw;
              }

              .card-navigation-outer {
                .card-navigation-body {
                  bottom: 4rem;
                  left: 50%;
                  transform: translate(-90%, 0);

                  h3,
                  .h5 {
                    font-size: 40px;
                  }
                }
              }
              &:hover {
                z-index: 13;
                margin-left: -20vw;
                margin-right: -23vw;

                @include media-breakpoint-up(4xl) {
                  margin-left: -10vw;
                  margin-right: -13vw;
                }

                .card-navigation-body {
                  transform: translate(-100%, 0);
                }
                .card-surface {
                  opacity: 0;
                }
                .card-navigation-gradient {
                  opacity: 1;
                }
              }
            }

            &.row-end {
              height: calc(100% + 5px);
              top: -5px;
              margin-left: -5vw;
              &:hover {
                margin-left: -8vw;
              }

              @include media-breakpoint-down(lg) {
                top: 0;
                margin-left: 0;
                margin-left: -10vw;
                &:hover {
                  margin-left: -15vw;
                }
              }

              @include media-breakpoint-down(sm) {
                top: 0;
                margin-left: 0;
              }

              .card-navigation-outer {
                .card-navigation-body {
                  bottom: 4rem;
                  left: 50%;
                  transform: translate(-30%, 0);

                  h3,
                  .h5 {
                    font-size: 40px;
                  }
                }
              }
              &:hover {
                z-index: 13;
                .card-navigation-body {
                  bottom: 4rem;
                  transform: translate(-20%, -0);
                }
                .card-surface {
                  opacity: 0;
                }
                .card-navigation-gradient {
                  opacity: 1;
                }
              }

              @include media-breakpoint-down(sm) {
                clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 100%) !important;
              }
            }

            @include media-breakpoint-down(xxl) {
              .btn {
                display: none;
              }
            }
            @include media-breakpoint-down(sm) {
              .btn {
                display: flex;
              }

              .heading {
                padding: 0 0 35px;
              }
            }
          }

          .card-navigation-mobile {
            display: none;

            @include media-breakpoint-down(sm) {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  @media all and (-ms-high-contrast: none) {
    .card-navigation-wrapper {
      margin-bottom: 0;
      margin-right: -30px;
      .wrap-it {
        margin-right: 30px;
        margin-bottom: 30px;
      }
    }
  }

  .component__navigation.bg-primary-gradient + .card-navigation-wrapper:not(id) {
    background-image: linear-gradient(to left, $primary, $primary-highlight);
  }
