.header-overview {
  position: relative;
  min-height: 33vw;
  max-height: 765px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .header-overview__background {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(45% + 15px);
    height: 100%;
    display: flex;
    background: $white;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      display: none;
      clip-path: unset !important;
    }

    &.background-blue {
      @include media-breakpoint-up(lg) {
        background-image: linear-gradient(
          to left,
          $primary,
          $primary-highlight
        );
      }
    }

    &.background-orange {
      @include media-breakpoint-up(lg) {
        background-image: linear-gradient(
          to left,
          $secondary,
          $secondary-highlight
        );
      }
    }

    &.background-green {
        @include media-breakpoint-up(lg) {
          background-image: linear-gradient(
            to left,
            $green-light,
            $green-light-highlight
          );
        }
      }

    &.background-grey {
      @include media-breakpoint-up(lg) {
        background-image: linear-gradient(to left, $gray-30, $gray-10);
      }
    }
  }

  ul {
    @extend .bullet-point-list;
  }

  &__keyvisual {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: auto;
    min-width: 59.1%;
    aspect-ratio: 16/9;
    height: 100%;
    overflow: hidden;

    .image-wrapper {
      height: 100% !important;
    }

    @media (max-width: 1199px) {
      @include media-breakpoint-down(lg) {
        .image-wrapper {
          height: 100% !important;
          & > span {
            padding-left: 17%;
            width: 117% !important;
            height: 100% !important;
          }
        }
      }
    }
    img {
      object-fit: cover;
      object-position: top center;
    }
  }

  &.small-header {
    @include media-breakpoint-up(lg) {
      min-height: 400px;
      max-height: 100%;
    }
    .header-overview__content {
      @include media-breakpoint-up(lg) {
        display: inline;
      }
      padding: 9% 6% 3% 7%;
    }
    .header-overview__keyvisual {
      aspect-ratio: 16/5;
      margin-left: 40%;
      width: 59.1% !important;
      .image-wrapper {
        height: 100%;
        > span:first-child {
          height: 100% !important;
        }
        @include media-breakpoint-down(xl) {
          max-width: 120% !important;
          height: 100%;
        }
      }
    }
    .margin-bottom-small {
      margin-bottom: 0;
    }
  }
  &.no-keyvisual {
    .header-overview__content {
      width: 100%;
      clip-path: none;
      position: static;
    }
  }
  &__wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;

    @include media-breakpoint-up(3xl) {
      max-width: none;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }

    @include media-breakpoint-down(md) {
      max-width: none;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }

    &__content {
      flex: 1;
      min-height: 100%;
      height: 100%;
      width: 45%;
      padding: 0 10% 0 7%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 2;

      @media screen and (min-width: 0\0) {
        flex-basis: 1000px;
      }

      &--blue,
      &--orange,
      &--green {
        color: white;

        .bullet-point-list li::before {
          color: white;
        }
      }

      &--blue {
        @include media-breakpoint-down(md) {
          padding-top: 5% !important;
          padding-bottom: 5% !important;
          background-image: linear-gradient(
            to left,
            $primary,
            $primary-highlight
          );
        }
        background: none;
      }

      &--orange {
        @include media-breakpoint-down(md) {
          padding-top: 5% !important;
          padding-bottom: 5% !important;
          background-image: linear-gradient(
            to left,
            $secondary,
            $secondary-highlight
          );
        }
        background: none;
      }

      &--green {
        @include media-breakpoint-down(md) {
          padding-top: 5% !important;
          padding-bottom: 5% !important;
          background-image: linear-gradient(
            to left,
            $green-light,
            $green-light-highlight
          );
        }
        background: none;
      }

      &--grey {
        @include media-breakpoint-down(md) {
          padding-top: 5% !important;
          padding-bottom: 5% !important;
          background-image: linear-gradient(to left, $gray-30, $gray-10);
        }
        background: none;
      }

      &__container {
        &.service-content-container {
          margin-top: 90px;
          margin-bottom: 45px;
          max-height: 800px;

          @include media-breakpoint-down(xl) {
            margin-top: 30px;
            margin-bottom: 15px;
          }
        }

        @include media-breakpoint-up(lg) {
          padding-left: 0 !important;
        }
      }
    }
  }

  .overview-search {
    margin-bottom: 25px;
    margin-top: 30px;
    max-width: 85%;
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
    > .react-select__container {
      width: 100%;
      > .react-select__control {
        box-shadow: 0 4px 8px rgba($black, 0.1);
        border: 1px solid $white-highlight;
      }
      .react-select__menu-list {
        max-height: 200px; // restrict height to not get cut from overflow container
      }
    }
  }

  .media-element {
    display: flex;
    margin-top: 30px;
    cursor: pointer;

    .media-keyvisual {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;
    }

    .media-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .media-play {
      display: flex;
      flex-direction: row;
      align-items: center;

      span.svg {
        margin-right: 5px;
      }
    }
  }

  @include media-breakpoint-down(3xl) {
    .header-overview__wrapper__content {
      padding-left: 7%;
      padding-right: 10%;
      padding-top: 0%;
      padding-bottom: 0;
    }
  }
  @include media-breakpoint-down(xxl) {
    .header-overview__wrapper__content {
      padding: 3% 5% calc(20px + 1%) 0%;
    }
  }

  @include media-breakpoint-down(lg) {
    .header-overview__wrapper__content {
      width: 45%;
      padding: 2% 5% calc(0px + 0%) 0%;
    }
  }

  &.interactive {
    @include media-breakpoint-down(xl) {
      max-height: unset;

      .header-overview__background {
        display: none;
        clip-path: unset !important;
      }

      .header-overview__keyvisual {
        margin-left: 0 !important;
        width: 100% !important;
        position: static;
        width: 100%;
        height: auto;
      }

      .header-overview__wrapper__content {
        padding-top: 50px;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0;
        width: 100%;
        position: relative;

        &__container {
          width: 100%;
        }
      }

      &.small-header {
        .header-overview__keyvisual {
          width: 100% !important;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    max-height: unset;

    .header-overview__keyvisual {
      margin-left: 0 !important;
      width: 100% !important;
      position: static;
      width: 100%;
      height: auto;
    }

    .header-overview__wrapper__content {
      padding-top: 50px;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-bottom: 0;
      width: 100%;
      position: relative;

      &__container {
        width: 100%;
      }
    }

    &.small-header {
      .header-overview__keyvisual {
        width: 100% !important;
      }
    }
  }
}
@media all and (-ms-high-contrast: none) {
  .header-overview__wrapper__content {
    display: block;
    padding-top: 100px;
  }
}

@media all and (display-mode: fullscreen) {
  .fullscreen-only-interactive-map {
    .page-content > *:not(.header-overview) {
      display: none;
    }

    .header-overview {
      height: 100vh;
      max-height: none;

      &__wrapper {
        display: none;
      }

      &__background {
        display: none;
      }

      &__keyvisual {
        left: 0;
        aspect-ratio: unset;
        background: $black;
        display: flex;
        align-items: center;

        .interactive-map {
          width: 100%;
          max-height: 100vh;
        }
      }
    }
  }
}
