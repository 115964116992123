.component__history {
  .timeline-slider {
    user-select: none;
    padding: 48px 0;

    @media (max-width: 767px) {
      .swiper {
        &.history-slider {
          cursor: default;
        }
      }
    }

    .swiper {
      width: 100%;
      margin-left: 0;
      cursor: grab;

      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.date-slider {
        display: flex;
        align-items: center;
        width: 100%;
        height: 300px;
        margin-right: 30px;

        .swiper-wrapper {
          position: relative;
        }

        .position-marker {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          height: 50px;
          width: 100%;

          .marker-wrapper {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            height: 100%;

            .marker-mousemove,
            .marker-arrow {
              display: flex;
              align-items: center;
              position: absolute;
              width: 30px;
              height: 30px;
              background-size: 30px 30px;
              background-repeat: no-repeat;
            }

            .marker-arrow {
              left: 0;
              background-image: url("../../public/assets/favicon-32x32.png");
            }

            .marker-mousemove {
              right: 0;
            }

            &:before,
            &:after {
              display: flex;
              content: "";
              position: absolute;
              width: 100%;
              height: 1px;
              left: 30px;
            }

            &:before {
              border-top: 2px solid $light;
              top: 0;
            }

            &:after {
              bottom: 0;
              border-bottom: 2px solid $light;
            }
          }
        }

        .swiper-slide {
          justify-content: flex-start;
          position: relative;
          opacity: 0.3;
          padding-left: 10px;
          font-size: 16px;

          .year {
            margin-left: 30px;
          }

          &.swiper-slide-active {
            color: $primary;
            opacity: 1;
            font-size: 24px;
          }

          &.swiper-slide-next,
          &.swiper-slide-prev {
            opacity: 0.7;
            font-size: 20px;
          }
        }
      }

      &.history-slider {
        position: relative;

        &:before,
        &:after {
          content: "";
          position: absolute;
          left: 0;
          width: 100%;
          height: 80px;
          z-index: 2;
        }

        &:before {
          top: 0;
        }

        &:after {
          bottom: 0;
        }

        .swiper-slide {
          flex-direction: column;
          align-items: flex-start;
          color: lightgray;
          height: auto;
          opacity: 0.5;

          &.swiper-slide-active {
            color: black;
            font-size: 20px;
            opacity: 1;
          }

          &.swiper-slide-next,
          &.swiper-slide-prev {
            opacity: 0.5;
            font-size: 16px;
          }
        }

        .history {
          text-align: left;
          margin-bottom: 5px;
        }
      }

      @media (max-width: 767px) {
        &.date-slider {
          width: 100%;
          height: 180px;
          margin-bottom: 15px;
          margin-right: 0;

          .position-marker {
            height: 60px;

            .marker-wrapper {
              height: 100%;

              &:before,
              &:after {
                left: 0;
              }

              &:before {
                border-top: 1px solid $white-highlight;
              }

              &:after {
                border-bottom: 1px solid $white-highlight;
              }
            }
          }

          .swiper-slide {
            justify-content: center;

            &.swiper-slide-active {
              font-size: 26px;
            }

            &.swiper-slide-next,
            &.swiper-slide-prev {
              opacity: 0.6;
              font-size: 16px;
            }

            .year {
              margin-left: 0;
            }
          }
        }

        &.history-slider {
          width: 100%;

          .swiper-slide {
            height: auto;
            justify-content: flex-start;
          }
        }
      }
    }
  }

  &:not(.bg-gray):not(.bg-secondary):not(.bg-primary-gradient) {
    .swiper.history-slider {
      &:before {
        background: linear-gradient(180deg, $white 0%, rgba($black, 0) 100%);
      }
      &:after {
        background: linear-gradient(0deg, $white 0%, rgba($black, 0) 100%);
      }
    }
  }

  &.bg-gray {
    .timeline-slider {
      .history-slider {
        &:before {
          background: linear-gradient(
            180deg,
            $gray-10 0%,
            rgba($black, 0) 100%
          );
        }
        &:after {
          background: linear-gradient(0deg, $gray-10 0%, rgba($black, 0) 100%);
        }

        .swiper-slide {
          color: $black;
        }
      }
    }
  }

  &.bg-primary-gradient,
  &.bg-secondary {
    &.text-white {
      .timeline-slider {
        .history-slider {
          .swiper-slide {
            color: $white;
          }
        }
        .date-slider {
          .swiper-slide {
            color: $gray-20;
          }
          .swiper-slide-active {
            color: $white;
          }
        }
      }
    }
  }

  &.bg-secondary {
    .swiper.history-slider {
      &:before {
        background: linear-gradient(
          180deg,
          $secondary 0%,
          rgba($black, 0) 100%
        );
      }
      &:after {
        background: linear-gradient(0deg, $secondary 0%, rgba($black, 0) 100%);
      }
    }
  }
}
