.contentset-wrapper {
  scroll-margin-top: 7.5rem;

  .filter-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 -15px;
    padding: 0 15px;
    /* background-color: $white; */
    transition: box-shadow $transition-speed ease;

    .filter-wrapper__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      margin: 0 -15px;
      padding: 0 15px;
      /* background-color: $white; */
      transition: box-shadow $transition-speed ease;
    }

    &.is-sticking {
      position: sticky;
      top: calc(var(--navigation-height, 0px) + var(--tab-height, 0px));
      height: 0;
      z-index: 20;
      background-color: $white;
      transition: top 150ms ease;

      .filter-wrapper__content {
        box-shadow:
          0px 8px 5px -5px $box-shadow-color,
          0 -32px 0 0 $white;
        background-color: $white;
      }
      &.is-open {
        .filter-wrapper__content {
          padding-bottom: 0.75rem;
        }
      }
    }

    &.no-filters {
      .filter-options-wrapper {
        justify-content: flex-end;
        margin: 0;

        .filter-title {
          display: none;
        }
      }

      .filter-button-wrapper {
        display: none;
      }

      .filter-values-wrapper {
        display: none;
      }
    }

    .filter-options-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .filter-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
        .beforeIcon {
          svg {
            top: 0;
          }
        }

        p.h6 {
          color: $tertiary;
          margin: 0 0 0 10px;
        }
      }
    }

    .filter-button-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-bottom: -10px;
      margin-right: -15px;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
      }

      & > * {
        margin-right: 15px;
        margin-bottom: 10px;

        &:last-child {
          margin-right: 0;
        }

        @include media-breakpoint-down(sm) {
          margin-right: 0;
        }
      }

      .filter-button {
        position: relative;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0 12px 16px;
        border: 1px solid $gray-40;
        font-size: 14px;
        line-height: 21px;
        color: $tertiary;
        max-width: 250px;
        height: 50px;
        transition:
          border-color $transition-speed ease,
          color $transition-speed ease;

        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }

        .filter-button-label {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          transition: font-weight $transition-speed ease;
        }

        .afterIcon {
          padding: 0 10px 0 16px;

          .svg {
            opacity: 1;
          }
        }

        &:hover {
          cursor: pointer;
          border-color: $primary;
          color: $primary;
        }

        &.selected {
          border-bottom: 2px solid $primary;
          color: $primary;

          .filter-button-label {
            font-weight: 700;
          }
        }
      }

      .search-filter {
        flex-grow: 1;
        max-width: 25%;

        & + .react-select__container {
          flex-grow: 0;
        }
      }

      .react-select__container {
        flex-grow: 1;
        max-width: 25%;

        &.has_input {
          .react-select__control {
            border-bottom: 2px solid $primary !important;
          }
          .react-select__input input {
            font-weight: 700;
          }
        }

        &:hover {
          .react-select__control {
            border-color: $primary;
            transition: border-color $transition-speed ease;

            .react-select__placeholder {
              color: $primary;
            }
          }
        }

        .react-select__indicator:hover .svg svg {
          fill: $primary;
        }

        @include media-breakpoint-down(sm) {
          max-width: none;
          width: 100%;
        }
      }

      &:not(.show-additional-filters) .additional-filter {
        display: none;
      }
    }

    .filter-values-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 1rem;
      gap: 0.5rem 0.75rem;

      .filter-selected-option-tag {
        display: flex;
        align-items: center;
        background-color: $white-highlight;
        border: none;
        min-height: 2rem;
        padding: 0.25rem 0.75rem;
        cursor: pointer;

        .svg {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .label {
          color: $primary;
          font-size: 0.875rem;
          font-weight: 700;
          margin-left: 0.25rem;
        }
      }

      .reset-button {
        color: $gray-80;
        margin: 0 0 0.5rem 0;
        box-shadow: none;
        margin-bottom: 0;

        &:active {
          box-shadow: none;
        }

        .svg {
          opacity: 1;

          svg {
            fill: $gray-80;
          }
        }

        &:hover {
          color: $tertiary;

          .svg svg {
            fill: $tertiary;
          }
        }
      }
    }

    .filter-options {
      display: flex;
      align-items: center;

      & > * {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }

      .amount {
        font-size: 14px;
        line-height: 21px;
        color: $gray-60;
      }

      .view-switcher {
        display: flex;
        align-items: center;
        .view-switcher-button {
          color: $gray-80;
          width: 18px;
          height: 18px;
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
          &.active {
            color: $primary;
          }
          &:hover {
            cursor: pointer;
          }
          .svg {
            display: flex;
          }
          @include media-breakpoint-down(sm) {
            &.switch-small-tiles {
              display: none !important;
            }
          }
        }
      }

      .svg {
        svg {
          &:hover {
            transition: all 0.3s;
            fill: $primary;
          }
        }
      }
    }

    .css-mqshio-container {
      .css-9v33uu-control:hover {
        border-color: $primary;
        color: $primary;
        transition: all 0.3s;

        .css-12jtsin {
          .svg svg {
            fill: $primary;
          }
        }

        .css-1wa3eu0-placeholder {
          color: $primary;
        }
      }
    }

    .filter-sticky-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      min-height: 3.5rem;

      .filter-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 1.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &:hover {
          cursor: pointer;
        }

        .beforeIcon {
          svg {
            top: 0;
          }
        }

        p.h6 {
          color: $tertiary;
          margin: 0 0 0 10px;
        }
      }
    }

    .filter-sticky-summary {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      min-height: 3.5rem;

      &__content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .filter-values-wrapper {
          margin-top: 0;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }

      &__show-more-button.btn {
        flex-shrink: 0;
        margin: 0 0 0 1.5rem;
      }
    }

    .filter-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 1.5rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      &:hover {
        cursor: pointer;
      }

      .beforeIcon {
        svg {
          top: 0;
        }
      }

      p.h6 {
        color: $tertiary;
        margin: 0 0 0 10px;
      }
    }
  }

  &--preview-filter {
    .filter-wrapper {
      &.not-filtered {
        display: none;
      }
    }
  }

  .filter-divider {
    margin-top: 20px;
  }

  .search-filter {
    position: relative;

    &--active {
      .search-filter__input {
        font-weight: 700;
        border-bottom: 2px solid $primary;
      }

      .search-filter__icon--clear {
        display: flex !important;
      }
    }

    &__input {
      padding: 10px 38px 10px 38px;
      border: 1px solid $gray-40;
      width: 100%;
      height: 50px;
      font-size: 14px;
      color: $primary;
      outline: none;
      transition: border-color $transition-speed ease;

      &::placeholder {
        transition: color $transition-speed ease;
      }
    }

    &:hover {
      .search-filter__input {
        border-color: $primary;

        &::placeholder {
          color: $primary;
        }
      }
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      padding: 10px;
      cursor: pointer;
    }

    &__icon--clear {
      left: auto;
      right: 0;
      display: none !important;

      svg {
        transition: fill $transition-speed ease;
      }

      &:hover svg {
        fill: $primary !important;
      }
    }
  }

  .no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 48px 0;
    flex-direction: column;

    .label {
      text-align: center;
      color: $gray-60;
      margin: 0;
    }
  }

  .entity-grid {
    &:not(.view__list) > div {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .card-date {
      @include typography(400, 14, 21px);

      color: $breadcrumb-active-color;
    }

    .horizontal .card-image:empty {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      .horizontal .card-feature {
        display: none;
      }
    }

    .horizontal .card-image:empty + .card-body {
      max-width: calc(83% - 25px);
    }

    .horizontal-thin {
      .card-title {
        font-size: 18px;
      }

      .card-image {
        margin-left: 0;
      }
    }
  }

  .load-more-divider {
    border-color: $white-highlight;
  }

  .load-more-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .load-more-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      max-width: 430px;

      .counter-text {
        font-size: 14px;
        text-align: center;
        color: $gray-60;
        margin: 0 0 15px 0;
      }

      .status-bar {
        position: relative;
        background-color: $white-highlight;
        width: 100%;
        height: 4px;
        overflow: hidden;
        margin-bottom: 20px;

        .bar {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 0%;
          background-color: $primary;
          transition: width 1s ease;
        }
      }
    }
  }

  .filter-modal {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    z-index: 1000;
    .filter-modal-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      width: 27.5rem;
      max-width: 100%;
      height: 100%;
      background: $white;

      transition-property: opacity transform;
      transition-duration: $transition-speed;
      transition-timing-function: ease;

      opacity: 0;
      transform: translateX(-100%);

      &-entering,
      &-entered {
        opacity: 1;
        transform: translateX(0);
      }

      .filter-modal__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        height: 6rem;
        background-color: $primary;
        color: $white;
        padding: 1rem 3rem;
        z-index: 5;

        @include media-breakpoint-down(sm) {
          padding: 1rem 2rem;
          height: 4rem;
        }

        .form-label {
          margin: 0;

          h4 {
            margin: 0;
          }
        }

        .filter-modal__close {
          padding: 1rem;
          margin: -1rem;
          cursor: pointer;

          .svg.close-big:hover svg {
            fill: $white-highlight;
          }
        }
      }

      .filter-modal__form {
        flex-grow: 1;
        padding: 2.5rem 3rem;
        overflow-y: auto;

        @include media-breakpoint-down(sm) {
          padding: 2.5rem 2rem;
        }

        .form-group {
          margin-top: 0;

          .filter-group {
            border-bottom: 1px solid $gray-20;
            margin-bottom: 0px;

            .filter-group__header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              background: none;
              border: none;
              padding: 1.5rem 0;
              appearance: none;
              cursor: pointer;

              .form-label {
                margin: 0;
                cursor: inherit;
                text-align: left;

                .h5 {
                  font-weight: 400;
                  font-size: 22px;
                  color: $tertiary;
                  margin: 0;
                }
              }

              .icon {
                transition: transform $transition-speed ease;
              }

              &--active,
              &--selected {
                .form-label {
                  .h5 {
                    font-weight: 400;
                    color: $primary;
                  }
                }
              }

              &--active {
                .icon {
                  transform: rotate(180deg);

                  svg {
                    fill: $primary;
                  }
                }
              }
            }

            .react-select__container,
            .search-filter {
              width: 100%;
              margin-bottom: 1.5rem;
            }

            .filter-selectable-options {
              margin-bottom: 1.5rem;

              .filter-selectable-option {
                margin-bottom: 6px;
                .filter-selectable-option-spacer {
                  margin: 0 4px;
                }

                .filter-selectable-options {
                  margin-bottom: 0;
                }

                &.selected {
                  > .form-check {
                    .form-check-option-label {
                      font-weight: 700;
                    }
                  }
                }
                &.empty {
                  .form-check-label {
                    span {
                      color: $gray-40;
                    }
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                }

                .form-check {
                  align-items: flex-start;

                  .form-check-option-label {
                    margin-top: -2px;
                  }
                }
              }
            }

            .form-group {
              margin: 0;

              .filter-group {
                margin-left: -2px;
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: 0;

                &:first-child {
                  padding-top: 0.25rem;
                }

                .form-label &:first-of-type {
                  display: none;
                }
              }
            }

            .form-label {
              line-height: normal;
              margin-bottom: 0;

              h3,
              .h5 {
                font-size: 14px;
                font-weight: 700;
                line-height: 1.5;
                margin-bottom: 0.625rem;
              }
            }

            .css-mqshio-container {
              width: auto;
              height: 50px;

              &:hover {
                border: 1px solid $primary;
                color: $primary;
                transition: all 0.3s;
              }
            }
          }
        }
      }
      .filter-controls {
        background: $gray-20;
        padding: 2rem 3rem;
        min-height: 6rem;
        flex-shrink: 0;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;

        @include media-breakpoint-down(sm) {
          padding: 1.5rem 2rem;
        }

        & > * {
          margin-right: 10px;
          margin-bottom: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .close-filter-modal {
      width: 100%;
      height: 100%;
      z-index: -1;
      position: absolute;
      background: rgba($gray-100, 0.4);
      transition: opacity $transition-speed ease;
      opacity: 0;

      &:hover {
        cursor: pointer;
      }

      &-entering,
      &-entered {
        opacity: 1;
      }
    }
  }
  .entity-table {
    width: 100%;
    word-break: break-word;
    margin-bottom: 30px;
    .tbody {
      .tr {
        align-items: center;
        &:nth-child(2n + 1) {
          background-color: $gray-10;
        }
      }
    }
    .tr {
      border-bottom: 1px solid $gray-20;
      display: flex;
      .th,
      .td {
        padding: 15px 20px;
      }
      .th {
        font-weight: 700;
        @extend .h6;
        color: $tertiary;
        margin-bottom: 0;
        .entity-table-sort {
          width: 16px;
          height: 16px;
          display: inline-block;
          margin-left: 5px;
        }
        &[title] {
          &:hover {
            cursor: pointer;
          }
        }
      }
      .td {
        color: $gray-100;
        @include typography(400, 14, 21px);
        &:first-child {
          color: $tertiary;
          @include typography(700, 14, 21px);
        }
        .entity-table-download {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          column-gap: 10px;
          row-gap: 1rem;

          @include media-breakpoint-down(md) {
            flex-wrap: wrap;
          }

          .download-group {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: 10px;
            row-gap: 1rem;
          }

          .dlc-version {
            text-align: center;
            padding-left: 20px;
            padding-right: 16px;
            white-space: nowrap;
          }

          .react-select__container {
            height: 36px;

            // language select
            &.dlc-language {
              min-width: 80px;
              max-width: 80px;

              .react-select__value-container {
                padding-left: 10px;
                &:hover {
                  cursor: pointer;
                }
              }
            }
            // version select
            &.dlc-revision {
              min-width: 100px;
              max-width: 100px;

              .react-select__control {
                border-bottom: 0 !important;

                &:hover,
                &:active,
                &:focus {
                  border: 0 !important;
                }
                .react-select__placeholder {
                  white-space: nowrap;
                  width: auto;
                }
                .react-select__single-value {
                  color: $gray-60;
                }
                .react-select__value-container {
                  overflow: visible;
                  &:hover {
                    cursor: pointer;
                  }
                }
              }

              &:hover {
                .react-select__control {
                  border: 0 !important;
                }
              }
            }
          }

          .btn {
            flex-shrink: 0;
            margin-right: 0;
          }

          .download-btn-desktop {
            display: none;
            @include media-breakpoint-up(md) {
              display: flex;
            }
          }

          .download-btn-mobile {
            @include media-breakpoint-up(md) {
              display: none;
            }
          }
        }
        .entity-table-categories {
          div {
            margin-bottom: 4px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .entity-table-document {
          display: flex;
          & > * {
            margin-right: 10px;
            &:last-child {
              margin-right: 0;
            }
          }
          svg {
            margin-right: 4px;
          }
        }
        .entity-table-title {
          display: grid;
          & > * {
            margin-right: 5px;
            &:last-child {
              margin-right: 0;
            }
          }
          .entity-table-title-description {
            font-weight: 400;
          }
          .entity-table-title-label {
            font-weight: 700;
          }
        }
        .entity-table-image {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      .thead {
        display: none;
      }

      .tr {
        display: block;
        max-width: 100% !important;
        min-width: 100% !important;

        .td {
          display: block;
          max-width: 100% !important;
          min-width: 100% !important;
          padding: 10px 20px;

          .entity-table-download {
            justify-content: space-between;
            align-items: center;

            .download-group {
              flex-grow: 1;
            }
          }
        }
      }
    }
  }
}

.filter-preview-wrapper {
  margin-top: -15px;
  margin-bottom: 30px;
  & > * {
    margin-bottom: 30px;
  }
  .filter-preview {
    &:hover {
      cursor: pointer;
    }
    .content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .filter-preview-input-wrapper {
        padding-top: 20px;
        .react-select__container,
        .search-filter {
          width: 100%;
        }
      }
    }
  }
}

.krohne-pick-banner {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  margin-bottom: 100px;
  .krohne-pick-background {
    width: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
    z-index: -1;
    margin: -30px;
  }
}
.event-slider {
  .swiper-slide {
    padding-right: 5px;
  }

  .event-slider-image {
    height: 40px;
    margin-bottom: 7.5px;
    img {
      object-position: left;
    }
  }
  .event-slider-title {
    margin-bottom: 0.5rem !important;

    @include truncate(3);
  }
}

.event-slider-date {
  color: $breadcrumb-active-color !important;
  display: flex;
  font-size: 14px !important;
  margin-bottom: 0.5rem !important;
}
.event-slider-title {
  color: $tertiary !important;
  font-weight: 700 !important;
  margin-bottom: 0px;
  @include truncate(3);
}
.event-slider-location {
  color: $breadcrumb-active-color !important;
  font-size: 14px !important;
  margin-bottom: 0.5rem !important;
  @include truncate(2);

  p {
    font-size: 14px !important;
  }
}
