.navigation {
  .card {
    width: 500px; // TODO: remove
  }
}

a.card-link-wrapper {
  width: 100%;
  text-decoration: none;

  .card-title {
    text-decoration: none !important;
  }
}

.card {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border: none;
  background-color: transparent;
  width: 100%;

  .text-uppercase .react-select__indicators {
    pointer-events: none;
  }

  p {
    @extend .medium;
  }
  ul {
    padding-left: 0;
    list-style: none;
    @extend p;
    font-weight: 400;
    li::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "\2022";
      color: $primary;
      font-weight: bold;
      display: inline-block;
      width: 22px;
    }
    li {
      padding-left: 16px;
      position: relative;
      margin-bottom: 10px;

      a {
        color: $primary;
        text-decoration: underline;
      }
    }
  }
  .card-title {
    color: $tertiary;
    margin-bottom: 0;
    transition: all 0.2s linear;
  }
  .card-body {
    padding: 10px 0;

    .content {
      div:first-child:not(.image-wrapper) {
        flex-grow: 1;
      }
      .styled-list-wrapper > ul > li > span {
        font-weight: unset !important;
      }
    }
  }

  .card-feature {
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    pointer-events: none;
    .image-wrapper {
      z-index: 1 !important;
      width: 100px !important;
      height: auto;
    }
  }
  .eyecatcher {
    position: absolute;
    top: 10px;
    left: -10px;
    white-space: nowrap;
  }
  .card-date {
    color: $breadcrumb-active-color;
  }
  .card-footer {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    > .btn {
      margin-right: 0;
    }
  }
  .product-label {
    position: absolute;
    background-color: $white;
    padding: 8px;
    bottom: 20px;
    &-position-left {
      left: 30px;
    }
    &-position-center {
      left: 50%;
      transform: translateX(-50%);
    }
    &-position-right {
      right: 30px;
    }
  }
  .card-dropdown {
    display: inline-block;
    color: black;
  }
  .card-description-image {
    margin-bottom: 10px;
  }
  &.vertical-outline {
    .card-body {
      box-shadow: 0px 3px 5px $box-shadow-color;
      padding: 20px;
    }
  }
  &.vertical {
    .card-body {
      display: flex;
      flex-direction: column;

      & > * {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &[class*="horizontal"] {
    padding-top: 0;
    padding-bottom: 30px;
    border-bottom: 1px solid $gray-20;
    flex-direction: row;
    justify-content: space-between;

    &.horizontal-outline {
      box-shadow: 0px 3px 5px $box-shadow-color;
      padding-bottom: 0;
      .card-image {
        padding: 0;
        margin: 0;
      }
      .card-body {
        padding: 20px;
      }
    }

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      padding-bottom: 18px;
    }

    .card-image {
      width: 20%;
      margin-right: 25px;

      @include media-breakpoint-down(md) {
        padding-bottom: 12px;
        width: 40%;
      }
      @include media-breakpoint-down(sm) {
        margin-right: 15px;
      }
    }
    .card-body {
      max-width: calc(60% - 25px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      & > * {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      @include media-breakpoint-down(md) {
        padding-top: 0;
      }
      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        max-width: calc(60% - 15px);
      }
    }
    .card-footer {
      padding: 0.75rem 1.25rem;
      padding-right: 0;
      margin-top: 5px;
      width: 20%;
      text-align: right;

      @include media-breakpoint-down(md) {
        padding-left: 0;
        margin-top: 0;
        padding-top: 0;
        width: 100%;
        text-align: left;
        &.inline-actions {
          margin-top: 10px;
          margin-top: 10px;
        }
      }
    }
  }

  &.horizontal-thin {
    display: flex;
    align-items: center;
    padding-bottom: 0;
    background-color: $white;
    height: 100%;
    min-height: 120px;
    max-height: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .image-text-wrapper {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .card-image {
      display: flex;
      width: unset;
      max-width: 25%;
      height: 100% !important;
      padding-bottom: 0;
      margin-right: 0;
      margin-left: 15px;
    }

    .card-body {
      max-width: unset;
      margin-left: 15px;
    }

    .card-footer {
      margin-right: 15px;
      width: auto;
    }

    @include media-breakpoint-down(md) {
      flex-wrap: nowrap;
      padding-bottom: 0;
      padding-top: 0;

      .card-footer {
        min-width: 10%;
      }
    }
  }

  // card hover
  &:not(.no-hover):hover {
    .card-image {
      &:hover {
        cursor: pointer;
      }
      .image-wrapper {
        overflow: hidden;
        img {
          transform: scale(1.03);
        }
      }
    }
    .card-title {
      color: $primary;
      text-decoration: underline;
      cursor: pointer;
    }
    .btn {
      &.btn-white {
        background-image: linear-gradient(
          98deg,
          $white-highlight 0%,
          $white-highlight 32px,
          $white 33px
        );
        .svg {
          left: 12px;
        }
        &:before {
          opacity: 1;
          background-image: linear-gradient(to right, $white, $white-highlight);
        }
      }
      &.btn-primary {
        background-image: linear-gradient(
          98deg,
          $primary-highlight 0%,
          $primary-highlight 32px,
          $primary 33px
        );
        .svg {
          left: 12px;
        }
        &:before {
          opacity: 1;
          background-image: linear-gradient(
            to left,
            $primary,
            $primary-highlight
          );
        }
      }
    }
  }
}

.card-image {
  position: relative;
  .image-wrapper {
    overflow: hidden;
    img {
      transition: all 0.6s ease;
      object-position: left;
    }
  }
  &.img-height {
    height: 70px;
  }
  &.lightbox-video,
  &.lightbox-image {
    cursor: zoom-in;
    &:hover {
      .svg {
        background-color: transparent;
        cursor: pointer;
      }
    }
    .svg {
      content: " ";
      background-color: rgba(34, 34, 34, 0.25);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: background-color 1s;
      cursor: zoom-in !important;
    }
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

// Smaller font for cards in small tiles view
.view__small_tiles {
  .card-title {
    font-size: 20px;
  }
  .content {
    font-size: 14px;
  }
}

.col-xl-3 .card {
  .card-image {
    &.img-height {
      height: 100px;
    }
  }
}
// contact
.contact-item {
  .label {
    margin-left: 10px;
  }
}

.component__contact {
  .card {
    background-color: $white;
    color: #212529;
    border-radius: 0.25rem;
    overflow: hidden;
    border-bottom: none;
    height: auto !important;
    margin-top: 2rem;
    margin-bottom: 4rem;

    &.no-title {
      margin-top: 4rem;
    }

    &.use-padding {
      .card-image {
        padding: 1rem 0 1rem 1rem;
      }
    }

    &.hide-shadow {
      box-shadow: none;
    }

    .card-body {
      max-width: none;
    }

    .image-wrapper img {
      border: none !important;
    }
  }
}

.language-card {
  display: flex !important;

  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-height: 56px !important;
  max-height: 56px !important;
}

.language-card .card-body {
  height: 100% !important;
  justify-content: center !important;
}

.language-card .card-body .card-title {
  margin-bottom: 0px !important;
}

.language-card .card-body .content {
  height: 0px !important;
}

.language-card .card-image {
  aspect-ratio: 1/1;
  margin-right: 0px !important;

  @include media-breakpoint-down(sm) {
    height: 36px !important;
    width: 36px !important;
  }
}

.language-card .card-footer {
  padding-bottom: 0px !important;
}

.contact-card {
    .card-body {
        display: flex;
        flex-direction: row;
    }

    .contact-info {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        max-width: calc(100% - 120px);
        hyphens: auto;
    }

    .contact-image {
        width: 100px!important;
        height: 100px!important;

        img {
            border-radius: 50%;
        }
    }

    .contact-title {
        font-weight: 700;
        margin-bottom: 5px;
    }

    .contact-excerpt {
        margin-bottom: 10px;
    }

    .icon-text {
        display: flex;
        align-items: flex-start;
        margin-bottom: 8px;
        color: $primary;
        font-weight: 600;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }

        span.svg {
            margin-right: 5px;
            margin-top: 3px;
        }

        address {
            margin-bottom: 0;
        }
    }
}
