.sticky-conversion {
  position: fixed;
  z-index: 21;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  .sticky-conversion-item {
    height: 50px;
    background: $white;
    color: $tertiary;
    box-shadow: $box-shadow;
    border-bottom: 1px solid $white-highlight;
    transition: width 0.4s ease;
    position: relative;
    &:hover {
      cursor: pointer;
      .sticky-conversion-item-text {
        opacity: 1;
      }
    }
    &:last-child {
      border-bottom: none;
    }
    .sticky-conversation-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      overflow: hidden;
      @include media-breakpoint-down(sm) {
        display: block;
        padding: 0;
        overflow: visible;
      }
    }
    .sticky-conversion-item-text {
      margin-left: 10px;
      opacity: 0;
      transition: opacity 0.4s ease;
      @include typography(400, 14, 21px);
      white-space: nowrap;
    }

    @include media-breakpoint-down(sm) {
      background: linear-gradient(90deg, $primary-highlight 0%, $primary 100%);
      box-shadow: 0px 3px 5px $box-shadow-color;
      border-bottom: 0;
      border-radius: 25px;
      padding-top: 12px;
      svg {
        fill: white;
      }
      &:hover {
        .sticky-conversion-item-text {
          opacity: 0;
        }
      }
      .sticky-conversation-content {
        padding: 0;
        margin-top: -2px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    top: auto;
    position: fixed;
    bottom: -15px;
    right: 10px;
  }
}
