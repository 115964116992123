.web-app {
  position: absolute;
  top: 37px;
  top: var(--meta-height);
  left: 26px;
  width: 362px;
  background-color: white;
  box-shadow: 0px 3px 5px $box-shadow-color;
  padding-top: 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid $white-highlight;
  z-index: 999;

  img {
    margin-left: 21px;
    margin-top: -2px;
  }

  .app-wrapper {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    gap: 9px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    .app {
      display: flex !important;
      justify-content: center;
      align-items: center;
      aspect-ratio: 1/1;
      min-height: 90px;
      border: 1px solid $white-highlight;
      background-color: white;
      cursor: pointer;
      margin-right: 9px;
      margin-bottom: 9px;
      width: 90px;

      h3 {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
        color: $tertiary;
        font-weight: 700;
        text-align: center;
        margin-top: 8px;
      }

      .icon {
        width: 14px;
        height: 14px;
        background-color: $white-highlight;
        display: block;
        margin: auto;
        margin-bottom: 8px;
      }

      &:hover {
        background-color: $white-highlight;
        transition: all 0.6s ease;

        h3 {
          color: $primary;
        }

        .icon {
          background-color: $primary;
          color: $primary;
          transition: all 0.6s ease;
        }
      }
    }

    @supports (display: grid) {
      display: grid;
      flex-wrap: unset;

      .app {
        margin-right: 0;
        margin-bottom: 0;
        width: auto;
      }
    }
  }

  .app-footer {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 6px;
    border-top: 1px solid $white-highlight;

    a {
      color: $tertiary;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
        color: $primary;
      }
    }

    .svg {
      svg {
        fill: $tertiary;
      }
    }
  }

  &.active {
    display: block;
    height: auto;
    z-index: 4000;
  }

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    top: -8px;
    left: 22px;
    z-index: 5;
  }
  &.web-app-mobile {
    position: relative;
    height: 100%;
    width: 100%;
    left: unset !important;
    top: unset !important;
    border-radius: unset;
    padding: unset;
    z-index: 99999;

    &:before {
      content: none;
    }

    .app-wrapper {
      padding: 20px;
    }

    .web-app-wrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: white;
      .nav {
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto;
        background-color: $primary-highlight;
        padding: 0;
        display: block;
        position: relative;
        .container {
          position: relative;
          .nav-item {
            a {
              color: $white;
              .svg {
                left: 0;
              }
            }
          }
          .svg {
            &.caret-down {
              position: absolute;
              top: calc(50% - 8px);
              right: 15px;
            }
          }
        }
      }
      .app-wrapper {
        @extend .container;
        min-height: 155px;
        margin-top: 20px;
      }
    }
  }
}
