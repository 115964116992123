.bullet-point-list,
.styled-list-wrapper {
  @include unstyled-list();

  li {
    @extend .h6;
    padding-left: 24px;
    position: relative;

    &::before {
      position: absolute;
      top: -2px;
      left: 8px;
      content: "\2022";
      color: $primary;
    }

    a {
      color: $primary;
      text-decoration: underline;
    }
  }

  ul ul {
    li {
      padding-left: 40px;

      &::before {
        left: 24px;
      }
    }
  }
}

.component__content-set .styled-list-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 10px;

  ul {
    margin-bottom: 0;
  }
}

// adjustments for Landingpages LAS - contentset view
.component__content-set.contentset-fullscreen {
  min-height: calc(100vh - var(--meta-height) - var(--header-height) + 9px);
  .card-image {
    .image-wrapper img {
      object-position: center;
      &.cld-responsive {
        object-fit: cover;
      }
    }
  }
}

.m--bullet-point-list > * {
  @extend .bullet-point-list;
}

.arrow-list,
.check-list {
  @include unstyled-list();

  li {
    margin: 5px 0;
    // min-height: 1.75em; /* the bold variant of the font has a greater x-height which is moving the margins and stuff ... */
    display: flex;
    align-items: center;
  }

  li > a {
    display: flex;
    align-items: center;
  }

  li > a:hover,
  li > a.active {
    text-decoration: none;
  }

  li > a:hover > span,
  li > a.active > span {
    color: $primary;
    font-weight: bold;
  }

  li > a:hover .svg svg,
  li > a.active .svg svg {
    fill: $primary;
  }
  .svg {
    margin: 0 1em;
  }
}
.check-list {
  li {
    margin-top: 0.5rem;
    align-items: flex-start;
    .svg {
      @extend .mt-1;
    }
  }
}
.arrow-list .svg {
  height: 0.7em;
  width: 0.7em;
}

ul.list-inline.list-styled {
  margin-left: 2px;
  &.narrow {
    margin-bottom: inherit;
    li {
      margin-bottom: 16px;
    }
  }
  &.normal {
    p {
      @include media-breakpoint-down(xs) {
        font-size: 18px;
      }
    }
  }
  &.medium {
    li {
      margin-bottom: 30px;
    }
  }
  li {
    margin-bottom: 60px;
    padding-left: 30px;
    align-items: center;
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }

    &.icon {
        p {
            &:before {
                display: none;
            }
            span.svg {
                align-items: flex-start!important;
            }

            svg {
                top: 8px;
            }
        }

    }

    p {
      &:before {
        content: "";
        -moz-transform: rotate(($base-angle) + deg);
        -ms-transform: rotate(($base-angle) + deg);
        -o-transform: rotate(($base-angle) + deg);
        -webkit-backface-visibility: hidden;
        -webkit-background-clip: content-box;
        -webkit-transform: rotate(($base-angle) + deg);
        backface-visibility: hidden;
        background-clip: content-box;
        background-color: $primary;
        bottom: 0;
        height: 100%;
        left: 0;
        padding: 1px;
        position: absolute;
        transform: rotate(($base-angle) + deg);
        width: 4px;
        will-change: transform;
      }
    }
    &.list-inline-item {
      p {
        margin-bottom: 0;
      }
    }
    &.list-inline-item:not(:last-child) {
      margin-right: 0;
    }
  }
}

.component__styled-list {
  &.bg-primary-gradient,
  &.bg-secondary {
    ul.list-inline.list-styled {
      p {
        &:before {
          background-color: $white;
        }
      }
    }
  }
}

// news list homepage
.news-list {
  hr {
    margin-top: 18px;
    margin-bottom: 18px;
    border-color: $gray-20;
  }
}
