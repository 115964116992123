html {
  scroll-behavior: smooth;
  min-height: 100vh;
}

html.normal-scroll,
html.nprogress-busy {
  scroll-behavior: auto;
}

.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
