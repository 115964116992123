@import "../variables";

.tr--gray {
  background-color: $gray-10 !important;
}

.tr--white {
  background-color: white !important;
}

.revision-row {
  display: block !important;
  overflow-y: hidden;
  transition: max-height 400ms ease-in-out;

  &--collapsed {
    border-bottom: none !important;
  }

  .revision-container {
    padding: 0px 15px 15px 15px;

    .revision-table-wrapper {
      transition: height 400ms ease-in-out;
      overflow: hidden;
    }
  }
}

.revision-table {
  width: 100%;
  table-layout: fixed;
  overflow-y: hidden;

  thead {
    width: 100%;
  }
  th {
    border-top: none;
    border-bottom: 1px solid $gray-20;
    width: auto;
    font-weight: 400;
    padding-top: 8px;
    padding-bottom: 8px;
    white-space: nowrap;
    text-align: center;

    &:first-child {
      position: sticky;
      left: 0;
    }
  }
  tbody {
    display: table-row-group;
  }
  tr:last-child {
    border-bottom: 1px solid $gray-20;
  }
  td {
    border: none;
    width: auto;
    padding: 7px;
    min-width: 50px;
    background-color: inherit;

    &:first-child {
      position: sticky;
      left: 0;
      padding-left: 8px;
      padding-right: 32px;
      white-space: nowrap;
      z-index: 1;

      @include media-breakpoint-down(lg) {
        padding-right: 8px;
      }
    }

    &:last-child {
      width: 100%;
    }

    a {
      margin-right: 0px;
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  &--white {
    th {
      background-color: white;
    }

    tr:nth-child(even) {
      background-color: white;
    }

    tr:nth-child(odd) {
      background-color: $gray-10;
    }
  }

  &--gray {
    th {
      background-color: $gray-10;
    }

    tr:nth-child(even) {
      background-color: $gray-10;
    }

    tr:nth-child(odd) {
      background-color: white;
    }
  }
}
