.page-content > .section + .section {
  margin-bottom: 6rem;
  margin-top: 6rem;

  @include media-breakpoint-down(sm) {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
}

// hows the rule here?
.header-overview + .container__section,
.page-content > .container__section:first-child:not(.component__slider) {
  padding-top: 50px;
}

// adjustments for landingpages (LAS - Liquid contentsets)
.page-content
  > .container__section.contentset-fullscreen:first-child:not(
    .component__slider
  ) {
  padding-top: 15px;
}

.root .page-content > .container__section.detailprocess__header {
  padding-top: 0; // :not does not work?
}

.root .page-content > .card-navigation-wrapper {
  @extend .container;

  @include media-breakpoint-up(md) {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 100% !important;
  }
}

.container__section {
  position: relative;

  &.bg-primary-gradient {
    $list: $primary, $primary-highlight;
    @include gradient(to left, $list);
  }
  div:not(.heading):not(> .content-right) {
    .btn {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .content-right {
    .btn {
      margin-right: 0;
    }
  }

  .d-block {
    overflow-x: hidden;
  }

  @include media-breakpoint-down(sm) {
    .container .col-md-12,
    .container-fluid .col-md-12 {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }

    &.component__slider {
      .container .col-md-12,
      .container-fluid .col-md-12 {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
  &.component__slider {
    .container .col-md-12,
    .container-fluid .col-md-12 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &.component__image-text {
    &.quotes .quote {
      align-items: center;
      .blockquote {
        margin-bottom: 0;
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      .row {
        flex-direction: column-reverse;
        align-content: center;

        .heading,
        .btn {
          align-self: center;
        }

        p {
          text-align: center;
        }
        .image-wrapper {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }

  &.component__media-text {
    &.quotes .quote {
      align-items: center;
      .blockquote {
        margin-bottom: 0;
        p:last-child {
          margin-bottom: 0;
        }
      }

      span.play-circle {
        border-radius: 50%;
      }
    }

    .card-image.lightbox-video {
        figure {
            margin-right: 0;
          }
    }

    @include media-breakpoint-down(xl) {
        .card-image.lightbox-video {
            margin-right: 0;
        }
    }

    @include media-breakpoint-down(sm) {
      .row {
        flex-direction: column-reverse;
        align-content: center;

        .media-wrapper {
            justify-content: center;
        }

        .card-image.lightbox-video {
            margin-bottom: 30px;

            .image-wrapper {
                margin: 0;
            }
        }

        .heading,
        .btn {
          align-self: flex-start;
        }

        p {
          text-align: center;
        }
        .image-wrapper {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }

  &.styled-list-wrapper .row p {
    text-align: left;
  }
}

// dont allow brackground colors inside tabs element
.component__tabs {
  .info-body {
    .bg-gray,
    .bg-primary,
    .bg-secondary {
      background: none;
    }

    .container__section--border {
      padding: 0;
      margin: 0;
    }

    .container__section--border::before,
    .container__section--border::after {
      display: none;
    }

    .container__section + .container__section {
      margin-top: 50px;
    }
  }

  // add padding-bottom for tab-elements with bg-color because of overlapping box-shadow
  &.bg-gray {
    padding-bottom: 24px;
  }
}

.container__section--border {
  &::after,
  &::before {
    content: "";
    height: 50px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
    pointer-events: none;
  }

  &.m--bottom::after {
    top: 100%;
    clip-path: polygon(100% 100%, 0% 100%, 50% 50%, 100% 0%);
  }

  &.m--top::before {
    bottom: 100%;
    clip-path: polygon(0% -1%, 100% -1%, 50% 50%, 0% 100%);
  }

  &.m--bottom {
    padding-bottom: 75px;
    margin-bottom: 65px;
    z-index: 1;
    &.m--bottom::after {
      margin-top: -50px;
      background-color: white;
    }
  }

  &.m--top {
    padding-top: 75px;
    margin-top: 65px;
    z-index: 1;
    &.m--top::before {
      margin-bottom: -50px;
      background-color: white;
    }
  }
}

.heading {
  margin-top: 20px;
}
// headings for events

.heading-wrapper,
.heading {
  @include media-breakpoint-down(sm) {
    display: block !important;

    .content-right .btn,
    .btn {
      margin-top: 0 !important;
    }
  }

  &.justify-content-between.align-items-center {
    text-align: left;
  }

  &.align-items-center {
    text-align: center;
  }

  &.border-bottom {
    @include media-breakpoint-down(sm) {
      padding-bottom: 20px;
    }
  }
}

.heading-wrapper {
  &.col-xl-3,
  &.col-lg-3,
  &.col-md-3 {
    .heading {
      padding-right: 25px;
    }
  }

  .content-right {
    .btn {
      margin-top: 20px;
    }
  }
}

.heading .content-right.align--baseline {
  align-self: baseline;
}

.dp-recommended .heading,
.slider__related-products-wrapper .heading {
  display: flex !important; // force always flex ...
}

.container {
  .component__images {
    margin-bottom: 1rem;

    .container {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}
