.search-bar {
  width: 420px;
  display: flex;
  justify-content: space-between;
  border: 2px solid $primary;
  padding: 15px;
  .svg.search {
    width: 16px;
  }
  input {
    border: none;
    background-color: transparent;
  }
  &.primary {
    border-color: $primary-highlight;
    color: $white;
    input,
    input::placeholder {
      color: $white;
      outline: 0;
    }
  }
}

.search-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > div {
    padding-left: 9px;
    padding-right: 9px;
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }
  &:hover {
    cursor: pointer;
  }
  .search-wrapper {
    .search-lightbox-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
        transform: translateY(-10%);
      & > * {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .search-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        h3 {
          color: $gray-100;
          margin-bottom: 10px;
        }

        p {
          color: $gray-100;
        }
      }
      > .react-select__container {
        height: 50px;
        box-shadow: $box-shadow;
        .react-select__control {
          border: 1px solid $white-highlight;
        }
        .react-select__menu {
          box-shadow: $box-shadow !important;
          border: none;
          margin-top: 0px;
          > * {
            height: auto !important;
          }
          .react-select__menu-list {
            border: none;
            .custom-option.react-select__option {
              &:hover {
                color: $primary;
                background: $white-highlight !important;
              }
              &--is-selected {
                color: $primary;
                background: $white-highlight !important;
              }
            }
          }
        }
      }
      .search-pick-banner {
        display: flex;
        width: 660px;
        max-width: 100%;
        margin-top: 50px;
        position: relative;
        overflow: hidden;
        .search-pick-banner-content {
          padding: 30px;
          padding-right: 50px;
          color: $white;
          background: linear-gradient(
            278deg,
            transparent 0%,
            transparent 42px,
            $primary 43px,
            $primary-highlight 100%
          );
          z-index: 1;
          width: 60%;
          h5 {
            margin-bottom: 10px;
          }
          p {
            margin-bottom: 20px;
          }
        }
        .search-pick-banner-image {
          position: absolute;
          right: 0;
          height: 100%;
          width: 68%;
        }
      }
      .search-pick-mobile {
        @include typography(400, 14, 21px);
        color: $tertiary;
        position: absolute;
        bottom: 0;
        margin-bottom: 40px;
      }
    }

    @include media-breakpoint-down(md) {
      display: flex;

      .open-mm {
        display: block;
      }
    }

    @include media-breakpoint-down(sm) {
      .lightbox .lightbox-window .lightbox-heading {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .search-header {
        text-align: center;
      }

      .lightbox-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .css-hiev8a-container {
          width: 100%;
        }
      }
    }

    &:hover {
      .svg svg {
        fill: $primary;
      }
    }
  }
}
