// ONLY FOR STORIES
.story-shape-wrapper {
  display: flex;
  flex-wrap: wrap;
  .story-shape {
    padding: 10px;
    border: 1px solid $gray-40;
    margin-bottom: 20px;
  }
}
