.action-dropdown {
  flex: 1;

  .react-select__control {
    background: $white;
    margin: 0;
    border-radius: 0;
    font-size: 14px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__single-value {
    color: $tertiary;
  }

  .react-select__indicator {
    padding: 8px 4px;
  }
}
