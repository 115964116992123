.title {
  @media (max-width: sm) {
    font-size: 30px;
    font-weight: 300;
    line-height: 39px;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
