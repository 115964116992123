@import "../variables";

table {
  font-size: 14px;
}
.table,
table {
  display: block;
  overflow: auto;
  p {
    margin-bottom: 0;
  }
  tbody {
    display: table;
    width: 100%;
  }

  th {
    background-color: mix($gray-10, $gray-20, 50%);
    border-top: 1px solid $gray-20;
    padding: 0.75rem;
    p {
      font-weight: 700;
      text-align: center !important;
    }
  }
  td {
    padding: 0.75rem;
    border-top: 1px solid $gray-20;
    &.first-cell {
        width: var(--col-width);
    }
  }
}
.table-component {
  table {
    max-width: 100%;
  }
}
