footer {
  box-shadow: -5px 0px 12px $box-shadow-color;
  padding-top: 50px;

  .copyright-text,
  .copyright-text a {
    padding-bottom: 1rem;
    margin-bottom: 0;
    text-align: center;
    color: white;
    font-size: 14px;
  }
  .sitemap {
    padding-bottom: 24px;

    .sitemapItem {
      margin-bottom: 32px;
    }

    p {
      font-size: 14px;
      line-height: 21px;

      &.h6 {
        font-size: 16px;
      }
    }

    .h6 {
      color: $tertiary;
      margin-bottom: 7px;
      word-break: break-word;
      font-weight: 700;
    }

    nav {
      .nav-link {
        font-size: 14px;
        line-height: 21px;
        word-break: break-word;
        padding-left: 0;
        padding-top: 0.33rem;
        padding-bottom: 0.33rem;
        padding-right: 1rem;
        color: $tertiary;

        &:hover {
          text-decoration: underline;
          color: $primary;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      [class*="col-"] {
        margin-bottom: 30px;
      }
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 5px;

      [class*="col-"] {
        display: none;
        text-align: center;
        margin-bottom: 0;

        &:first-child {
          display: block;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .copyright {
    background-color: $primary;

    @include media-breakpoint-down(sm) {
      padding-bottom: 50px !important;
    }

    &__highlight {
      background-color: $primary-highlight;

      @include media-breakpoint-down(lg) {
        padding-bottom: 20px !important;
        padding-top: 20px !important;
      }

      .container {
        > div {
          @include media-breakpoint-down(lg) {
            flex-direction: column;
            align-items: start !important;
          }
        }
      }
    }

    &:not(&__highlight) {
      img {
        filter: brightness(0) invert(1);
        @include media-breakpoint-down(lg) {
          height: 64px;
          width: auto !important;
        }
      }
    }

    .nav {
      .nav-link {
        color: white;
        font-size: 14px;
        line-height: 21px;
      }

      .language-icon {
        .nav-link {
          padding: 0.5rem 24px;
          &:focus,
          &:active {
            box-shadow: none;
          }
        }
      }

      :not(.language-icon) {
        .nav-link {
          padding: 0.5rem 0;
        }
      }
    }

    .version {
        display: flex;
        font-size: 12px;
        color: $primary-highlight;
        margin: 0;
        padding: 0;
        overflow: hidden;

        @include media-breakpoint-down(lg) {
            display: none;
          }
      }

    .svg {
      &:after {
        content: "";
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: -10px;
        right: -10px;
      }
    }

    @include media-breakpoint-down(lg) {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

  .footer__related-links {
    display: flex;
    justify-content: flex-end;

    a {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }

    svg {
      display: inline-block;
    }

    @include media-breakpoint-down(lg) {
      justify-content: center;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-top: 0;
    .accordion {
      .svg {
        position: relative;
        top: unset;
        left: unset !important;
        transform: unset;
        svg {
          transform: rotate(0deg);
          transition: transform 0.2s;
          fill: $gray-40;
        }
      }

      .card-header {
        border-bottom: 1px solid $white-highlight;

        .btn-link:focus {
          text-decoration: none;
        }

        .opened {
          background: $white-highlight;

          p {
            color: $primary;
            text-decoration: none;
          }
          .svg {
            svg {
              transform: rotate(180deg);
              fill: $primary;
            }
          }
          .button,
          .btn {
            color: $primary;
          }
        }
        .btn {
          font-size: 16px;
          line-height: 24px;
          color: $tertiary;
          border: none;
          border-top: 1px solid $white-highlight;
          box-shadow: none !important;
          p {
            font-size: 16px;
            margin-top: 13px;
            margin-bottom: 13px;
            &:hover {
              text-decoration: none;
              color: $tertiary;
            }
          }
          &:hover {
            text-decoration: none;
            color: $tertiary;
          }
        }
      }
      .card-body {
        opacity: 0;
        transition: opacity 0.15s;
        padding-top: 15px;
        padding-bottom: 15px;
      }
      .show {
        .card-body {
          opacity: 1;
        }
      }
    }
  }
}

@media all and (display-mode: fullscreen) {
  .fullscreen-only-interactive-map footer {
    display: none;
  }
}
