.form-check {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  .form-check-input {
    display: none;
  }
  .form-check-custom {
    min-width: 18px;
    min-height: 18px;
    border: 1px solid $gray-40;
    position: relative;
    &:hover {
      cursor: pointer;
    }
    &.radio {
      border-radius: 50%;
    }
    &.checked {
      &.radio {
        .svg {
          border-radius: 50%;
          overflow: hidden;

          svg {
            border-radius: 50%;
          }
        }
      }
      .svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .form-check-label {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    color: $tertiary;
    padding-left: 10px;
    &:hover {
      cursor: pointer;
    }
    .form-check-option-label {
      margin-right: 10px;
    }
    .form-check-label-support {
      color: $gray-60;
      display: inline;
      display: flex;
      align-items: center;
      margin-top: -2px;
      > .loading-wrapper {
        width: unset;
        margin-right: 3px;
      }
    }
  }
}
