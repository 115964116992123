@import "variables";

@mixin center-absolute() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin transition($property, $speed: $transition-speed) {
  transition-duration: $speed;
  transition-property: $property;
}

@mixin gradient($direction, $list) {
  background-image: -webkit-linear-gradient($direction, $list);
  background-image: -moz-linear-gradient($direction, $list);
  background-image: -o-linear-gradient($direction, $list);
  background-image: linear-gradient($direction, $list);
}

@mixin angle($color, $bgcolor, $angle) {
  background-image: linear-gradient(
    ((90 + $angle) + deg),
    $color 0%,
    $color 32px,
    $bgcolor 33px
  );
  background-image: -moz-linear-gradient(
    ((90 + $angle) + deg),
    $color 0%,
    $color 32px,
    $bgcolor 33px
  );
  background-image: -o-linear-gradient(
    ((90 + $angle) + deg),
    $color 0%,
    $color 32px,
    $bgcolor 33px
  );
  background-image: linear-gradient(
    ((90 + $angle) + deg),
    $color 0%,
    $color 32px,
    $bgcolor 33px
  );
}

@mixin button($name, $color, $color-highlight, $direction) {
  $list: $color, $color-highlight;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 0;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: opacity $transition-speed linear;
    opacity: 0;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
  }
  &-#{$name}-border {
    border: solid 1px $color-highlight;
  }
  &-#{$name} {
    &::before,
    &-outline::before {
      @include gradient($direction, $list);
    }
    color: $white;
    @if $name == "white" {
      color: $primary;
      border: 1px solid $white-highlight;
    }
    @include angle($color-highlight, $color, $base-angle);

    &-outline {
      @include angle($color, transparent, $base-angle);
      border: 1px solid $color;
      color: $color;
    }

    &:hover,
    &-outline:hover {
      color: $white;
      @if $name == "white" {
        color: $primary;
      }
    }
  }
}

@mixin unstyled-list() {
  padding-left: 0;
  margin-bottom: 0;
  text-decoration: none;
  list-style-type: none;
  ul {
    padding-left: 0;
    text-decoration: none;
    list-style-type: none;
  }
}

// Truncate text after number of lines
@mixin truncate($lines) {
  display: -webkit-box !important;
  -webkit-line-clamp: $lines !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}
