.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 100%;
  background: rgba($black, 0.6);
  z-index: 100000;
  &:hover {
    .content-right {
      cursor: pointer;
      .svg.close-big svg {
        fill: white !important;
      }
    }
  }

  transition-property: opacity transform;
  transition-duration: $transition-speed;
  transition-timing-function: ease;
  transform: translateY(100%);

  &-entering,
  &-entered {
    transform: translateY(0);
  }

  &-small {
    opacity: 0;
    transform: none;
  }

  &-small.lightbox-entering,
  &-small.lightbox-entered {
    opacity: 1;
  }

  &-noAnimation {
    transition-duration: 0ms;
  }

  // _icons.scss:15 ...
  .content-right {
    .svg.close-big {
      &:hover svg {
        fill: white !important;
      }
    }
  }
  .lightbox-window {
    position: absolute;
    display: flex;
    flex-direction: column;
    &.small {
      left: 50%;
      max-height: 96vh;
      height: 100%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 1380px;
      width: 100%;
      padding: 0 15px;
      will-change: transform;

      .lightbox-background {
        left: 15px !important;
        right: 15px !important;
        width: unset !important;
      }
    }
    &.large {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    &:hover {
      cursor: default;
    }
    .lightbox-heading {
      padding: 30px;
      &.primary {
        background: $primary;
        color: $white;
      }
      &.secondary {
        background: $secondary;
        color: $white;
      }
      &.tertiary {
        background: $tertiary;
        color: $white;
      }
      &.white {
        background: $white;
        color: $dark;
      }
      .heading {
        display: flex !important;
        margin-top: 0;
        > div:first-child {
          flex: 1;
        }
        > * {
          display: inline;
        }
        .h1,
        h1,
        .h2,
        h2,
        .h3,
        h3,
        .h4,
        h4,
        .h5,
        h5 {
          margin: 0;
        }
        .lightbox-close {
          &:hover {
            cursor: pointer;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        padding: 16px;
      }
    }
    .lightbox-content {
      position: relative;
      padding: 30px;
      flex-grow: 1;
      overflow-y: auto;
      // Hide scrollbar
      // -ms-overflow-style: none;
      // scrollbar-width: none;
      &::-webkit-scrollbar {
        // display: none;
      }
      @include media-breakpoint-down(sm) {
        padding: 30px 15px;
      }

      .lightbox-navigation {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 2;

        &__prev {
          left: 2rem;
        }

        &__next {
          right: 2rem;
        }
      }

      &.white {
        background: $white;
      }
      > .image-wrapper {
        position: relative;
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        height: 100%;
        min-height: 100%;

        > span:first-child {
          width: 100% !important;
          height: 100% !important;

          .image-fill {
            position: absolute !important;
            width: 100% !important;
            height: 100% !important;
            object-fit: contain;
          }
        }
      }
      .container__section {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-items: center;

        .container .cloudinary-context {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 85vh;
          max-width: 100%;

          .cloudinary-context-video {
            width: auto;
            margin-top: 0;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      .lightbox-background {
        position: fixed;
        min-height: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        background-color: $gray-40;

        img {
          height: 100%;
          object-fit: cover !important;
        }

        > span:first-child {
          position: absolute !important;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }

      .service-form-wrapper {
        height: 100%;
      }
    }
  }

  .tab-content {
    @include media-breakpoint-down(sm) {
      .formio-control-buttons {
        box-shadow: 0px -3px 5px $box-shadow-color;
        background-color: white;
        z-index: 25;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 15px;
      }

      .formio-component-columns {
        display: block;

        .col {
          max-width: unset;
        }
      }
    }
  }
}

.locale-switcher .lightbox-window .lightbox-content {
  .container__section {
    align-items: flex-start;
    justify-items: center;
  }
}

body.lightbox-opened {
  position: fixed !important;
  overflow-y: scroll !important;
  padding-right: 0 !important;
  width: 100%;

  @include media-breakpoint-down(sm) {
    position: static !important;
    overflow: hidden !important;
  }
}

.confirmation-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;

    .confirmation-popup-wrapper {
        .header {
            background: $primary;
            color: $white;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: bold;
            padding: 0 20px;

            .svg svg {
                fill: $white!important;
            }
        }
        .content {
            background: $white;
            padding: 20px;
            text-align: center;
        }
        .button-wrapper {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;

            button {
                margin: 0 5px;
            }
        }
    }
  }
