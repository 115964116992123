.component__video {
  &__wrapper {
    position: relative;

    &__labels {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      @include media-breakpoint-down(md) {
        position: static;
      }

      &__label {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @include media-breakpoint-down(md) {
          position: static;
          justify-content: flex-start;
          padding: 12px 0;
        }

        .svg {
          background-color: $white;
          border-radius: 50%;

          @include media-breakpoint-down(md) {
            svg {
              width: 24px !important;
              height: 24px !important;
            }
          }
        }

        p {
          margin-left: -12px;
          margin-bottom: 0;
          background-color: $white;
          padding: 4px 16px 4px 20px;
          box-shadow:
            $box-shadow-color 0px 20px 25px -5px,
            rgba($black, 0.04) 0px 10px 10px -5px;

          @include media-breakpoint-down(md) {
            margin-left: 0;
            background-color: transparent;
            padding: 4px 12px;
            box-shadow: none;
            flex-grow: 1;
          }
        }
      }
    }

    .image-wrapper {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}
