// Country forwarding banner
.country-forwarding-select {
  max-height: 36px;
  .react-select__control {
    min-height: 36px !important;
  }
  :hover {
    cursor: pointer !important;
  }
  &.react-select__container.has_value {
    .react-select__control {
      border-bottom: none !important;
    }
    &:hover {
      .react-select__control {
        border: none !important;
      }
    }
  }
  .react-select__single-value {
    color: $dark-blue !important;
  }
  .react-select__control {
    border: none !important;
  }
  .react-select__indicator {
    padding-right: 8px !important;
  }
  .react-select__value-container {
    padding-left: 6px !important;
    .image-wrapper {
      width: 18px !important;
      height: 18px;
    }
  }
}
// Country forwarding banner menu
.country-forwarding-select-menu {
  .image-wrapper {
    width: 18px !important;
    height: 18px;
  }
  &.react-select__menu {
    .react-select__menu-list {
      > div {
        .react-select__group {
          height: 0px;
          .react-select__group-heading {
            display: none;
          }
        }
        :nth-child(3) {
          .react-select__group {
            border-bottom: 1px solid $gray-20;
          }
        }
      }
      .custom-option.react-select__option {
        padding-left: 8px;
        .image-wrapper {
          width: 18px !important;
          height: 18px;
        }
      }
    }
  }
}
#country-forwarding {
  .stay-on-btn {
    text-decoration: underline;
  }
}

// Meta menu
.meta {
  @media all and (display-mode: fullscreen) {
    display: none;
  }

  .meta-bar {
    background-image: linear-gradient(
      90deg,
      $primary 0%,
      $primary 50%,
      $primary-highlight 50%
    );
    .col {
      background-color: $primary;
      &:first-child {
        padding-right: 0;
      }
      &:last-child {
        padding: 0;
      }
    }
    .nav {
      flex-wrap: nowrap;
      .nav-link {
        @extend .p, .small;
        color: $white;
        margin-bottom: 0;
        padding: 0.5rem 0.6rem;

        &.btn {
          padding-left: 22px;
          padding-top: 8px;
        }

        &:focus,
        &:active {
          box-shadow: none;
        }
      }
      .language-icon {
        .image-wrapper {
          display: flex;
        }
      }
      &.meta-right {
        .nav-link {
          display: flex;
          justify-content: center;
          align-items: center;

          span.svg.home {
            margin-right: 5px;
          }
        }

        .highlight {
          background-color: $primary-highlight;

          .nav-link {
            padding-left: 30px;
          }

          &.first {
            background-image: linear-gradient(
              98deg,
              $primary 0%,
              $primary 5px,
              $primary-highlight 6px
            );
            margin-left: 7px;

            .nav-link {
              margin-left: 7px;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

// Navigation
.header {
  position: sticky;
  z-index: 50;
  left: 0;
  right: 0;
  top: 0;
  transition: transform 0.2s;

  @media all and (display-mode: fullscreen) {
    display: none;
  }

  &.header-scrolled {
    transform: translateY(calc(-100% - 8px));
  }
  &.header-narrow {
    transform: none;
  }
}

.lightbox-opened .header.header-sticking {
  position: fixed;
}

.lightbox-opened .header.header-sticking ~ .page-content {
  padding-top: var(--navigation-height);
}

.navigation {
  position: relative;
  z-index: 5;
  background-color: white;
  box-shadow: 0px 3px 5px $box-shadow-color;
  transition-property: box-shadow;
  transition-duration: 0.5 * $transition-speed;
  transition-timing-function: ease;

  &--with-open-mega-menu {
    box-shadow: 0px 1px 0px $gray-20;
  }

  .nav-link {
    padding: 26px 0;
  }

  .nav-item {
    margin: 0;
    color: $text-dark;
    @include typography(400, 22, 35px);

    .nav-link {
      padding: 26px 18px;

      @include media-breakpoint-down(lg) {
        padding: 15px 15px;
      }

      @include media-breakpoint-down(md) {
        font-size: 18px;
        padding: 26px 10px;
      }

      &.active {
        color: $link-active-color;
      }
    }

    &.active,
    .active {
      font-weight: 700;

      @supports (-webkit-text-stroke: 1px currentColor) {
        -webkit-text-stroke: 1px currentColor;
        font-weight: 400;
      }
    }

    &.anchor {
        @include typography(400, 18, 35px);

        .nav-link {
            @include media-breakpoint-down(lg) {
                font-size: 16px;
                padding: 14px 12px;
              }
        }
    }
  }

  .nav {
    position: relative;

    .indicator {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 80px;
      background-color: $link-active-color;
      transform: scaleY(0);
      transform-origin: bottom center;
      transition-property: transform;
      transition-duration: 250ms;
      transition-timing-function: ease;

      &.active {
        transform: scaleY(1);
      }

      &.animated {
        transition-property: left, width, transform;
      }
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .logo {
    @include media-breakpoint-down(lg) {
      height: 64px;
      width: auto !important;
    }
  }
}

// Mega menu
.mega-menu {
  display: flex;
  position: absolute;
  background-color: $white;
  width: 100%;
  max-height: calc(100vh - var(--header-height));
  top: 100%;
  z-index: 1;
  box-shadow: 0px 15px 15px -15px rgba($black, 0.2);
  opacity: 0;
  transform: translateY(-2rem);
  transition-property: opacity transform;
  transition-duration: 250ms;
  transition-timing-function: ease;

  &.entering,
  &.entered {
    opacity: 1;
    transform: translateY(0);
  }

  .close-button-wrapper {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .close-button {
    background: none;
    border: none;
    appearance: none;
    position: absolute;
    top: 24px;
    right: 15px;
    padding: 0 9px;
    z-index: 1;
    cursor: pointer;

    .close-big:after {
      content: "";
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
    }
  }

  &__height-wrapper {
    width: 100%;
    max-height: calc(100vh - var(--header-height));
    overflow-y: auto;
    transition: height 250ms ease;
  }

  &__navigation-wrapper {
    overflow: hidden;

    &--hidden {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      opacity: 0;
      pointer-events: none;
    }
  }

  &__row-wrapper {
    position: relative;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
  }

  &__navigation-tree-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .navigationTree {
    @include unstyled-list();
    position: relative;
    width: 100%;
    min-height: 0;
    transition: min-height 250ms ease;

    .navigationTree-item-box {
      padding: 12px 12px 12px 0px;
      cursor: pointer;

      > .svg {
        color: $gray-40;
      }

      .navigationTree-level {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }

      .btn {
        font-size: 1rem;
      }

      .label {
        @include media-breakpoint-down(xl) {
          width: 84%;
        }

        @include media-breakpoint-down(md) {
          width: 75%;
        }
      }

      .svg {
        display: flex;
        align-items: center;

        svg {
          height: 16px;
          width: 16px;
        }
      }
    }

    .navigationTree-item {
      ul {
        position: absolute;
        top: 0;
        pointer-events: none;
        opacity: 0;
        transform: translateX(-1rem);
        margin-left: 30px;
        transition-property: opacity, transform;
        transition-duration: 250ms;
        transition-timing-function: ease;
      }

      > ul {
        width: 100%;
        left: 100%;

        .navigationTree-item > ul {
          width: 100%;
          left: 100%;
        }
      }

      &:hover {
        > .navigationTree-item-box {
          > .label {
            color: $link-hover-color;
          }

          > .svg {
            color: $dark-blue;
          }
        }
      }

      &.active {
        > .navigationTree-item-box {
          background-image: linear-gradient(
            98deg,
            transparent calc(100% - 50px),
            $white-highlight calc(100% - 50px)
          );
        }

        > ul {
          opacity: 1;
          transform: none;
          pointer-events: initial;
        }

        .navigationTree-item ul {
          &.active {
            opacity: 1;
            transform: none;
          }
        }

        > .navigationTree-item-box {
          > .label {
            user-select: none;
            color: $link-active-color;
          }

          > .svg {
            color: $dark-blue;
          }
        }
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 50px;
    padding-left: 20px;
    background-color: $gray-10;
    transition-property: opacity, transform;
    transition-duration: 250ms;
    transition-timing-function: ease;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100vh;
      // 960px = minimal container width
      width: calc(((100vw - 960px) / 2) + 100%);
      background-color: $gray-10;
      z-index: -1;
    }

    &--hidden {
      opacity: 0;
      transform: translateX(1rem);
      pointer-events: none;
    }

    a:hover {
      text-decoration: none;
    }

    .card.horizontal-thin {
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      min-height: auto;

      .card-image {
        margin-left: 0;
        width: 100%;
        max-width: 60px;

        .image-wrapper {
          align-items: flex-start;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }

      .card-body {
        height: 100%;
        margin-left: 10px;
        padding-top: 20px;
        padding-bottom: 20px;

        .card-title {
          font-size: 1rem;
          font-weight: 700;
        }

        .content {
          line-height: 1.1;
          color: $dark-blue;
        }
      }

      .card-footer {
        margin-right: 0;
        margin-top: 0;
      }
    }
  }
}

.mega-menu-backdrop {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  height: 100vh;
  background: rgba($black, 0.5);
  opacity: 0;
  transition-property: opacity;
  transition-duration: 250ms;
  transition-timing-function: ease;

  &.entering,
  &.entered {
    opacity: 1;
  }
}

// Mobile menu
.open-mm {
  display: none;
  position: relative;

  @include media-breakpoint-down(md) {
    display: block;
  }

  &:after {
    content: "";
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
  }
}

.mobile-navigation-wrapper {
  overscroll-behavior: contain;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: calc(var(--navigation-height) - 1px + var(--country-banner-height));
  background-color: $white;
  display: none;
  z-index: 49;

  .mobile-navigation {
    background-color: $white;
    border-top: 1px solid $gray-20;

    ul {
      margin-bottom: 0;

      &:not(.mobile-metanav) {
        padding-right: 0;
      }

      li {
        div.d-flex {
          border-bottom: 1px solid $white-highlight;
        }

        a {
          padding-left: 0;
          padding-right: 13px;
          padding-top: 13px;
          padding-bottom: 13px;
          transition: all 0.4s ease;

          &.active {
            color: $link-active-color;
          }
        }

        svg {
          transition: all 0.4s ease;
        }

        &:first-child {
          display: none;
        }

        &.header {
          span {
            font-weight: 700;
            margin: auto;
          }

          &:first-child {
            display: block;
            background: white;
          }
        }

        &:hover {
          a {
            color: $link-active-color;
          }

          .svg svg {
            fill: $link-active-color;
          }
        }

        &:last-child {
          div.d-flex {
            border-bottom: 0;
          }
        }
      }
    }

    &.mb-top {
      margin-bottom: auto !important;
      min-height: calc(
        100dvh - var(--navigation-height) + 1px - var(--country-banner-height) -
          96px
      );
    }

    &.mb-bottom {
      background-color: $gray-10;

      ul.mobile-metanav {
        display: flex;
        li {
          border-bottom: 0;

          &:first-child {
            text-align: left;
            display: block;

            a {
              font-weight: normal;
            }
          }

          a {
            padding-top: 15px;
            padding-bottom: 14px;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: $tertiary;
          }
        }
      }
    }

    @include media-breakpoint-down(xs) {
      &.mb-top {
        margin-left: -15px;
        margin-right: -15px;
        overflow-y: auto;
        overflow-x: hidden;

        ul {
          padding-left: 0px;
          li {
            a {
              padding-left: 15px;
              padding-right: 15px;
            }
          }
        }

        li.bold.header {
          position: sticky;
          top: 0;

          a.d-flex:first-of-type {
            z-index: 1;
          }
          a.justify-content-center {
            margin-left: -15px;
            padding-left: 0px !important;
          }
        }
      }
    }
    @include media-breakpoint-down(xs) {
      margin: 0 !important;
    }
  }
  .web-app-mobile-wrapper {
    .meta-left {
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      height: auto;
      background-color: $primary-highlight;
      padding: 0;
      a {
        color: white;
        display: block;
        text-align: left;
      }
      > .nav {
        position: relative;
        display: flex;
        height: auto;
        justify-content: space-between;
        cursor: pointer;
        @extend .container;
        .nav-item {
          padding-top: 5px;
          padding-bottom: 5px;
          .apps {
            left: 0;
          }
          .btn {
            box-shadow: none;
          }
        }
        .svg {
          &.caret-up {
            display: flex;
            place-items: center;
            position: absolute;
            right: 15px;
            top: calc(50% - 8px);
          }
        }
      }
    }
    > .footer-mobile-nav-container {
      background-color: $primary;
      transition: height 0.3s ease;
      .footer-mobile-nav {
        @extend .container;
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
        margin-bottom: 20px;
        .nav-item {
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
          a {
            padding: 0.5rem 0;
            color: $white;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
      .footer__related-links {
        @extend .container;
        display: flex;
        margin-top: 20px;
        padding-bottom: 20px;

        & > * {
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    + .header-scrolled {
      height: auto;
    }
  }
}

@include media-breakpoint-down(md) {
  .mobile-navigation-wrapper + div.header {
    opacity: 1;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 50;

    .meta {
      transition: opacity 0.5s ease-out;
      visibility: hidden;
      opacity: 0;
      height: 0;
    }
  }
}

.navigation {
  .nav-link {
    &.mobile-las-logo {
      display: none;
    }

    &.desktop-las-logo {
      display: flex;
    }
  }
}

// special landing pages
@include media-breakpoint-down(md) {
  .navigation {
    .nav-link {
      &.mobile-las-logo {
        display: flex;
        align-items: center;
        height: 4rem;
      }

      &.desktop-las-logo {
        display: none;
      }

      &.text-primary {
        font-size: 16px;
        line-height: 1.1;
        margin: 0 5px;
      }
    }
  }
}

// Breadcrumb
.breadcrumb-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  opacity: 1;
  height: 54px;
  transition: opacity 0.5s ease-out;
  visibility: visible;

  @include media-breakpoint-down(md) {
    height: auto;
  }

  @media all and (display-mode: fullscreen) {
    display: none;
  }

  .multiline {
    &:hover {
      cursor: pointer;
    }
    &.breadcrumb-inner-wrapper.multiline-open {
      height: auto;
    }
    &:not(.multiline-open) .breadcrumb-item:not(:last-child) {
      display: none;
    }
    &:not(.multiline-open) .breadcrumb-item:last-child::before {
      content: "... /";
    }
  }
}
.breadcrumb {
  font-size: 0.875rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0;
  transition: height 0.1s;
}
.breadcrumb-inner-wrapper {
  overflow: hidden;
  height: 37px;
  margin-bottom: 1rem;
}

.mobile-menu-slide-left-enter {
  transform: translateX(100%);
}
.mobile-menu-slide-left-enter-active {
  transform: translateX(0);
  transition: transform 150ms ease-in-out;
}
.mobile-menu-slide-left-exit {
  transform: translateX(0);
}
.mobile-menu-slide-left-exit-active {
  transform: translateX(-100%);

  transition: transform 200ms ease-in-out;
}

.mobile-menu-slide-right-enter {
  transform: translateX(-100%);
}
.mobile-menu-slide-right-enter-active {
  transform: translateX(0);
  transition: transform 150ms ease-in-out;
}
.mobile-menu-slide-right-exit {
  transform: translateX(0);
}
.mobile-menu-slide-right-exit-active {
  transform: translateX(100%);

  transition: transform 200ms ease-in-out;
}

.mobile-menu-animate-enter {
  transform: translateX(-100%);
  opacity: 0;
}
.mobile-menu-animate-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition:
    transform 300ms ease-out,
    opacity 300ms ease-out;
}
.mobile-menu-animate-exit {
  transform: translateX(0);
  opacity: 1;
}
.mobile-menu-animate-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition:
    transform 300ms ease-in,
    opacity 300ms ease-in;
}

.mobile-menu-apps-animate-enter {
  max-height: 0;
  overflow: hidden;
  transition: max-height 200ms ease-in-out;
}
.mobile-menu-apps-animate-enter-active {
  max-height: 500px;
  overflow: hidden;
}
.mobile-menu-apps-animate-exit {
  max-height: 500px;
}
.mobile-menu-apps-animate-exit-active {
  max-height: 0;
  transition: max-height 200ms ease-in-out;
  overflow: hidden;
}
