.form-group {
  .form-label {
    @include typography(700, 14, 21px);
    .form-label-required {
      color: $primary;
    }
  }
  .form-control-custom {
    display: block;
    width: 100%;
    padding: 7px 10px;
    border: 1px solid $gray-40;
    font-size: 14px;
    outline: none;
    &:focus {
      border: 1px solid $primary;
    }
    &[type="textarea"] {
      &::-webkit-resizer {
        display: none;
      }
    }
  }
}
