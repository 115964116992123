.formio-form {
  margin-bottom: 30px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 65px;
  }
  .formio-component {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    @include typography(700, 14, 21px);

    .card-body {
      padding: 0;
    }
    .control-label {
      text-align: left !important;
    }

    // Columns styling
    &.formio-component-columns {
      flex-direction: row;
      margin-bottom: 0;
    }

    // General label styling
    label {
      padding: 0 0 10px 0;
      &:after {
        color: $primary;
        align-self: flex-start;
      }
      &.form-check-label {
        padding-left: 30px;
        input {
          margin-left: -30px !important;
        }
      }
    }

    // General input styling
    input {
      height: 36px;
      &.form-check-input {
        display: block;
      }
    }
    textarea {
      height: 108px;
    }
    input,
    label,
    textarea,
    .formio-choices,
    &.formio-component-file > * {
      width: unset !important;
      margin: 0 !important;
      flex-grow: 1;
    }
    input,
    textarea,
    .formio-choices .form-control {
      border-radius: 0;
      border: 1px solid $gray-40;
      @include typography(700, 14, 21px);
      color: $tertiary;
      box-shadow: none !important;
      padding: 10px;
      &:focus {
        border-color: $primary;
      }
    }
    legend {
      margin-bottom: 20px;
    }

    // Selectbox and radio components
    &.formio-component-radio,
    &.formio-component-selectboxes {
      .form-group {
        display: flex;
        flex-wrap: wrap;
        background: $gray-40;
        border: 1px solid $gray-40;

        & > * {
          margin-right: 1px;
          margin-bottom: 1px;
          &:last-child {
            margin-right: 0;
          }
        }

        .form-check {
          background: white;
          margin: 0;
          flex-basis: calc(50% - 0.5px);
          flex-grow: 1;
          &:first-child:last-child {
            flex-basis: 100%;
          }
          label {
            display: flex;
            align-items: center;
            padding: 15px 20px 15px 50px;
            input[type="checkbox"] {
              position: absolute;
            }
            span {
              flex-grow: 1;
              img {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    // Select component
    .formio-choices {
      &:after {
        background-image: url("#{$asset-path}/shape/caret-down-small.svg");
        width: 16px;
        height: 16px;
        transform: translateY(-50%);
        border-style: none;
        margin-top: -1px;
      }
      &.is-open {
        &:after {
          transform: rotate(180deg) translateY(50%);
          margin-top: 1px;
        }
      }
      &[data-type="select-multiple"] {
        .form-control {
          padding: 0;
          border: 0;
          display: flex;
          flex-direction: column;
          & > * {
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .form-control {
        display: flex;
        .choices__list {
          line-height: 1;
          &.choices__list--multiple {
            display: flex;
            flex-wrap: wrap;
            & > * {
              margin-bottom: 10px;
              &:last-child {
                margin-bottom: 0;
              }
            }
            .choices__item {
              margin: 0;
              margin-right: 10px;
              padding: 9px 10px;
              display: inline-flex;
              align-items: center;
              background: $primary;
              border: 1px solid;
              color: $white;
              font-size: 14px;
              .choices__button {
                color: $white;
                border-color: $white;
                opacity: 1;
              }
            }
          }
        }
      }
      .choices__list--dropdown {
        border: none;
        border-radius: 0;
        .choices__input {
          width: 100% !important;
        }
        .choices__list {
          border: 1px solid $gray-30;
          border-top: none;
        }
      }
    }

    // Checkbox components
    &.formio-component-checkbox {
      label {
        border: 1px solid $gray-40;
        padding: 15px 20px 15px 50px !important;
        width: 100% !important;
        display: flex;
        align-items: center;
      }
    }

    // File component
    &.formio-component-file {
      a {
        color: $primary;
      }
    }

    // Error messages
    .formio-errors {
      .help-block {
        @include typography(700, 14, 21px);
      }
    }
    &.alert {
      display: flex;
      background: none;
      padding: 0;
      border: none;
      input,
      textarea,
      .formio-choices .form-control {
        background-color: rgba($danger, 0.4);
        border-color: $danger;
      }
    }

    // Hide buttons
    .formio-component-submit,
    .formio-component-columnsReset,
    &.formio-component-submit,
    &.formio-component-columnsReset {
      display: none;
    }

    // Hide hidden compnents
    &.formio-component-hidden {
      display: none;
    }
    &.formio-component-fieldset {
      &.border {
        legend {
          width: auto;
          font-size: initial;
          margin-left: 15px;
          padding: 0 5px;
        }

        .formio-component-checkbox label {
          border: none;
          padding-top: 5px !important;
          padding-bottom: 5px !important;
        }

        .invalid-feedback {
          padding: 0 20px;
        }
      }
    }
  }
  // Hide loading animation {
  .loader-wrapper {
    display: none;
  }

  // Hide alert message at top
  .alert {
    display: none;
  }
}

.contact-tabs {
  .tab-primitive__nav {
    margin-top: -10px;
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      margin-left: -30px;
      margin-right: -30px;
      margin-top: -30px;

      &.nav-tabs {
        box-shadow: 0px 3px 5px $box-shadow-color;

        .nav-link {
          padding: 11px 20px 11px;
        }
      }
    }
  }

  .tab-primitive__content__item {
    padding-top: 0;
  }
}

.tab-primitive__content {
  .form-switcher {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .form-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-top: 10px;
    .form-option {
      display: flex;
      border: 1px solid $gray-40;
      padding: 15px 20px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .service-banner {
    display: flex;
    flex-wrap: wrap;
    background: $white-highlight;
    padding: 20px;
    border-radius: 4px;
    .service-banner-column {
      min-width: 150px;
      flex-grow: 1;
      flex-basis: 50%;
      color: $text-dark;
      .service-banner-title {
        @include typography(700, 16, 1.1);
        margin-bottom: 10px;
      }
      .service-banner-content {
        @include typography(400, 14, 21px);
      }
      .service-banner-phone {
        color: $primary;
        display: flex;
        padding: 10px 0;
        @extend .h4;
        & > * {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
  .call-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    width: 100%;
  }
}
.form-result {
  tr td:first-child {
    width: 36%;
  }
}
